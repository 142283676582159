import { Box, Button } from '@material-ui/core';
import { BorderColor, CropFree } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { URLS } from '../../constants';
import useStyles from '../../styles';

function LendSelect(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div>
        <h1>{t('lend.selectLendType')}</h1>
        <h3>{t('lend.instructions')}</h3>
      </div>
      <div>
        <div>
          <Box style={{ marginTop: '16px', marginBottom: '16px' }} display="block">
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(URLS.EU.SCANNING);
              }}
            >
              <CropFree style={{ marginTop: '5px', marginBottom: '5px', marginRight: ' 10px' }} />
              {t('lend.lendWithQRCode')}
            </Button>
          </Box>
        </div>

        {false && (
          <div>
            <Box style={{ marginTop: '16px', marginBottom: '16px' }} display="block">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push(URLS.EU.LEND_ITEM);
                }}
              >
                <BorderColor style={{ marginRight: ' 10px' }} /> {t('lend.code')}
              </Button>
            </Box>
          </div>
        )}
      </div>
    </>
  );
}

export default LendSelect;
