import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { ItemInstance, ItemInstanceDTO, ItemModel, ItemModelDTO } from '../models';
import ProblemDTOModel from '../models/ProblemModel';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const alterPrices = (itemInstance: ItemInstance, encode = false): ItemInstance => {
  if (!encode) {
    return {
      ...itemInstance,
      price: parseFloat(String(itemInstance.price / 100)),
      pretaxPrice: parseFloat(String(itemInstance.pretaxPrice / 100)),
    };
  } else {
    return {
      ...itemInstance,
      price: parseInt(String(itemInstance.price * 100)),
      pretaxPrice: parseInt(String(itemInstance.pretaxPrice * 100)),
    };
  }
};
const alterPricesDto = (itemInstanceDto: ItemInstanceDTO, encode = false): ItemInstanceDTO => {
  if (!encode) {
    return {
      ...itemInstanceDto,
      price: parseFloat(String(itemInstanceDto.price / 100)),
      pretaxPrice: parseFloat(String(itemInstanceDto.pretaxPrice / 100)),
    };
  } else {
    return {
      ...itemInstanceDto,
      price: parseInt(String(itemInstanceDto.price * 100)),
      pretaxPrice: parseInt(String(itemInstanceDto.pretaxPrice * 100)),
    };
  }
};

const fetchAll = async function (searchQuery?: string): Promise<ItemModel[] | void> {
  const model: string = i18n.t('data.models.itemModels');
  const query =
    searchQuery && searchQuery !== undefined ? `${URLS.API}/itemModels?name=${searchQuery}` : `${URLS.API}/itemModels`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    const body = await (res.json() as Promise<ItemModel[]>);
    return body.map((itemModel) => {
      if (itemModel.instances) {
        itemModel.instances = itemModel.instances.map((itemInstance) => {
          return alterPrices(itemInstance);
        });
      }
      return itemModel;
    });
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchAllInstances = async function (searchQuery?: string): Promise<ItemInstance[] | void> {
  const model: string = i18n.t('data.models.itemInstances');
  const query =
    searchQuery && searchQuery !== undefined ? `${URLS.API}/itemInstances?${searchQuery}` : `${URLS.API}/itemInstances`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    const itemInstanceDtos = (await res.json()) as ItemInstance[];
    const itemInstanceBody = itemInstanceDtos.map((itemInstanceDto) => {
      return alterPrices(itemInstanceDto);
    });
    return Promise.resolve(itemInstanceBody);
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchInstancesForOneModel = async function (itemModelId: number): Promise<ItemInstance[] | void> {
  const model: string = i18n.t('data.models.itemInstances');
  const query =
    itemModelId && itemModelId !== undefined
      ? `${URLS.API}/itemInstances?itemModelId=${itemModelId}`
      : `${URLS.API}/itemInstances`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    const itemInstanceDtos = (await res.json()) as ItemInstance[];
    const itemInstanceBody = itemInstanceDtos.map((itemInstanceDto) => {
      return alterPrices(itemInstanceDto);
    });
    return Promise.resolve(itemInstanceBody);
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOne = async function (itemModelId: number, userId?: string): Promise<ItemModel | void> {
  const model: string = i18n.t('data.models.itemModels');
  const query = userId
    ? `${URLS.API}/itemModels/${itemModelId}?userId=${userId}`
    : `${URLS.API}/itemModels/${itemModelId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return (await res.json()) as Promise<ItemModel>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOneInstance = async function (itemInstanceId: number, userId?: string): Promise<ItemInstance | void> {
  const model: string = i18n.t('data.models.itemInstances');
  const query = userId
    ? `${URLS.API}/itemInstances/${itemInstanceId}?userId=${userId}`
    : `${URLS.API}/itemInstances/${itemInstanceId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    const itemInstanceDto = (await res.json()) as ItemInstance;
    const itemInstanceBody = alterPrices(itemInstanceDto);
    return Promise.resolve(itemInstanceBody);
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOneInstanceNoError = async function (itemInstanceId: number, userId?: string): Promise<ItemInstance | void> {
  const query = userId
    ? `${URLS.API}/itemInstances/${itemInstanceId}?userId=${userId}`
    : `${URLS.API}/itemInstances/${itemInstanceId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    const itemInstanceDto = (await res.json()) as ItemInstance;
    const itemInstanceBody = alterPrices(itemInstanceDto);
    return Promise.resolve(itemInstanceBody);
  } catch {}
};

const createOne = async function (userId: string, itemModelDto: ItemModelDTO): Promise<ItemModel | void> {
  const model: string = i18n.t('data.models.itemModels');
  const query = `${URLS.API}/itemModels?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(itemModelDto),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const item: Promise<ItemModel> = res.json() as Promise<ItemModel>;
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return item;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

const createOneInstance = async function (
  userId: string,
  itemInstanceDto: ItemInstanceDTO
): Promise<ItemInstance | void> {
  const model: string = i18n.t('data.models.itemInstances');
  const query = `${URLS.API}/itemInstances?userId=${userId}`;
  const itemInstanceBody = alterPricesDto(itemInstanceDto, true);
  const init: RequestInit = {
    body: JSON.stringify(itemInstanceBody),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const instance = (await res.json()) as ItemInstance;
    const itemInstanceBody = alterPrices(instance);
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return Promise.resolve(itemInstanceBody);
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

const updateOne = async function (
  userId: string,
  itemDto: ItemModelDTO,
  itemModelId: number
): Promise<ItemModel | void> {
  const model: string = i18n.t('data.models.itemModels');
  const query = `${URLS.API}/itemModels/${itemModelId}?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(itemDto),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const item: Promise<ItemModel> = res.json() as Promise<ItemModel>;
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return item;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update'));
  }
};

const updateOneInstance = async function (
  userId: string,
  itemInstanceDto: ItemInstanceDTO,
  itemInstanceId: number
): Promise<ItemInstance | void> {
  const model: string = i18n.t('data.models.itemInstances');
  const query = `${URLS.API}/itemInstances/${itemInstanceId}?userId=${userId}`;
  const itemInstanceBody = alterPricesDto(itemInstanceDto, true);
  const init: RequestInit = {
    body: JSON.stringify(itemInstanceBody),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const instance = (await res.json()) as ItemInstance;
    const itemInstanceBody = alterPrices(instance);
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return itemInstanceBody;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update'));
  }
};

const setLockerDoor = async function (itemInstanceId: number, lockerDoorId: number): Promise<ItemInstance | void> {
  const model: string = i18n.t('data.models.itemInstances');
  const query = `${URLS.API}/itemInstances/${itemInstanceId}/lockerdoor/${lockerDoorId}`;
  const init: RequestInit = {
    method: 'POST',
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const instance = (await res.json()) as ItemInstance;
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return instance;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update'));
  }
};
const deleteOne = async function (userId: string, itemModelId: number): Promise<ItemModel[] | void> {
  const model: string = i18n.t('data.models.itemModels');
  const query = `${URLS.API}/itemModels/${itemModelId}?userId=${userId}`;
  const init: RequestInit = { method: 'DELETE' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const item: Promise<ItemModel[]> = res.json() as Promise<ItemModel[]>;
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return item;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};

const deleteOneInstance = async function (userId: string, itemInstanceId: number): Promise<ItemInstance[] | void> {
  const model: string = i18n.t('data.models.itemInstances');
  const query = `${URLS.API}/itemInstances/${itemInstanceId}?userId=${userId}`;
  const init: RequestInit = { method: 'DELETE' };
  try {
    const res: Response = await fetch(query, init);
    console.log(res);
    checkStatus(res);
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
  } catch (e) {
    console.log(e);
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};

const reportError = async function (itemId: string, description: string): Promise<void> {
  const query = `${URLS.API}/itemModels/${itemId}/reportProblem`;
  const init: RequestInit = {
    body: JSON.stringify({ description }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await fetch(query, init);
    checkStatus(res);
    createSuccessNotification(i18n.t('notifications.success.reportProblem'));
  } catch {
    return createErrorNotification(i18n.t('notifications.error.reportProblem'));
  }
};

const reportProblem = async function (problemItemId: number, content: string): Promise<void> {
  const payload = {
    ProblemItemInstanceId: problemItemId,
    content,
  };
  const query = `${URLS.API}/problems`;
  const init: RequestInit = {
    body: JSON.stringify(payload),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res = await fetch(query, init);
    checkStatus(res);
    createSuccessNotification(i18n.t('notifications.success.reportProblem'));
  } catch {
    return createErrorNotification(i18n.t('notifications.error.reportProblem'));
  }
};

type Problem = { content: string };
const fetchProblems = async function (itemInstanceId?: number): Promise<Problem[] | void> {
  const model: string = i18n.t('data.models.problem');
  const query = `${URLS.API}/problems?itemInstanceId=${itemInstanceId || ''}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Problem[]>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
const fetchProblemsList = async function (itemInstanceId?: number): Promise<ProblemDTOModel[] | void> {
  const model: string = i18n.t('data.models.problem');
  const query = `${URLS.API}/problems/list?itemInstanceId=${itemInstanceId || ''}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<ProblemDTOModel[]>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
const markProblemAsRead = async function (problemId?: number): Promise<boolean | void> {
  const model: string = i18n.t('data.models.problem');
  const query = `${URLS.API}/problems/markAsRead?problemId=${problemId || ''}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<boolean>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

export default {
  fetchAll,
  fetchAllInstances,
  fetchInstancesForOneModel,
  fetchOne,
  fetchOneInstance,
  fetchOneInstanceNoError,
  createOne,
  createOneInstance,
  updateOne,
  updateOneInstance,
  deleteOne,
  deleteOneInstance,
  reportError,
  reportProblem,
  fetchProblems,
  fetchProblemsList,
  setLockerDoor,
  markProblemAsRead,
};
