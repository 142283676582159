import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LocationResults, SearchBar } from '../../components';
import { URLS } from '../../constants';
import { Location } from '../../models';
import { locationService } from '../../services';
import useStyles from '../../styles';

function AdminSearchLocationsView(props: any): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [locations, setLocations]: [Location[], any] = useState([]);

  useEffect(() => {
    locationService.fetchAll().then((locations: Location[] | void) => setLocations(locations ?? []));
    //console.debug('Called effect for locations');
  }, []);

  const navigateToCreation: () => void = () => {
    history.push(URLS.ADMIN.LOCATION_NEW);
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('search.header')}</h1>
      <Button variant="contained" color="secondary" onClick={navigateToCreation}>
        {t('actions.new')}
      </Button>
    </div>
  );

  return (
    <div>
      {header}
      <SearchBar redirectUrl={URLS.ADMIN.LOCATIONS} searchingFor="locations" initialQuery={props.match.params.query} />
      <LocationResults locations={locations} redirectUrl={URLS.ADMIN.LOCATION} />
    </div>
  );
}

export default AdminSearchLocationsView;
