import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import locationsSliceReducer from './locationsSlice';
import notificationSliceReducer from './notificationSlice';
import searchSliceReducer from './searchSlice';
import settingsSliceReducer from './settingsSlice';
import userSliceReducer from './userSlice';

export const store = configureStore({
  reducer: {
    search: searchSliceReducer,
    settings: settingsSliceReducer,
    user: userSliceReducer,
    notifications: notificationSliceReducer,
    locations: locationsSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
