import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { Lock, Person } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { User } from '../../models';
import { selectLocations } from '../../store/locationsSlice';

interface UserComponentProps {
  user: User;
  redirectUrl: string;
}

function getDisplayName(user: User, noName: string): string {
  if (user.lastName) {
    return user.firstName ? `${user.lastName} ${user.firstName}` : user.lastName;
  } else if (user.email) {
    return user.email;
  }
  return noName;
}

function LocationComponent(props: UserComponentProps): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const locations = useSelector(selectLocations);
  const location = useMemo(() => {
    const l = locations?.find((loc) => loc.locationId === props.user.defaultLocationId);
    if (l) return l;
    return null;
  }, [locations, props]);
  return (
    <Card variant="outlined">
      <CardActionArea
        onClick={() => {
          history.push(`${props.redirectUrl}/${props.user.id}`);
        }}
      >
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Person fontSize="large" style={{ marginRight: '8px' }} />
            <h3>
              {getDisplayName(props.user, t('data.none'))} {props.user?.lockoutEnd ? <Lock /> : null}
            </h3>
          </div>
          {location && (
            <div>
              <span>{location.name}</span>
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default LocationComponent;
