import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Borrowing, BorrowingDTO } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const fetchAll = async function (userId: string): Promise<Borrowing[] | void> {
  const model: string = i18n.t('data.models.borrowing');
  const query = `${URLS.API}/borrowings?userId=${userId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Borrowing[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchAllActive = async function (): Promise<Borrowing[] | void> {
  const model: string = i18n.t('data.models.borrowing');
  const query = `${URLS.API}/borrowings?Ended=false`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Borrowing[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchForItem = async function (userId: string, itemInstanceId: number): Promise<Borrowing[] | void> {
  const model: string = i18n.t('data.models.borrowing');
  const query = `${URLS.API}/borrowings?userId=${userId}&itemInstanceId=${itemInstanceId}&ended=false`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Borrowing[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOne = async function (itemInstanceId: number): Promise<Borrowing[] | void> {
  const model: string = i18n.t('data.models.borrowing');
  const query = `${URLS.API}/borrowings?itemInstanceId=${itemInstanceId}&ended=false`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Borrowing[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const createBorrowing = async function (borrowingDTO: BorrowingDTO): Promise<Borrowing | void> {
  const query = `${URLS.API}/borrowings/started`;
  const init: RequestInit = {
    body: JSON.stringify(borrowingDTO),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const borrowing: Promise<Borrowing> = res.json() as Promise<Borrowing>;
    createSuccessNotification(i18n.t('notifications.success.borrowing'));
    return borrowing;
  } catch (e) {
    console.error(e);
    createErrorNotification(i18n.t('notifications.error.borrowing'));
  }
};

const endBorrowing = async function (userId: string, borrowingId: number): Promise<Borrowing | void> {
  const query = `${URLS.API}/borrowings/ended`;
  const init: RequestInit = {
    body: JSON.stringify({ userId, borrowingId }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    console.warn(res);
    checkStatus(res);
    const borrowing: Promise<Borrowing> = res.json() as Promise<Borrowing>;
    createSuccessNotification(i18n.t('notifications.success.borrowingReturned'));
    return borrowing;
  } catch (e) {
    console.error(e);
    createErrorNotification(i18n.t('notifications.error.borrowing'));
  }
};

const openLocker = async function (borrowingId: number): Promise<Borrowing | void> {
  const query = `${URLS.API}/borrowings/${borrowingId}/open-locker`;
  const init: RequestInit = {
    method: 'POST',
  };
  try {
    const res: Response = await fetch(query, init);
    console.warn(res);
    checkStatus(res);
    const borrowing: Promise<Borrowing> = res.json() as Promise<Borrowing>;
    createSuccessNotification(i18n.t('notifications.success.open', { model: i18n.t('data.models.locker') }));
    return borrowing;
  } catch (e) {
    console.error(e);
    createErrorNotification(i18n.t('notifications.error.open', { model: i18n.t('data.models.locker') }));
  }
};

export default { fetchAll, fetchAllActive, fetchForItem, fetchOne, createBorrowing, endBorrowing, openLocker };
