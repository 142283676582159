import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageContentService } from '../../services';
import { selectLanguage } from '../../store/settingsSlice';
import useStyles from '../../styles';
import MDTextDisplay from '../text/MDTextDisplay';

type Props = { closeModal: () => void };

function TermsOfUseItem(props: Props): JSX.Element {
  const [text, setText] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  const open = Boolean(true);

  const language = useSelector(selectLanguage);

  useEffect(() => {
    let lang = 0;
    if (language === 'fi') {
      lang = 1;
    } else if (language === 'en') {
      lang = 2;
    }
    pageContentService.getPage(5, lang).then((res) => {
      const result: any = res;
      setText(result[result.length - 1]?.body);
      setHeading(result[result.length - 1]?.heading);
    });
  }, [language]);

  const classes = useStyles();

  return (
    <div>
      <Dialog onClose={props.closeModal} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          {heading}
          <IconButton aria-label="close" className={classes.termsModalCloseButton} onClick={props.closeModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <MDTextDisplay path={text} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TermsOfUseItem;
