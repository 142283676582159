import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { URLS } from '../constants';
import { signoutRedirectCallback } from '../services/authenticationService';

function LogoutRedirect(): JSX.Element {
  const history = useHistory();
  useEffect(() => {
    // Doesn't need actual async/await, because first render html tag
    // then redirects automatically after call has finished
    function signoutAsync(): void {
      signoutRedirectCallback();
      history.push(URLS.ROOT);
    }
    signoutAsync();
  }, [history]);

  return (
    <div>
      Processing logout...
      <CircularProgress />
    </div>
  );
}

export default LogoutRedirect;
