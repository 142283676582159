import { store } from '../store';
import { addError, addSuccess, NotificationItem } from '../store/notificationSlice';

function createErrorNotification(message: string): void {
  const notification: NotificationItem = { message };
  store.dispatch(addError(notification));
}

function createSuccessNotification(message: string): void {
  const notification: NotificationItem = { message };
  store.dispatch(addSuccess(notification));
}

function checkStatus(res: Response): void {
  if (!res.ok) {
    throw Error();
  }
}

export { createErrorNotification, createSuccessNotification, checkStatus };
