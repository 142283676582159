import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VALIDATION } from '../../constants';
import { UserDTO } from '../../models';
import { selectLocations } from '../../store/locationsSlice';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';
import { errorInText } from './validation';

interface UserFormProps {
  dto: UserDTO;
  setDto: any;
  initializeWithDto: boolean;
  userId: string;
}

interface UserValidationErrors {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  address: boolean;
}

const initialValidationErrors: UserValidationErrors = {
  firstName: false,
  lastName: false,
  email: false,
  address: false,
};

function UserForm(props: UserFormProps): JSX.Element {
  const classes = useStyles();
  const [errors, setErrors]: [UserValidationErrors, any] = useState(initialValidationErrors);
  const { t } = useTranslation();

  const [checked, setChecked] = useState<true | false>(false);
  const locations = useSelector(selectLocations);
  const ownUserId: string = useSelector(selectUser).id ?? '';

  useEffect(() => {
    setChecked(props.dto.isAdmin);
  }, [props.dto.isAdmin]);

  return (
    <div>
      <TextField
        className={classes.vMargin}
        label={t('data.fields.firstName')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.firstName : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, firstName: event.target.value });
        }}
        error={errors.firstName}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.USER.NAME })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, name: errorInText(event.target.value, VALIDATION.USER.NAME) })
        }
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.lastName')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.lastName : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, lastName: event.target.value });
        }}
        error={errors.lastName}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.USER.NAME })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, address: errorInText(event.target.value, VALIDATION.USER.NAME) })
        }
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.email')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.email : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, email: event.target.value });
        }}
        error={errors.email}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.USER.NAME })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, address: errorInText(event.target.value, VALIDATION.USER.NAME) })
        }
      />

      <TextField
        className={classes.vMargin}
        label={t('data.fields.address')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.address : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, address: event.target.value });
        }}
        error={errors.address}
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.postalcode')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.postalcode : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, postalcode: event.target.value });
        }}
        error={errors.address}
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.city')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.city : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, city: event.target.value });
        }}
        error={errors.address}
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.phoneNumber')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.phoneNumber : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, phoneNumber: event.target.value });
        }}
        error={errors.address}
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel id="select-location-label" style={{ background: '#fafafa', padding: '0 5px' }}>
          {t('search.selectLocation')}
        </InputLabel>
        <Select
          labelId={'select-location-label'}
          value={props.dto?.defaultLocationId}
          onChange={(e) => {
            props.setDto({ ...props.dto, defaultLocationId: e.target.value || null });
            //
          }}
        >
          <MenuItem value={undefined}>--</MenuItem>
          {locations?.map((location) => {
            return <MenuItem value={location.locationId}>{location.name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            disabled={ownUserId === props.userId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setDto({ ...props.dto, isAdmin: event.target.checked });
            }}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label="Admin"
      />
    </div>
  );
}

export default UserForm;
