import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BorrowingResults } from '../../components';
import { URLS } from '../../constants';
import { Borrowing, BorrowingInfo, ItemInstance } from '../../models';
import { borrowingService, itemService } from '../../services';
import { RootState } from '../../store';

function BorrowingsView(): JSX.Element {
  const { t } = useTranslation();
  const userId = useSelector((state: RootState) => state.user.id);
  const [borrowings, setBorrowings] = useState<Borrowing[]>([]);
  const [borrowingInfos, setBorrowingInfos] = useState<BorrowingInfo[]>([]);

  // Fetch all user's borrowings in the beginning
  useEffect(() => {
    // Route is protected, user has to be logged in
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    borrowingService.fetchAll(userId!).then((borrowingsResponse: Borrowing[] | void) => {
      if (borrowingsResponse) {
        setBorrowings(borrowingsResponse);
      }
    });
  }, [userId]);

  // Fetch all liked item objects when likes are fetched
  useEffect(() => {
    const borrowedIds = borrowings.map((borr) => borr.borrowedItemId);
    if (borrowedIds.length !== 0) {
      // TODO: Fetch by list or something
      itemService.fetchAllInstances().then((itemsResponse: ItemInstance[] | void) => {
        // Pair every borrowing with corresponding item to get all needed information
        if (itemsResponse) {
          const borrowingInfos: BorrowingInfo[] = [];
          const borrowedItems: ItemInstance[] = itemsResponse.filter((instance: ItemInstance) =>
            borrowedIds.includes(instance.itemInstanceId)
          );
          borrowings.forEach((borr: Borrowing) => {
            const correspondingItem: ItemInstance | undefined = borrowedItems.find(
              (instance: ItemInstance) => instance.itemInstanceId === borr.borrowedItemId
            );
            if (correspondingItem) {
              borrowingInfos.push({ itemInstance: correspondingItem, borrowing: borr });
            }
          });
          setBorrowingInfos(borrowingInfos);
        }
      });
    }
  }, [borrowings]);

  return (
    <div>
      <h1>{t('views.borrowings')}</h1>
      <BorrowingResults infos={borrowingInfos} redirectUrl={URLS.EU.INSTANCE_NOQUERY} />
    </div>
  );
}

export default BorrowingsView;
