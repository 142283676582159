import { baseUrl } from './util';

const URLS = {
  API: `${baseUrl}/api`,
  ROOT: '/',
  AUTH: {
    LOGIN: '/authentication/login',
    LOGIN_CB: '/authentication/login-callback',
    LOGOUT: '/authentication/logout',
    LOGOUT_CB: '/authentication/logout-callback',
  },
  EU: {
    SEARCH: '/search',
    SEARCH_Q: '/search/:query',
    ITEM_NOQUERY: '/item',
    INSTANCE_NOQUERY: '/instance',
    ITEM: '/item/:itemId',
    INSTANCE: '/instance/:instanceId',
    LIKES: '/favourites',
    NEWS: '/news',
    NOTPAID: '/notpaid',
    NEWSARTICLE: '/news/:newsArticleId',
    SCANNING: '/scanning',
    LEND_ITEM: '/lend',
    LEND_SELECT: '/select',
    BORROWING: '/borrowing',
    PAYMENTMETHODS: '/paymentmethods',
    BORROWS: '/borrows',
    PROFILE: '/profile',
    HELP: '/help',
    LEGAL: '/legal',
  },
  ADMIN: {
    ITEMS: '/admin/items',
    ITEMS_SEARCH: '/admin/items/:query',
    ITEM: '/admin/item',
    ITEM_NEW: '/admin/item/new',
    INSTANCE_NEW: '/admin/instance/:itemId',
    INSTANCE_OLD: '/admin/instance/:instanceId/:itemId',
    ITEM_OLD: '/admin/item/:itemId',
    INSTANCE_NOQUERY: '/admin/instance',

    BORROWINGS: '/admin/borrowings',

    LOCATIONS: '/admin/locations',
    LOCATIONS_SEARCH: '/admin/locations/:query',
    LOCATION: '/admin/location',
    LOCATION_NEW: '/admin/location/new',
    LOCATION_OLD: '/admin/location/:locationId',

    NEWS: '/admin/news',
    NEWS_SEARCH: '/admin/news/:query',
    NEWSARTICLE: '/admin/newsarticle',
    NEWSARTICLE_NEW: '/admin/newsarticle/new',
    NEWSARTICLE_OLD: '/admin/newsarticle/:newsArticleId',

    USERS: '/admin/users',
    USERS_SEARCH: '/admin/users/:query',
    USER: '/admin/user',
    USER_NEW: '/admin/user/new',
    USER_OLD: '/admin/user/:userId',

    DASHBOARD: '/admin/dashboard',
    LOGS: '/admin/logs',
    FEEDBACK: '/admin/feedback',
    HELP: '/admin/help',
    MODIFY: '/admin/modify',
    PROBLEMS: '/admin/problems',
    ORDERS: '/admin/orders',
    STATISTICS: '/api/statistics',
  },
};

const VALIDATION = {
  ITEM: {
    NAME: 4,
    DESCRIPTION: 10,
  },

  LOCATION: {
    NAME: 4,
    ADDRESS: 10,
  },

  NEWS: {
    AUTHOR: 4,
    HEADING: 4,
    BODY: 10,
  },

  USER: {
    NAME: 1,
    USERNAME: 6,
  },

  INSTANCE: {
    SIZE: 0,
  },
};

const TIME = {
  DAY: 60 * 60 * 24,
  WEEK: 60 * 60 * 24 * 7,
};

export { URLS, VALIDATION, TIME };
