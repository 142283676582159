import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import useStyles from '../../styles';

interface BarProps {
  label: string;
  value: number;
  maxValue: number;
}

function changeScale(value: number, oldmax: number, newmax: number): number {
  return (newmax / oldmax) * value;
}

function logarithmicGrowth(currentValue: number): number {
  /* 
  if x < 1: {y = 20*ln(1) + 8}
  if x >= 1: {y = 20*ln(x) + 8}
  */
  const value = 20 * Math.log(currentValue < 1 ? 1.0 : currentValue) + 8;

  return value;
}

/*
// as reference
function linearGrowth(currentValue: number): number {
  const value = currentValue + 3; // y=x+3

  return value;
}
*/

function AnimatedBar(props: BarProps): JSX.Element {
  const classes = useStyles();
  const maxBarPercentage = 100;
  const [time, setTime]: [number, any] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // NOTE: function passed to setTime must be a mathematical function, which
      // produces y>=100 when x=100 and y>=0 when x=0
      if (time < maxBarPercentage) setTime(logarithmicGrowth(time));

      if (time > maxBarPercentage) setTime(maxBarPercentage);
    }, 1);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  let value = props.value;
  if (value > maxBarPercentage) value = maxBarPercentage;

  const scaledValue = (time / maxBarPercentage) * value;
  const mapped = changeScale(scaledValue, props.maxValue, 100);

  return (
    <div>
      <h4>
        {props.label}
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={mapped ? mapped : 0.2}
              classes={{
                root: classes.animatedBar,
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {props.value}
            </Typography>
          </Box>
        </Box>
      </h4>
    </div>
  );
}

export default AnimatedBar;
