import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification, selectNotifications } from '../../store/notificationSlice';

let displayed: any[] = [];

const Notification = (): null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: string | number): void => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string | number): void => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ id, type, message, dismissed }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(id);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(id)) return;

      enqueueSnackbar(message, {
        key: id,
        variant: type,
        /*action: (id) => (
          <div>
            <Button style={{ color: 'white', zIndex: 9999 }} onClick={() => closeSnackbar(id)}>
              {t('actions.close')}
            </Button>
          </div>
        ),*/

        onExited: (event, id) => {
          // remove this snackbar from redux store
          dispatch(removeNotification(id));
          removeDisplayed(id);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(id);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, t]);

  return null;
};

export default Notification;
