import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageContentService } from '../../services';
import { selectLanguage } from '../../store/settingsSlice';
import useStyles from '../../styles';
import MDTextDisplay from '../text/MDTextDisplay';

interface TermsOfUseDialogProps {
  closeDialog: () => void;
  isOpen: boolean;
}

export function TermsOfUseDialog(props: TermsOfUseDialogProps): JSX.Element {
  const [text, setText] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  const language = useSelector(selectLanguage);

  useEffect(() => {
    let lang = 0;
    if (language === 'fi') {
      lang = 1;
    } else if (language === 'en') {
      lang = 2;
    }
    // 5 for terms of use
    pageContentService.getPage(5, lang).then((res) => {
      const result: any = res;
      if (result.length > 0) {
        setText(result[result.length - 1].body);
        setHeading(result[result.length - 1].heading);
      } else {
        setText('');
        setHeading('');
      }
    });
  }, [language]);

  const classes = useStyles();
  return (
    <Dialog onClose={props.closeDialog} aria-labelledby="dialog-title" open={props.isOpen}>
      <DialogTitle id="customized-dialog-title">
        {heading}
        <IconButton aria-label="close" className={classes.termsModalCloseButton} onClick={props.closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <MDTextDisplay path={text} />
      </DialogContent>
    </Dialog>
  );
}
