import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemInstance } from '../../../models';
import { itemService } from '../../../services';

type Props = {
  instance: ItemInstance;
  closeModal: () => void;
};

export function ReportProblem(props: Props): JSX.Element {
  const [description, setDescription] = useState('');
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();

  const onSend = async (): Promise<void> => {
    setIsSending(true);
    await itemService.reportProblem(props.instance.itemInstanceId, description);
    props.closeModal();
  };

  return (
    <Dialog maxWidth="md" fullWidth open={true} onClose={props.closeModal}>
      <DialogTitle>{t('views.reportProblem')}</DialogTitle>
      <DialogContent>
        <TextField
          disabled={isSending}
          multiline
          variant="outlined"
          rows={3}
          fullWidth
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={description.length < 1 || isSending} onClick={onSend}>
          {t('actions.send')}
        </Button>
        <Button onClick={props.closeModal}>{t('actions.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
