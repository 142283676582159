import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreateButton, ItemResults, SearchBar, SearchFilters } from '../../components';
import { URLS } from '../../constants';
import { ItemInstance, ItemModel, Location } from '../../models';
import { itemService } from '../../services';
import { selectLocations } from '../../store/locationsSlice';
import { selectQuery } from '../../store/searchSlice';
import useStyles from '../../styles';

function AdminSearchItemsView(props: any): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const query = useSelector(selectQuery);
  const [instances, setInstances] = useState<ItemInstance[]>([]);
  const [items, setItems] = useState<ItemModel[]>([]);
  const [firstQuery, setFirstQuery] = useState<boolean>(true);
  const locations = useSelector(selectLocations);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [loadingInstances, setLoadingInstances] = useState<boolean>(false);

  useEffect(() => {
    // A bit hackish way of checking store before fetching everything (as there's no query)
    if (firstQuery) {
      setFirstQuery(false);
      return;
    }
    setLoadingItems(true);
    itemService.fetchAll(query).then((items: ItemModel[] | void) => {
      if (items) {
        setItems(items);
        setLoadingItems(false);
      }
    });
    setLoadingInstances(true);
    itemService.fetchAllInstances(query).then((instances: ItemInstance[] | void) => {
      if (instances) {
        setInstances(instances);
        setLoadingInstances(false);
      }
    });
  }, [firstQuery, query]);

  const navigateToCreation: () => void = () => {
    history.push(URLS.ADMIN.ITEM_NEW);
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('search.header')}</h1>
      <CreateButton create={navigateToCreation} checkError={() => false}></CreateButton>
    </div>
  );

  return (
    <div>
      {header}
      <div className={classes.hFlex}>
        <SearchBar redirectUrl={URLS.ADMIN.ITEMS} searchingFor="items" initialQuery={props.match.params.query} />
        <SearchFilters />
      </div>
      <div style={{ maxWidth: 'calc(100% - 48px)' }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="select-location-label" style={{ background: '#fafafa', padding: '0 5px' }}>
            {t('search.selectLocation')}
          </InputLabel>
          <Select
            labelId={'select-location-label'}
            value={selectedLocation?.locationId || null}
            onChange={(e) => {
              const location = locations.find((loc) => loc.locationId === e.target.value);
              if (location) {
                setSelectedLocation(location);
              } else {
                setSelectedLocation({} as Location);
              }
              //
            }}
          >
            <MenuItem value={undefined}>--</MenuItem>
            {locations.map((location) => {
              return <MenuItem value={location.locationId}>{location.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <ItemResults
        loading={loadingItems || loadingInstances}
        items={items}
        instances={instances}
        location={selectedLocation}
        redirectUrl={URLS.ADMIN.ITEM}
      />
    </div>
  );
}

export default AdminSearchItemsView;
