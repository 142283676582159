import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { User } from '../../models';
import { selectLocations } from '../../store/locationsSlice';
import { selectQuery } from '../../store/searchSlice';
import useStyles from '../../styles';
import UserComponent from './User';

interface UserResultsProps {
  users: User[];
  redirectUrl: string;
  actionButton?: JSX.Element;
  resultText?: string;
}

function UserResults(props: UserResultsProps): JSX.Element {
  const { t } = useTranslation();
  const query = useSelector(selectQuery);
  const classes = useStyles();
  const locations = useSelector(selectLocations);
  const filteredUsers = useMemo(() => {
    return props.users.filter((user: User) => {
      const l = locations?.find((loc) => loc.locationId === user.defaultLocationId);
      return query
        ? `${user.email} ${user.firstName} ${user.lastName} ${l && l.name}`
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase())
        : true;
    });
  }, [props.users, query, locations]);

  const displayResultText = (): string => {
    if (props.resultText) {
      return props.resultText;
    }
    if (!query) {
      return t('search.browse.users');
    }
    switch (filteredUsers.length) {
      case 0:
        return t('search.results.none');
      case 1:
        return t('search.results.one');
      default:
        return t('search.results.many', { x: filteredUsers.length });
    }
  };

  // Decide best way way to filter users (note if some attribute is empty), leave to id for now
  const sortUsersByName = (i1: User, i2: User): number => (i1.id.toLowerCase() > i2.id.toLowerCase() ? 1 : -1);

  return (
    <div>
      <div className={classes.flexRow}>
        {/* <h2>{displayResultText()}</h2> */}
        {props.actionButton !== undefined && props.actionButton}
      </div>
      <Grid container className={classes.fGrow} spacing={2}>
        {filteredUsers.sort(sortUsersByName).map((user: User, i: number) => (
          <Grid item className={classes.gridItem} key={user.id} xl={3} lg={4} md={6} sm={12} xs={12}>
            <UserComponent user={user} redirectUrl={props.redirectUrl} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default UserResults;
