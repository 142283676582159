import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Done, LockOpen } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ConfirmationDialog } from '../../../components';
import ImageCarousel from '../../../components/item/ImageCarousel';
import PriceDisplay from '../../../components/item/PriceDisplay';
import { URLS } from '../../../constants';
import { getLocationById, secondsToDays } from '../../../helpers';
import { Borrowing, DoorCode, ImageData, ItemInstance, ItemModel, Location, LockerDoor } from '../../../models';
import { borrowingService, doorCodeService, imageService, itemService } from '../../../services';
import lockerService from '../../../services/lockerService';
import { RootState } from '../../../store';
import { selectLocations } from '../../../store/locationsSlice';
import { addSkipForImage, selectSkipImageReload } from '../../../store/settingsSlice';
import { selectUser } from '../../../store/userSlice';
import useStyles from '../../../styles';
import { createImageData } from '../../../util';
import { DetailedItemModals } from './DetailedItemModals';

function DetailedInstancePage(props: any): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(selectUser);
  // Only users should be able to access this view, empty value is never used
  const userId: string = user.id ?? '';
  const instanceId = props.match.params.instanceId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [item, setItem] = useState<ItemModel>({} as ItemModel);
  const [borrowing, setBorrowing] = useState<Borrowing>({} as Borrowing);
  const [doorCode, setDoorCode] = useState<DoorCode>({} as DoorCode);
  const [lockerDoor, setLockerDoor] = useState<LockerDoor>({} as LockerDoor);

  const [loadingLockerDoorOpen, setLoadingLockerDoorOpen] = useState(false);
  const [loadingBorrowing, setLoadingBorrowing] = useState(true);
  const [instance, setInstance] = useState<ItemInstance>({} as ItemInstance);
  const [pickupInfoModalOpen, setPickupInfoModalOpen] = useState(false);
  const [returnInfoModalOpen, setReturnInfoModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModelOpen] = useState(false);

  const [doorOpenConfirmationModalOpen, setDoorOpenConfirmationModalOpen] = useState(false);
  const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
  const [imageData2, setImageData2] = useState<ImageData | undefined>(undefined);
  const [imageData3, setImageData3] = useState<ImageData | undefined>(undefined);
  const skipReload = useSelector((state) => selectSkipImageReload(state as RootState, item.itemModelId));
  const locations = useSelector(selectLocations);

  const location = useMemo(() => {
    if (instance) {
      return getLocationById(locations, instance.locationId);
    }
    return null;
  }, [instance, locations]);
  const locationName = (locId: number): JSX.Element => {
    const loc = locations.find((location: Location) => location.locationId === locId);
    return (
      <div>
        <Typography variant={'subtitle2'}>{t('instance.location')}:</Typography>
        <Typography variant={'subtitle1'}>
          <strong>{loc?.name ?? ''}</strong>
        </Typography>
      </div>
    );
  };

  const returnItem = (): void => {
    // If the item is borrowed, return it and update item information
    if (borrowing)
      borrowingService.endBorrowing(userId, borrowing.borrowingId).then(() => {
        itemService.fetchOneInstance(instanceId, userId).then((itemResponse: ItemInstance | void) => {
          if (itemResponse) {
            setInstance(itemResponse);
          }
        });
      });
  };

  const loadBorrowing = async () => {
    setLoadingBorrowing(true);
    let borrowing: void | Borrowing[];
    try {
      borrowing = await borrowingService
        .fetchForItem(userId, instance.itemInstanceId)
        .then((borrowing: Borrowing[] | void) => {
          if (borrowing) {
            borrowing.forEach((borr) => {
              setBorrowing(borr);
            });
          }
          return borrowing;
        });
      if (borrowing && borrowing?.[0] && borrowing?.[0]?.borrowingId) {
        const borrowingId = borrowing?.[0]?.borrowingId;
        await doorCodeService.fetchForBorrowing(borrowingId).then((doorCode: DoorCode | void) => {
          if (doorCode) {
            setDoorCode(doorCode);
          }
        });
        if (instance.lockerDoorId) {
          await lockerService.fetchForBorrowing(borrowingId).then((lockerDoor: LockerDoor | void) => {
            if (lockerDoor) {
              setLockerDoor(lockerDoor);
            }
          });
        }
      } else {
        setBorrowing({} as Borrowing);
        setDoorCode({} as DoorCode);
        setLockerDoor({} as LockerDoor);
      }
    } catch (e) {
      console.error(e);
    }
    setLoadingBorrowing(false);
    return borrowing;
  };
  const loadItem = async () => {
    return itemService
      .fetchOneInstance(instanceId)
      .then((instance: ItemInstance | void) => {
        if (instance) {
          setInstance(instance);
          itemService.fetchOne(Number(instance.itemModelId), userId).then((itemResponse: ItemModel | void) => {
            if (itemResponse) {
              setItem(itemResponse);
            }
          });
        }
        return instance;
      })
      .then((instance) => {
        if (instance && instance.itemModelId) {
          imageService.fetchOne(instance.itemModelId, true, skipReload).then((imageBlob: Blob | void) => {
            createImageData(setImageData, imageBlob);
            if (!skipReload) dispatch(addSkipForImage(instance.itemModelId || 0));
          });
          imageService
            .fetchOneWithImageNumber(instance.itemModelId, 2, true, skipReload)
            .then((imageBlob: Blob | void) => {
              createImageData(setImageData2, imageBlob);
              if (!skipReload) dispatch(addSkipForImage(`${instance.itemModelId}_2`));
            });
          imageService
            .fetchOneWithImageNumber(instance.itemModelId, 2, true, skipReload)
            .then((imageBlob: Blob | void) => {
              createImageData(setImageData3, imageBlob);
              if (!skipReload) dispatch(addSkipForImage(`${instance.itemModelId}_3`));
            });
        }
      });
  };
  useEffect(() => {
    loadItem();
  }, [instanceId]);

  useEffect(() => {
    // Fetch borrowing information for possible returning callback
    if (instance && instance.borrowedByMe) {
      /*intervalId = setInterval(() => {
        if (!userId) {
          setLoadingBorrowing(false);
          return;
        }
        clearInterval(intervalId);
        if (borrowing && borrowing.borrowingId) return false;
        // Wait for user id to be set
        loadBorrowing();
      }, 500);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };*/
      if (!userId) {
        setLoadingBorrowing(false);
        return;
      }
      loadBorrowing();
    } else {
      setLoadingBorrowing(false);
    }
  }, [instanceId, instance, userId]);

  const borrowStatus = (available: boolean, borrowedUntil: string | undefined): JSX.Element => {
    const date = moment(borrowedUntil).format('DD.MM.yyyy') ?? '';
    return (
      <>
        <Typography variant={'subtitle2'}>{t('item.status._')}:</Typography>
        {available ? (
          <div className={classes.greenChip}>{t('item.status.available')}</div>
        ) : (
          <div className={classes.redChip}>{`${t('item.status.unavailable')} (${date})`}</div>
        )}
      </>
    );
  };

  const openLocker = async () => {
    const _borrowing = structuredClone(borrowing);
    setLoadingLockerDoorOpen(true);
    const isPickup = !_borrowing.lockerOpened;
    const isReturn = _borrowing.lockerOpened;
    try {
      await borrowingService.openLocker(_borrowing.borrowingId);
      await loadBorrowing();
      await loadItem();
      setLoadingLockerDoorOpen(false);
      setDoorOpenConfirmationModalOpen(false);
      isPickup && setPickupInfoModalOpen(true);
      isReturn && setReturnInfoModalOpen(true);
    } catch (e) {
      console.error(e);
    }
    setLoadingLockerDoorOpen(false);
  };
  return (
    <div>
      <div></div>
      {loadingBorrowing && <LinearProgress />}
      {!loadingBorrowing && instance && !instance.available && instance.borrowedByMe && lockerDoor && lockerDoor.id && (
        <Card className={classes.vMargin}>
          <CardContent>
            <Typography variant={'h6'} gutterBottom>
              {!borrowing?.lockerOpened && t('locker.pickup')}
              {borrowing?.lockerOpened && t('locker.return')}
            </Typography>
            {!borrowing?.lockerOpened && <Alert severity="info">{t('locker.borrowInfo')}</Alert>}
            {borrowing?.lockerOpened && <Alert severity="info">{t('locker.returnInfo')}</Alert>}
          </CardContent>
          <CardContent>
            <Typography variant={'subtitle2'}>{t('locker.location')}: </Typography>
            <Typography variant={'subtitle1'}>
              <strong>{lockerDoor?.locker?.description}</strong>
            </Typography>
            <Typography variant={'subtitle2'}>{t('locker.door.door')}: </Typography>
            <Typography variant={'subtitle1'}>
              <strong>{lockerDoor?.door}</strong>
            </Typography>
            <br />
            <Button
              startIcon={<LockOpen />}
              color={'secondary'}
              variant="contained"
              className={classes.block}
              onClick={() => setDoorOpenConfirmationModalOpen(true)}
            >
              {t('locker.open')}
            </Button>
          </CardContent>
        </Card>
      )}
      {!loadingBorrowing &&
        instance &&
        !instance.available &&
        instance.borrowedByMe &&
        (!lockerDoor || !lockerDoor.id) && (
          <Card>
            <CardContent>
              <Typography variant={'h6'} gutterBottom>
                {t('borrowing.pickupOrReturn')}
              </Typography>
              <Alert severity="info"> {t('borrowing.pickupOrReturnText')}</Alert>
            </CardContent>
            <CardContent>
              {doorCode && doorCode.code && (
                <div>
                  <Typography variant={'subtitle2'}>{t('doorCode.header')}:</Typography>
                  <Typography variant={'subtitle1'}>
                    <span className={classes.pinCode}>{doorCode.code}</span>
                  </Typography>
                </div>
              )}
              <Button
                variant="contained"
                color="secondary"
                className={classes.block}
                startIcon={<Done />}
                onClick={() => {
                  setConfirmationModelOpen(true);
                }}
              >
                {t('item.actions.return')}
              </Button>
            </CardContent>
          </Card>
        )}
      <Card className={classes.vMargin}>
        <CardMedia>
          <ImageCarousel imageData={imageData} imageData2={imageData2} imageData3={imageData3} />
        </CardMedia>
        <CardHeader title={item && item.name} action={<DetailedItemModals instance={instance} />}></CardHeader>
        <CardContent>
          {item && (
            <div className={classes.vMargin}>
              <Typography variant={'subtitle1'} className={classes.lineBreak}>
                {item.description}
              </Typography>
            </div>
          )}
          {instance.size ? (
            <div>
              <Typography variant={'subtitle2'}>{t('instance.size')}:</Typography>
              <Typography variant={'subtitle1'}>
                <strong>{instance.size}</strong>
              </Typography>
            </div>
          ) : null}
          <div className={classes.vMargin}>{locationName(instance.locationId)}</div>
          {item && (
            <div className={classes.vMargin}>
              <Typography variant={'subtitle2'}>{t('item.borrowTime')}:</Typography>
              <Typography variant={'subtitle1'}>
                <strong>
                  {secondsToDays(item?.maxBorrowingTime)} {t('item.borrowTimeUnit')}
                </strong>
              </Typography>
            </div>
          )}
          <div className={classes.vMargin}>{borrowStatus(instance.available, instance.nextBorrowingDueDate)}</div>
          <PriceDisplay instance={instance} />
          <br />
          {instance && instance.available && (
            <Button
              variant="contained"
              color="primary"
              className={classes.blockBorrow}
              onClick={() => {
                if (location?.usePayments) {
                  history.push({
                    pathname: URLS.EU.BORROWING,
                    state: { instance, item },
                  });
                } else {
                  history.push({
                    pathname: URLS.EU.SCANNING,
                  });
                }
              }}
            >
              {t('item.actions.borrow')}
            </Button>
          )}
        </CardContent>
      </Card>

      <ConfirmationDialog
        title={t('locker.openConfirm')}
        open={doorOpenConfirmationModalOpen}
        onClose={() => setDoorOpenConfirmationModalOpen(false)}
        onOk={() => console.log}
        useOkButton={false}
      >
        <Typography variant={'subtitle1'} className={classes.vMargin}>
          {t('locker.openConfirmCustomerText')}
        </Typography>
        <br />
        <Typography variant={'subtitle2'}>{t('locker.location')}: </Typography>
        <Typography variant={'subtitle1'}>
          <strong>{lockerDoor?.locker?.description}</strong>
        </Typography>
        <Typography variant={'subtitle2'}>{t('locker.door.door')}: </Typography>
        <Typography variant={'subtitle1'}>
          <strong>{lockerDoor?.door}</strong>
        </Typography>
        <br />
        <Button
          startIcon={loadingLockerDoorOpen ? <CircularProgress size={26} /> : <LockOpen />}
          color={'secondary'}
          variant="contained"
          className={classes.block}
          onClick={async () => {
            await openLocker();
          }}
        >
          {t('locker.open')}
        </Button>
      </ConfirmationDialog>
      <ConfirmationDialog
        title={t('borrowing.pickupInfo')}
        open={pickupInfoModalOpen}
        onClose={() => setPickupInfoModalOpen(false)}
        onOk={() => {
          setPickupInfoModalOpen(false);
        }}
        useCancelButton={false}
        okButtonText={t('confirmationDialog._ok')}
      >
        {t('borrowing.pickupInfoText')}
      </ConfirmationDialog>
      <ConfirmationDialog
        title={t('borrowing.returnInfo')}
        open={returnInfoModalOpen}
        onClose={() => setReturnInfoModalOpen(false)}
        onOk={() => {
          setReturnInfoModalOpen(false);
        }}
        useCancelButton={false}
        okButtonText={t('confirmationDialog._ok')}
      >
        {t('borrowing.returnInfoText')}
      </ConfirmationDialog>
      <ConfirmationDialog
        title={t('borrowing.confirmReturned')}
        open={confirmationModalOpen}
        onClose={() => setConfirmationModelOpen(false)}
        onOk={() => {
          setConfirmationModelOpen(false);
          returnItem();
        }}
      >
        {t('borrowing.confirmReturnedText')}
      </ConfirmationDialog>
    </div>
  );
}

export default DetailedInstancePage;
