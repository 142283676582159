import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MDTextDisplay } from '../../components';
import { pageContentService } from '../../services';
import { selectLanguage } from '../../store/settingsSlice';

function HelpView(): JSX.Element {
  const language = useSelector(selectLanguage);
  const [text, setText] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  useEffect(() => {
    let lang = 0;
    if (language === 'fi') {
      lang = 1;
    } else if (language === 'en') {
      lang = 2;
    }
    // 3 for Help
    pageContentService.getPage(3, lang).then((res) => {
      const result: any = res;
      if (result && result.length > 0) {
        setText(result[result.length - 1].body);
        setHeading(result[result.length - 1].heading);
      } else {
        setText('');
        setHeading('');
      }
    });
  }, [language]);

  return (
    <div>
      <h1>{heading}</h1>
      <MDTextDisplay path={text} />
    </div>
  );
}

export default HelpView;
