import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Feedback, FeedbackDto } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const fetchAll = async function (): Promise<Feedback[] | void> {
  const model: string = i18n.t('data.models.feedback');
  const query = `${URLS.API}/feedback`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Feedback[]>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const markAsRead = async function (feedbackId: number): Promise<boolean | void> {
  const model: string = i18n.t('data.models.feedback');
  const query = `${URLS.API}/feedback/markAsRead?feedbackId=${feedbackId}`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<boolean>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const createOne = async function (feedbackDto: FeedbackDto): Promise<Feedback | void> {
  const model: string = i18n.t('data.models.feedback');
  const query = `${URLS.API}/feedback`;
  const init: RequestInit = {
    body: JSON.stringify(feedbackDto),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const fb: Promise<Feedback> = res.json() as Promise<Feedback>;
    createSuccessNotification(i18n.t('general.thanks', { model }));
    return fb;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

export default { fetchAll, createOne, markAsRead };
