import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { PageContent } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const getPage = async function (pageType: number, pageLang: number): Promise<PageContent | void> {
  const model: string = i18n.t('data.models.page');
  const query = `${URLS.API}/pageContents?contentType=${pageType}&contentLanguage=${pageLang}`;
  const init: RequestInit = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const pageContent: Promise<PageContent> = (await res.json()) as Promise<PageContent>;
    return pageContent;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const savePageContent = async function (
  ownUserId: string,
  pageContentId: number,
  pageType: number,
  pageLang: number,
  heading: string,
  body: string
): Promise<PageContent | void> {
  const model: string = i18n.t('data.models.page');
  const content = {
    author: ownUserId,
    type: pageType,
    language: pageLang,
    heading: heading,
    body: body,
  };

  const query = `${URLS.API}/pageContents/${pageContentId}?userId${ownUserId}`;
  const init: RequestInit = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(content),
  };

  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const savedPageContent: Promise<PageContent> = res.json() as Promise<PageContent>;
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return savedPageContent;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update', { model }));
  }
};

export default { getPage, savePageContent };
