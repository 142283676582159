import { debounce, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectQuery, updateQuery } from '../../store/searchSlice';
import useStyles from '../../styles';

interface StateProps {
  query?: string | undefined;
  // This is the page where searching will redirect. Configuring this from
  // outside makes recycling the component to all views possible. Path should be
  // given in the paramless version (e.g. '/admin/locations').
  redirectUrl: string;
  initialQuery: string | undefined;
  searchingFor: string;
  className?: string;
  inputClassName?: string;
}

function SearchBar(props: StateProps): JSX.Element {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const storedQuery = useSelector(selectQuery);

  // Update external query (value in store). Use this on initial page load or
  // after a successful search.
  const updateExternalQuery = useCallback(
    (query: string | undefined): void => {
      if (query !== '') {
        dispatch(updateQuery(query));
      } else {
        dispatch(updateQuery(undefined));
      }
    },
    [dispatch]
  );

  const executeSearch = useCallback(
    (query: string): void => {
      function navigateToNew(): void {
        history.push(`${props.redirectUrl}/${query ?? ''}`);
      }

      updateExternalQuery(query);
      navigateToNew();
    },
    [history, props.redirectUrl, updateExternalQuery]
  );

  const debouncedExecute = useCallback(
    debounce((q: string) => executeSearch(q), 800),
    []
  );

  function initializeQuery(): void {
    let initialQuery = '';

    if (storedQuery && storedQuery !== '') {
      initialQuery = storedQuery;
    } else if (props.initialQuery !== undefined && props.initialQuery !== '') {
      initialQuery = props.initialQuery;
    }

    setQuery(initialQuery);
    updateExternalQuery(initialQuery);
  }

  // This ensures that query is initialized only once, when component mounts
  const initializeWithoutDepencies = useCallback(initializeQuery, []);

  useEffect(() => {
    // Ensure the internal state is always a string.
    initializeWithoutDepencies();
  }, [initializeWithoutDepencies]);

  function executeOnEnter(event: any): void {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      executeSearch(query);
    }
  }

  // Update internal query (component's own). Use this after every search query
  // update (key presses).
  function updateInternalQuery(event: any): void {
    setQuery(event.target.value);
    debouncedExecute(event.target.value);
  }

  return (
    <TextField
      className={[props.className, classes.vMargin].join(' ')}
      fullWidth
      label={t(`search.for.${props.searchingFor}`)}
      variant="outlined"
      color="primary"
      value={query}
      onKeyPress={(event: SyntheticEvent) => executeOnEnter(event.nativeEvent)}
      onInput={(event: SyntheticEvent) => updateInternalQuery(event.nativeEvent)}
      InputProps={{
        className: props.inputClassName,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={() => executeSearch(query)}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBar;
