import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, RemoveButton, SaveButton } from '../../components';
import { LockButton, UnlockButton } from '../../components/admin/ActionButtons';
import UserForm from '../../components/admin/UserForm';
import { errorInUser } from '../../components/admin/validation';
import { URLS } from '../../constants';
import { User, UserDTO } from '../../models';
import { userService } from '../../services';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

function AdminOldUserView(props: any): JSX.Element {
  const userId: string = props.match.params.userId;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [userDto, setUserDto]: [UserDTO, any] = useState({
    email: '',
    firstName: '',
    lastName: '',
    defaultLocationId: 0,
    isAdmin: false,
    address: '',
    postalcode: '',
    city: '',
    phoneNumber: '',
  });
  const [isAdmin, setIsAdmin] = useState<false | true>(false);

  const ownUserId: string = useSelector(selectUser).id ?? '';

  const getUser = (userId: string) => {
    userService.fetchOne(userId).then((user: User | void) => {
      if (user) {
        setUserDto(user as UserDTO);
        setIsAdmin(user.isAdmin);
      }
    });
  };
  useEffect(() => {
    getUser(userId);
  }, [userId]);

  const saveUser: () => void = () => {
    userService.updateOne(ownUserId, userDto, userId).then(() => {
      history.push(URLS.ADMIN.USERS);

      // IF admin priviledges is change, run updateRole
      if (isAdmin !== userDto.isAdmin) {
        updateRole();
      }
    });
  };

  const updateRole: () => void = () => {
    userService.updateRole(ownUserId, userId, userDto.isAdmin).then((res) => {
      console.log(res);
    });
  };

  const checkError: () => boolean = () => {
    return errorInUser(userDto);
  };

  const removeUser: () => void = () => {
    userService.deleteOne(ownUserId, userId).then(() => {
      history.push(URLS.ADMIN.USERS);
    });
  };
  const lockUser: () => void = () => {
    userService.lockOne(ownUserId, userId).then((r) => {
      getUser(userId);
    });
  };

  const unlockUser: () => void = () => {
    userService.unlockOne(ownUserId, userId).then((r) => {
      getUser(userId);
    });
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.modify')}</h1>
      <div className={classes.flexRow}>
        <div className={classes.rMargin}>
          <SaveButton save={saveUser} checkError={checkError} />
        </div>
        <div className={classes.rMargin}>
          <RemoveButton remove={removeUser} />
        </div>
        {userDto && !userDto?.lockoutEnd && (
          <div className={classes.rMargin}>
            <LockButton lock={lockUser} />
          </div>
        )}
        {userDto?.lockoutEnd && (
          <div className={classes.rMargin}>
            <UnlockButton unlock={unlockUser} />
          </div>
        )}
        <BackButton />
      </div>
    </div>
  );

  return (
    <div>
      {header}
      {userDto?.lockoutEnd && (
        <Alert severity="warning">
          {t('data.fields.lockoutEnd', { days: moment(userDto.lockoutEnd).format('DD.MM.YYYY HH:mm') })}
        </Alert>
      )}
      <UserForm dto={userDto} setDto={setUserDto} initializeWithDto={true} userId={userId} />
    </div>
  );
}

export default AdminOldUserView;
