import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, Delete, List, LockOpen, Save } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, CreateButton, InstanceComponent, LocationForm, RemoveButton, SaveButton } from '../../components';
import { CloseButton } from '../../components/admin/ActionButtons';
import { errorInLocation } from '../../components/admin/validation';
import { URLS } from '../../constants';
import { ItemInstance, ItemModel, Location, LocationDTO } from '../../models';
import DoorCode from '../../models/DoorCode';
import Locker, { LockerType } from '../../models/Locker';
import LockerDoor from '../../models/LockerDoor';
import { doorCodeService, itemService, locationService } from '../../services';
import lockerService from '../../services/lockerService';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';
import theme from '../../theme';

interface LockerDoorItemListDialogProps {
  open: boolean;
  door: LockerDoor | null;
  locker: Locker | null;
  itemInstances: ItemInstance[];
  selectedItems: ItemInstance[];

  deletedDoors: ItemInstance[];
  loadingItems: boolean;
  loadingSave: boolean;
}

function AdminOldLocationView(props: any): JSX.Element {
  const locationId: number = props.match.params.locationId;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [locationDto, setLocationDto]: [LocationDTO, any] = useState({ name: '', address: '', usePayments: false });
  const [doorCodes, setDoorCodes]: [DoorCode[], any] = useState([]);
  const [doorCodeFilter, setDoorCodeFilter]: [string, any] = useState('');
  const [lockers, setLockers]: [Locker[], any] = useState([]);
  const [itemInstances, setItemInstances]: [ItemInstance[], any] = useState([]);
  const [lockerDoorItemListDialog, setLockerDoorItemListDialog]: [LockerDoorItemListDialogProps, any] = useState({
    selectedItems: [],
    open: false,
    door: null,
    locker: null,
    deletedDoors: [],
    itemInstances: [],
    loadingItems: false,
    loadingSave: false,
  } as LockerDoorItemListDialogProps);
  const userId: string = useSelector(selectUser).id ?? '';

  const loadDoorCodes = () => {
    doorCodeService.fetchAll({ locationId }).then((doorCodes: DoorCode[] | void) => {
      if (doorCodes) {
        setDoorCodes(doorCodes);
      }
    });
  };
  const saveLocation: () => void = () => {
    locationService.updateOne(userId, locationDto, locationId).then(() => {
      history.push(URLS.ADMIN.LOCATIONS);
    });
  };

  const checkError: () => boolean = () => {
    return errorInLocation(locationDto);
  };

  const removeLocation: () => void = () => {
    locationService.deleteOne(userId, locationId).then(() => {
      history.push(URLS.ADMIN.LOCATIONS);
    });
  };
  const deprecateCode = async (doorCodeId: number) => {
    await doorCodeService.deprecate(doorCodeId);
    loadDoorCodes();
  };

  const addNewDoorCode = () => {
    setDoorCodes([{ code: '', _edit: true, locationId: +locationId }, ...doorCodes]);
  };
  const saveCode = async (doorCode: DoorCode) => {
    await doorCodeService.save(doorCode);
    loadDoorCodes();
  };

  const setDoorCodeCode = (value: string, index: number) => {
    const codes = [...doorCodes];
    codes[index].code = value;
    setDoorCodes(codes);
  };

  // update locker
  const updateLocker = (locker: Locker, index: number) => {
    setLockers(lockers.map((l, i) => (index === i ? locker : l)));
  };
  const saveLocker = (locker: Locker, index: number) => {
    const newLocker = { ...locker };
    //newLocker.doors = newLocker.doors.filter((d) => !d.deprecated);
    if (!newLocker.id) {
      lockerService.createOne(newLocker).then((_locker) => {
        if (_locker) updateLocker(_locker, index);
      });
    } else {
      lockerService.updateOne(newLocker, newLocker.id).then((_locker) => {
        if (_locker) updateLocker(_locker, index);
      });
    }
  };
  const removeLocker = (locker: Locker, index: number) => {
    const newLocker = { ...locker };
    const confirmit = window.confirm(t('locker.deleteConfirm'));
    if (!confirmit) return;
    //newLocker.doors = newLocker.doors.filter((d) => !d.deprecated);
    if (newLocker.id) {
      lockerService.deprecateOne(newLocker.id).then((_locker) => {
        if (_locker) setLockers(lockers.filter((l, i) => index !== i));
      });
    } else {
      setLockers(lockers.filter((l, i) => index !== i));
    }
  };

  const loadLockers = () => {
    lockerService.fetchAllByLocation(locationId).then((lockers: Locker[] | void) => {
      if (lockers) {
        setLockers(lockers);
      }
    });
  };
  const updateLockerDoor = (locker: Locker, index: number, door: LockerDoor, doorIndex: number) => {
    const newLocker = { ...locker };
    newLocker.doors = newLocker.doors.map((d, i) => (doorIndex === i ? door : d));
    updateLocker(newLocker, index);
  };
  const openLockerDoor = (locker: Locker, door: LockerDoor) => {
    if (!locker || !locker.id || !door || !door.id) return false;

    const confirmit = window.confirm(t('locker.openConfirm'));
    if (!confirmit) return false;
    lockerService.openDoor(locker.id, door.id).then((door) => {
      console.log('open door', door);
    });
  };
  const removeLockerDoor = (locker: Locker, index: number, doorIndex: number) => {
    const newLocker = { ...locker };
    newLocker.doors = newLocker.doors.filter((d, i) => doorIndex !== i);
    updateLocker(newLocker, index);
  };

  const addNewLocker = () => {
    if (lockers.find((l) => l.id === undefined)) {
      return;
    }
    const newLocker: Locker = {
      locationId: +locationId,
      description: '',
      doors: [{ id: undefined, door: '1', lockerId: 0, description: '', lockId: '', deprecated: false } as LockerDoor],
      lockerId: '',
      type: LockerType.PUNTA,
      deprecated: false,
    };
    setLockers([newLocker, ...lockers]);
  };

  const addItemToLockerDoor = (itemInstance: ItemInstance) => {
    setLockerDoorItemListDialog({
      ...lockerDoorItemListDialog,
      selectedItems: [...lockerDoorItemListDialog.selectedItems, itemInstance],
    });
  };
  const removeItemFromLockerDoor = (itemInstance: ItemInstance) => {
    const deletedDoors = [...lockerDoorItemListDialog.deletedDoors];
    setLockerDoorItemListDialog({
      ...lockerDoorItemListDialog,
      itemInstances: lockerDoorItemListDialog.itemInstances.map((i) => {
        if (i.itemInstanceId === itemInstance.itemInstanceId) {
          i.lockerDoorId = undefined;
          deletedDoors.push(i);
        }
        return i;
      }),
      selectedItems: lockerDoorItemListDialog.selectedItems.filter(
        (i) => i.itemInstanceId !== itemInstance.itemInstanceId
      ),
      deletedDoors,
    });
  };

  const saveLockerDoorItems = async (locker: Locker, door: LockerDoor, selectedItems: ItemInstance[]) => {
    if (!door || !door.id) return;
    setLockerDoorItemListDialog({ ...lockerDoorItemListDialog, loadingSave: true } as LockerDoorItemListDialogProps);
    const itemInstances = selectedItems.map((i) => {
      const itemInstance = { ...i };
      itemInstance.lockerDoorId = door.id;
      return itemInstance;
    });
    for (const itemInstance of lockerDoorItemListDialog.deletedDoors) {
      await itemService.setLockerDoor(itemInstance.itemInstanceId, 0);
    }
    for (const itemInstance of itemInstances) {
      await itemService.setLockerDoor(itemInstance.itemInstanceId, door.id);
    }

    setLockerDoorItemListDialog({
      ...lockerDoorItemListDialog,
      open: false,
      loadingSave: false,
    } as LockerDoorItemListDialogProps);
    loadItemInstances();
  };

  const addNewLockerDoor = (index: number) => {
    const locker = { ...lockers[index] };

    locker.doors.push({
      id: undefined,
      door: locker.doors.length + 1 + '',
      lockerId: 0,
      description: '',
      lockId: '',
      deprecated: false,
    } as LockerDoor);
    updateLocker(locker, index);
  };
  const getItemInstances = async () => {
    const items = await itemService.fetchAll('&locationId=' + locationId);
    const itemInstances = await itemService.fetchAllInstances('locationId=' + locationId);
    if (itemInstances && items) {
      itemInstances.forEach((itemInstance) => {
        itemInstance.itemModel = items.find((item) => item.itemModelId === itemInstance.itemModelId);
      });
    }
    return itemInstances;
  };
  const loadItemInstances = () => {
    getItemInstances().then((itemInstances) => {
      if (itemInstances) {
        setItemInstances(itemInstances);
      }
    });
  };
  const getItemInstancesCountForLockerDoor = (door: LockerDoor): number => {
    if (!door || !door.id) return 0;
    return itemInstances.filter((i) => i.lockerDoorId === door.id).length;
  };
  const openLockerDoorItemList = (locker: Locker, door: LockerDoor) => {
    if (!locker || !locker.id || !door || !door.id) return false;
    const dialog = { ...lockerDoorItemListDialog, open: true, locker, door, loadingItems: true };
    setLockerDoorItemListDialog(dialog);

    getItemInstances().then((itemInstances) => {
      if (itemInstances) {
        setLockerDoorItemListDialog({
          ...dialog,
          selectedItems: itemInstances.filter((i) => i.lockerDoorId === door.id),
          itemInstances,
          loadingItems: false,
        } as LockerDoorItemListDialogProps);
      }
    });
  };
  useEffect(() => {
    locationService.fetchOne(locationId).then((location: Location | void) => {
      if (location) {
        setLocationDto(location as LocationDTO);
      }
    });
    loadDoorCodes();
    loadLockers();
    loadItemInstances();
    //console.debug('Called effect for location');
  }, [locationId]);
  const closeLockerDoorItemListDialog = () => {
    setLockerDoorItemListDialog({
      ...lockerDoorItemListDialog,
      open: false,
      items: [],
      locker: null,
      door: null,
      loadingSave: false,
      loadingItems: false,
      deletedDoors: [],
      selectedItems: [],
    } as LockerDoorItemListDialogProps);
  };

  const filteredDialogItemInstances = useMemo((): ItemInstance[] => {
    return lockerDoorItemListDialog.itemInstances
      .filter(
        (itemInstance) =>
          !lockerDoorItemListDialog.selectedItems?.map?.((i) => i.itemInstanceId).includes(itemInstance.itemInstanceId)
      )
      .filter((itemInstance) => !itemInstance.lockerDoorId);
  }, [lockerDoorItemListDialog.itemInstances, lockerDoorItemListDialog.selectedItems]);

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.modify')}</h1>
      <div className={`${classes.flexRow} ${classes.flexReverseRow}`}>
        <SaveButton save={saveLocation} checkError={checkError} />

        <div className={classes.rMargin}>
          <RemoveButton remove={removeLocation} />
        </div>
        <div className={classes.rMargin}>
          <BackButton />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {header}
      <LocationForm dto={locationDto} setDto={setLocationDto} initializeWithDto={true} />

      <section style={{ marginTop: theme.spacing(2) }}>
        <Typography variant={'h5'}>{t('doorCode._s')}</Typography>
        <div className={classes.flexRow} style={{ justifyContent: 'end' }}>
          <div className={classes.rMargin}>
            <CreateButton create={addNewDoorCode} checkError={() => false} />
          </div>
        </div>
        <TableContainer component={Paper} style={{ marginTop: theme.spacing(2), maxWidth: 650 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('doorCode.code')}</TableCell>
                <TableCell align={'right'}>
                  <TextField
                    label={t('doorCode.searchFromCodes')}
                    onChange={(e) => {
                      setDoorCodeFilter(e.target.value);
                    }}
                  />{' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doorCodes
                .filter((code) => (doorCodeFilter.length > 0 ? code.code.startsWith(doorCodeFilter) : true))
                .map((doorCode, index) => {
                  return (
                    <TableRow key={doorCode.id || index + '_'}>
                      {doorCode._edit && (
                        <TableCell>
                          <TextField value={doorCode.code} onChange={(e) => setDoorCodeCode(e.target.value, index)} />
                        </TableCell>
                      )}
                      {!doorCode._edit && <TableCell>{doorCode.code}</TableCell>}
                      <TableCell align={'right'}>
                        {!doorCode._edit && (
                          <IconButton onClick={() => deprecateCode(doorCode.id)}>
                            <Delete />
                          </IconButton>
                        )}
                        {doorCode._edit && (
                          <IconButton onClick={() => saveCode(doorCode)}>
                            <Save />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </section>

      <Divider style={{ marginTop: theme.spacing(2) }} />
      <section style={{ marginTop: theme.spacing(2) }}>
        <Typography variant={'h5'}>{t('locker._s')}</Typography>
        <div className={classes.flexRow} style={{ justifyContent: 'end' }}>
          <div className={classes.rMargin}>
            <CreateButton create={addNewLocker} checkError={() => false} />
          </div>
        </div>
        {lockers.map((locker, index) => {
          return (
            <TableContainer
              key={locker.id ? locker.id : index + '_'}
              component={Paper}
              style={{ marginTop: theme.spacing(2), maxWidth: 900 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('locker.description')}</TableCell>
                    <TableCell>{t('locker.lockerId')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={locker.id ? locker.id : index + '_'}>
                    <TableCell>
                      <TextField
                        value={locker.description}
                        onChange={(e) => updateLocker({ ...locker, description: e.target.value }, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={locker.lockerId}
                        style={{ width: '250px' }}
                        onChange={(e) => updateLocker({ ...locker, lockerId: e.target.value }, index)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={locker.id ? locker.id + '_doors' : index + '_doors'}>
                    <TableCell colSpan={2}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: '40px' }}>{t('locker.door.door')}</TableCell>
                            <TableCell>{t('locker.door.description')} </TableCell>
                            <TableCell>{t('locker.door.lockId')}</TableCell>
                            <TableCell className={classes.hFlex} style={{ justifyContent: 'flex-end' }}>
                              <IconButton onClick={() => addNewLockerDoor(index)}>
                                <Add></Add>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {locker.doors.map((door, doorIndex) => {
                            return (
                              <TableRow key={door.id ? door.id : doorIndex + '_'}>
                                <TableCell>
                                  <TextField
                                    value={door.door}
                                    onChange={(e) =>
                                      updateLockerDoor(locker, index, { ...door, door: e.target.value }, doorIndex)
                                    }
                                  />
                                </TableCell>

                                <TableCell>
                                  <TextField
                                    value={door.description}
                                    style={{ width: '100%' }}
                                    onChange={(e) =>
                                      updateLockerDoor(
                                        locker,
                                        index,
                                        { ...door, description: e.target.value },
                                        doorIndex
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    style={{ width: '220px' }}
                                    value={door.lockId}
                                    onChange={(e) =>
                                      updateLockerDoor(locker, index, { ...door, lockId: e.target.value }, doorIndex)
                                    }
                                  />
                                </TableCell>
                                <TableCell className={`${classes.hFlex}`} style={{ justifyContent: 'flex-end' }}>
                                  {door && door.id && (
                                    <IconButton onClick={() => openLockerDoorItemList(locker, door)}>
                                      <Badge badgeContent={getItemInstancesCountForLockerDoor(door)} color={'primary'}>
                                        <List />
                                      </Badge>
                                    </IconButton>
                                  )}
                                  {door && door.id && (
                                    <IconButton onClick={() => openLockerDoor(locker, door)}>
                                      <LockOpen></LockOpen>
                                    </IconButton>
                                  )}
                                  <IconButton onClick={() => removeLockerDoor(locker, index, doorIndex)}>
                                    <Delete></Delete>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                  <TableRow key={locker.id ? locker.id + '_save' : index + '_save'}>
                    <TableCell colSpan={2}>
                      <div className={classes.flexRow} style={{ justifyContent: 'end' }}>
                        <div className={classes.rMargin}>
                          <RemoveButton remove={() => removeLocker(locker, index)} />
                        </div>
                        <div className={classes.rMargin}>
                          <SaveButton
                            save={() => saveLocker(locker, index)}
                            checkError={() => {
                              return false;
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
        <Dialog open={lockerDoorItemListDialog.open} onClose={closeLockerDoorItemListDialog} fullWidth={true}>
          <DialogTitle>
            {t('locker.door._')} {lockerDoorItemListDialog.door?.door} {lockerDoorItemListDialog.door?.description}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography variant={'h6'}>
              {t('locker.selectedItems')} ({lockerDoorItemListDialog?.selectedItems.length})
            </Typography>
            <div className={`${classes.vFlex} ${classes.rMargin}`}>
              {lockerDoorItemListDialog?.selectedItems.map?.((itemInstance, index) => {
                return (
                  <div key={itemInstance.itemInstanceId} className={`${classes.card}`}>
                    <InstanceComponent
                      instance={itemInstance}
                      item={itemInstance.itemModel || ({} as ItemModel)}
                      redirectUrl={''}
                      borrowButton={false}
                      showBorrowStatus={false}
                      onClick={() => removeItemFromLockerDoor(itemInstance)}
                    />
                  </div>
                );
              })}
              {lockerDoorItemListDialog?.selectedItems.length === 0 && (
                <Typography variant={'body1'} style={{ marginTop: '5px', marginBottom: '5px' }}>
                  {t('locker.noSelectedItems')}
                </Typography>
              )}
            </div>
            <Divider className={classes.vMargin} />
            <Typography variant={'h6'}>{t('locker.selectItem')}</Typography>

            {filteredDialogItemInstances.length > 0 && (
              <Typography variant={'body2'}>{t('locker.selectItemHelp')}</Typography>
            )}

            <div className={`${classes.vFlex} ${classes.rMargin}`}>
              {lockerDoorItemListDialog?.loadingItems && <LinearProgress />}
              {!lockerDoorItemListDialog?.loadingItems &&
                filteredDialogItemInstances?.map((itemInstance, index) => {
                  return (
                    <div key={itemInstance.itemInstanceId} className={`${classes.card}`}>
                      <InstanceComponent
                        instance={itemInstance}
                        item={itemInstance.itemModel || ({} as ItemModel)}
                        redirectUrl={''}
                        borrowButton={false}
                        showBorrowStatus={false}
                        onClick={() => addItemToLockerDoor(itemInstance)}
                      />
                    </div>
                  );
                })}
              {!lockerDoorItemListDialog?.loadingItems && filteredDialogItemInstances.length === 0 && (
                <Typography variant={'body1'} style={{ marginTop: '5px', marginBottom: '5px' }}>
                  {t('locker.noItems')}
                </Typography>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <CloseButton close={closeLockerDoorItemListDialog} />
            <SaveButton
              save={() =>
                saveLockerDoorItems(
                  lockerDoorItemListDialog.locker || ({} as Locker),
                  lockerDoorItemListDialog.door || ({} as LockerDoor),
                  lockerDoorItemListDialog.selectedItems
                )
              }
              checkError={() => false}
            />
          </DialogActions>
        </Dialog>
      </section>
    </div>
  );
}

export default AdminOldLocationView;
