import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, NewsForm, RemoveButton, SaveButton } from '../../components';
import { errorInNewsArticle } from '../../components/admin/validation';
import { URLS } from '../../constants';
import { NewsArticle, NewsArticleDTO } from '../../models';
import { newsService } from '../../services';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

function AdminOldNewsArticleView(props: any): JSX.Element {
  const newsArticleId: number = props.match.params.newsArticleId;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [newsArticleDto, setNewsArticleDto]: [NewsArticleDTO, any] = useState({ author: '', heading: '', body: '' });
  const userId: string = useSelector(selectUser).id ?? '';

  useEffect(() => {
    newsService.fetchOne(newsArticleId).then((newsarticle: NewsArticle | void) => {
      if (newsarticle) {
        setNewsArticleDto(newsarticle as NewsArticleDTO);
      }
    });
    //console.debug('Called effect for newsarticle');
  }, [newsArticleId]);

  const saveNewsArticle: () => void = () => {
    newsService.updateOne(userId, newsArticleDto, newsArticleId).then(() => {
      history.push(URLS.ADMIN.NEWS);
    });
  };

  const checkError: () => boolean = () => {
    return errorInNewsArticle(newsArticleDto);
  };

  const removeNewsArticle: () => void = () => {
    newsService.deleteOne(userId, newsArticleId).then(() => {
      history.push(URLS.ADMIN.NEWS);
    });
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.modify')}</h1>
      <div className={classes.flexRow}>
        <div className={classes.rMargin}>
          <SaveButton save={saveNewsArticle} checkError={checkError} />
        </div>
        <div className={classes.rMargin}>
          <RemoveButton remove={removeNewsArticle} />
        </div>
        <BackButton />
      </div>
    </div>
  );

  return (
    <div>
      {header}
      <NewsForm dto={newsArticleDto} setDto={setNewsArticleDto} initializeWithDto={true} />
    </div>
  );
}

export default AdminOldNewsArticleView;
