import { URLS } from '../constants';
import { baseUrl } from '../util';

// we figure out how to do it.
export const config = {
  authority: baseUrl,
  client_id: 'Lender',
  redirect_uri: baseUrl + URLS.AUTH.LOGIN_CB,
  post_logout_redirect_uri: baseUrl + URLS.AUTH.LOGOUT_CB,
  response_type: 'code',
  scope: 'LenderAPI openid profile',
  response_mode: 'query',
};
