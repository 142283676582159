import { Button, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InstanceComponent } from '../../components';
import QrScanner from '../../components/qr-scanner/QrScanner';
import { URLS } from '../../constants';
import { ItemInstance, ItemModel } from '../../models';
import { itemService } from '../../services';
import { createErrorNotification } from '../../services/helpers';
import { selectLocations } from '../../store/locationsSlice';

function ScanView(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const [codeInput, setCodeInput] = useState<string>('');
  const [item, setItem] = useState<ItemModel>({} as ItemModel);
  const [instance, setInstance] = useState<ItemInstance>({} as ItemInstance);
  const locations = useSelector(selectLocations);

  const handleSuccess = async (scannedItem: ItemInstance): Promise<void> => {
    // TODO: Do the actual action here
    if (scannedItem) {
      const item: ItemModel | any = await itemService.fetchOne(Number(scannedItem.itemModelId));
      setItem(item);
    }
    setInstance(scannedItem);
  };

  const handleCodeInputSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    try {
      const itemInstanceId: number = parseInt(codeInput);
      const scannedInstanceModel = await itemService.fetchOneInstanceNoError(itemInstanceId);
      /*if (scannedInstanceModel) {
        const item: ItemModel | any = await itemService.fetchOne(Number(scannedInstanceModel.itemModelId));
        setItem(item);
      }*/

      if (scannedInstanceModel) {
        handleSuccess(scannedInstanceModel);
      } else {
        createErrorNotification(t('notifications.error.scanNotRecognized'));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRedirect = async (): Promise<void> => {
    try {
      if (instance.itemInstanceId) {
        history.push({
          pathname: URLS.EU.BORROWING,
          state: { instance, item },
        });
      } else {
        createErrorNotification(t('notifications.error.scanNotRecognized'));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleClear = async (): Promise<void> => {
    setItem({} as ItemModel);
    setInstance({} as ItemInstance);
  };

  return (
    <div>
      <h1>{t('scan.header')}</h1>

      {!instance.itemInstanceId && (
        <div>
          <QrScanner onSuccess={handleSuccess} />
        </div>
      )}
      {instance.itemInstanceId && (
        <div style={{ marginBottom: '3em' }}>
          <Button autoFocus onClick={handleClear} variant="contained" color="secondary">
            {t('item.actions.scanAgain')}
          </Button>
        </div>
      )}

      <div style={{ marginTop: '16px', marginBottom: '16px' }}>
        <form onSubmit={handleCodeInputSubmit}>
          <TextField
            label={t('scan.manually')}
            variant="outlined"
            onChange={(event) => setCodeInput(event.target.value)}
            value={codeInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <ArrowForwardIosIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </div>
      {instance.itemInstanceId && (
        <InstanceComponent item={item} instance={instance} borrowButton={false}></InstanceComponent>
      )}
      <div style={{ marginTop: '2em' }} />

      <Button
        autoFocus
        onClick={handleRedirect}
        variant="contained"
        color="secondary"
        disabled={!instance.itemInstanceId || !instance.available}
      >
        {t('item.actions.borrow')}
      </Button>
      {instance.itemInstanceId && !instance.available && (
        <Typography variant="body2" color="error" className={'mt-2'}>
          Tavara on jo lainattu
        </Typography>
      )}
    </div>
  );
}

export default ScanView;
