import { UserManager } from 'oidc-client';
import { config } from '../libs/OidcConfig';
import { store } from '../store';
import { addError, NotificationItem } from '../store/notificationSlice';
import { updateLocationFilter } from '../store/searchSlice';
import { addUser } from '../store/userSlice';
import userService from './userService';

const userManager = new UserManager(config);

export async function signinRedirect(): Promise<void> {
  try {
    await userManager.signinRedirect();
  } catch (e) {
    const notification: NotificationItem = { message: 'Error while trying to login' };
    store.dispatch(addError(notification));
  }
}

export async function signinRedirectCallback(): Promise<void> {
  try {
    await userManager.signinRedirectCallback();
  } catch (error) {
    const notification: NotificationItem = { message: 'Error while logging in' };
    store.dispatch(addError(notification));
  }
}

export async function signoutRedirect(): Promise<void> {
  try {
    await userManager.signoutRedirect();
    await userManager.clearStaleState();
  } catch (e) {
    const notification: NotificationItem = { message: 'Error while trying logout' };
    store.dispatch(addError(notification));
  }
}

export async function signoutRedirectCallback(): Promise<void> {
  try {
    await userManager.signoutRedirectCallback();
    await userManager.clearStaleState();
  } catch (error) {
    const notification: NotificationItem = { message: 'Error while logging out' };
    store.dispatch(addError(notification));
  }
}

export async function signInSilent(): Promise<void> {
  try {
    await userManager.signinSilent();
  } catch (error) {
    const notification: NotificationItem = { message: 'Error renewing token' };
    store.dispatch(addError(notification));
  }
}

export async function signInSilentCallback(): Promise<void> {
  try {
    await userManager.signinSilentCallback();
  } catch (error) {
    const notification: NotificationItem = { message: 'Error renewing token' };
    store.dispatch(addError(notification));
  }
}

export async function getUserFromStorage(): Promise<void> {
  try {
    const storedUser = await userManager.getUser();
    if (storedUser?.profile.sub) {
      const activeUser = await userService.fetchOne(storedUser?.profile.sub);
      if (activeUser) {
        store.dispatch(addUser(activeUser));
        if (activeUser.defaultLocationId) {
          store.dispatch(updateLocationFilter(true));
        }
      }
    }
  } catch (e) {
    const notification: NotificationItem = { message: 'Error loading user information' };
    store.dispatch(addError(notification));
  }
}

export function isAuthenticated(): boolean {
  const item = sessionStorage.getItem(`oidc.user:${config.authority}:${config.client_id}`);
  if (item) {
    const oidcStorage = JSON.parse(item);

    return !!oidcStorage && !!oidcStorage.access_token;
  }
  return false;
}

export default userManager;
