import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ItemResults, SearchBar, SearchFilters } from '../../components';
import { URLS } from '../../constants';
import { ItemInstance, ItemModel } from '../../models';
import { itemService } from '../../services';
import { selectQuery } from '../../store/searchSlice';
import useStyles from '../../styles';

function SearchPage(props: any): JSX.Element {
  const [items, setItems] = useState<ItemModel[]>([]);
  const [instances, setInstances] = useState<ItemInstance[]>([]);
  const [firstQuery, setFirstQuery] = useState<boolean>(true);
  const query = useSelector(selectQuery);
  const classes = useStyles();
  const { t } = useTranslation();
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [loadingInstances, setLoadingInstances] = useState<boolean>(false);
  useEffect(() => {
    // A bit hackish way of checking store before fetching everything (as there's no query)
    if (firstQuery) {
      setFirstQuery(false);
      return;
    }

    setLoadingItems(true);
    const q = query ? `${query}&GetInstances=true` : '&GetInstances=true';
    itemService.fetchAll(q).then((items: ItemModel[] | void) => {
      if (items) {
        setItems(items);
        setInstances(items.flatMap((item) => item.instances ?? []).filter(Boolean));
        setLoadingItems(false);
      }
    });
    /*setLoadingInstances(true);
    itemService.fetchAllInstances(query).then((instances: ItemInstance[] | void) => {
      if (instances) {
        setInstances(instances);
        setLoadingInstances(false);
      }
    });*/
  }, [firstQuery, query]);

  return (
    <div>
      <h1>{t('search.header')}</h1>
      <div className={classes.hFlex}>
        <SearchBar redirectUrl={URLS.EU.SEARCH} searchingFor={'items'} initialQuery={props?.match?.params?.query} />
        <SearchFilters />
      </div>
      <ItemResults
        items={items}
        instances={instances}
        redirectUrl={URLS.EU.ITEM_NOQUERY}
        loading={loadingItems || loadingInstances}
      />
    </div>
  );
}

export default SearchPage;
