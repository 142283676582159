import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export enum Language {
  FI = 'fi',
  EN = 'en',
}

export enum CurrentView {
  A_DASHBOARD,
  A_ITEMS,
  A_INSTANCES,
  A_BORROWINGS,
  A_LOCATIONS,
  A_USERS,
  A_NEWS,
  A_LOGS,
  A_FEEDBACK,
  A_HELP,
  A_MODIFY,
  T_HOME,
  T_SEARCH,
  T_SCAN,
  T_LIKES,
  T_BORROWINGS,
  T_NEWS,
  T_PROFILE,
  T_HELP,
  T_LEGAL,
  T_LEND,
  A_ORDERS,
  A_PROBLEMS,
}

interface SettingsState {
  language: Language;
  mobileDrawerOpen: boolean;
  skipImageReload: number[];
  currentView: CurrentView | undefined;
}

export const initialState: SettingsState = {
  language: Language.FI,
  mobileDrawerOpen: false,
  skipImageReload: [],
  currentView: undefined,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;
    },
    toggleMobileDrawerOpen(state) {
      state.mobileDrawerOpen = !state.mobileDrawerOpen;
    },
    addSkipForImage(state, action: PayloadAction<any>) {
      const imageId = action.payload;
      if (!state.skipImageReload.includes(imageId)) {
        state.skipImageReload.push(action.payload);
      }
    },
    rewriteSkipForImage(state, action: PayloadAction<number[]>) {
      state.skipImageReload = action.payload;
    },
    setCurrentView(state, action: PayloadAction<CurrentView | undefined>) {
      state.currentView = action.payload;
    },
  },
});

export const { setLanguage, toggleMobileDrawerOpen, addSkipForImage, rewriteSkipForImage, setCurrentView } =
  settingsSlice.actions;

export const selectLanguage = (state: RootState): Language => state.settings.language;
export const selectMobileDrawerOpen = (state: RootState): boolean => state.settings.mobileDrawerOpen;
export const selectAllImageSkips = (state: RootState): number[] => state.settings.skipImageReload;
export const selectSkipImageReload = (state: RootState, imageId: number): boolean =>
  state.settings.skipImageReload.includes(imageId);
export const selectCurrentView = (state: RootState): CurrentView | undefined => state.settings.currentView;

export default settingsSlice.reducer;
