import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { ItemInstance } from '../models';
import Order from '../models/Order';
import OrderProduct from '../models/OrderProduct';
import { default as PaymentMethodsModel } from '../models/PaymentMethodsModel';
import PaymentTokenModel from '../models/PaymentTokenModel';
import { checkStatus, createErrorNotification } from './helpers';
const alterPrices = (orderProduct: OrderProduct, encode = false): OrderProduct => {
  if (!encode) {
    return {
      ...orderProduct,
      price: parseFloat(String(orderProduct.price / 100)),
      pretaxPrice: parseFloat(String(orderProduct.pretaxPrice / 100)),
    };
  } else {
    return {
      ...orderProduct,
      price: parseInt(String(orderProduct.price * 100)),
      pretaxPrice: parseInt(String(orderProduct.pretaxPrice * 100)),
    };
  }
};
const alterPricesOrder = (order: Order, encode = false): Order => {
  if (!encode) {
    return {
      ...order,
      amount: parseFloat(String(order.amount / 100)),
    };
  } else {
    return {
      ...order,
      amount: parseInt(String(order.amount * 100)),
    };
  }
};
const fetchPaymentMethods = async function (): Promise<PaymentMethodsModel[] | void> {
  const model: string = i18n.t('data.models.paymentMethod');
  const query = `${URLS.API}/vismapay/payment/methods`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<PaymentMethodsModel[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
const fetchPaymentToken = async function (
  instance: ItemInstance,
  selectedPaymentMethod: string
): Promise<PaymentTokenModel | void> {
  const model: string = i18n.t('data.models.paymentMethod');
  const query = `${URLS.API}/vismapay/payment/token/${instance.itemInstanceId}/${selectedPaymentMethod}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<PaymentTokenModel>;
  } catch (err) {
    console.error(err);
    //createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
const fetchOrders = async function (): Promise<Order[] | void> {
  const model: string = i18n.t('data.models.paymentMethod');
  const query = `${URLS.API}/vismapay/orders`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    let orders = (await res.json()) as Order[];
    orders = orders.map((o) => {
      o = alterPricesOrder(o);
      o.products = o.products?.map((p) => {
        return alterPrices(p);
      });
      return o;
    });
    return Promise.resolve(orders);
  } catch (err) {
    console.error(err);
    //createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
export default { fetchPaymentMethods, fetchPaymentToken, fetchOrders };
