import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, CreateButton, ItemForm } from '../../components';
import { errorInImage, errorInItem } from '../../components/admin/validation';
import { URLS } from '../../constants';
import { ImageData, ItemInstance, ItemInstanceDTO, ItemModel, ItemModelDTO, Location } from '../../models';
import { imageService, itemService, locationService } from '../../services';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

function AdminNewItemView(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [itemModelDto, setItemModelDto] = useState<ItemModelDTO>({
    name: '',
    description: '',
    maxBorrowingTime: parseInt(''),
  });
  const [itemInstanceDto, setItemInstanceDto] = useState<ItemInstanceDTO>({
    itemModelId: 0,
    size: '',
    locationId: 0,
    pretaxPrice: 0,
    price: 0,
    tax: 24,
  });
  const [locations, setLocations]: [Location[], any] = useState([]);
  const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
  // Allow location selector to be controlled
  const [currentLocation, setCurrentLocation]: [string, any] = useState('');
  const userId: string = useSelector(selectUser).id ?? '';

  useEffect(() => {
    locationService.fetchAll().then((locations) => setLocations(locations));
    //console.debug('Called effect for locations');
  }, []);

  const createItem: () => void = async () => {
    const item: ItemModel | void = await itemService.createOne(userId, itemModelDto);
    if (item && imageData) {
      itemInstanceDto.itemModelId = item.itemModelId;
      const instance: ItemInstance | void = await itemService.createOneInstance(userId, itemInstanceDto);
      const imageCreated = await imageService.createOne(item.itemModelId, imageData.blob);
      if (imageCreated) {
        history.push(`${URLS.ADMIN.ITEM}/${item.itemModelId}`);
        //history.push(URLS.ADMIN.ITEM);
      }
    }
  };

  const checkError: () => boolean = () => {
    return errorInItem(itemModelDto) || errorInImage(imageData?.blob);
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.createNew')}</h1>
      <div className={classes.flexRow}>
        <div className={classes.rMargin}>
          <BackButton />
        </div>
        <CreateButton create={createItem} checkError={checkError} />
      </div>
    </div>
  );

  return (
    <div>
      {header}
      <ItemForm
        dto={itemModelDto}
        setDto={setItemModelDto}
        setImage={setImageData}
        setImage2={undefined}
        setImage3={undefined}
        imageData={imageData}
        imageData3={undefined}
        imageData2={undefined}
        initializeWithDto={false}
        locations={locations}
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        instanceDto={itemInstanceDto}
        setInstanceDto={setItemInstanceDto}
        instances={[]}
      />
    </div>
  );
}

export default AdminNewItemView;
