import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BorrowingResults } from '../../components';
import { URLS } from '../../constants';
import { Borrowing, BorrowingInfo, ItemInstance, User } from '../../models';
import { borrowingService, itemService, userService } from '../../services';
import { RootState } from '../../store';
import { selectLocations } from '../../store/locationsSlice';

function AdminBorrowingsView(): JSX.Element {
  const { t } = useTranslation();
  const userId = useSelector((state: RootState) => state.user.id);
  const [borrowings, setBorrowings] = useState<Borrowing[]>([]);
  const [borrowingInfos, setBorrowingInfos] = useState<BorrowingInfo[]>([]);
  const locations = useSelector(selectLocations);

  const reload = () => {
    borrowingService.fetchAllActive().then((borrowingsResponse: Borrowing[] | void) => {
      if (borrowingsResponse) {
        setBorrowings(borrowingsResponse);
      }
    });
  };
  // Fetch active borrowings in the beginning
  useEffect(() => {
    // Route is protected, user has to be logged in
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    reload();
  }, [userId]);

  const [users, setUsers]: [User[], any] = useState([]);
  useEffect(() => {
    userService.fetchAll().then((users: User[] | void) => {
      setUsers(users ?? []);
    });
  }, []);
  // Fetch all liked item objects when likes are fetched
  useEffect(() => {
    const borrowedIds = borrowings.map((borr) => borr.borrowedItemId);
    if (borrowedIds.length !== 0) {
      // TODO: Fetch by list or something
      itemService.fetchAllInstances().then((itemsResponse: ItemInstance[] | void) => {
        // Pair every borrowing with corresponding item to get all needed information
        if (itemsResponse) {
          const borrowingInfos: BorrowingInfo[] = [];
          const borrowedItems: ItemInstance[] = itemsResponse.filter((instance: ItemInstance) =>
            borrowedIds.includes(instance.itemInstanceId)
          );
          borrowings.forEach((borr: Borrowing) => {
            const correspondingItem: ItemInstance | undefined = borrowedItems.find(
              (instance: ItemInstance) => instance.itemInstanceId === borr.borrowedItemId
            );
            const borrowerId: string = borr.borrowerId;
            if (correspondingItem) {
              borrowingInfos.push({ itemInstance: correspondingItem, borrowing: borr, borrowerId: borrowerId });
            }
          });
          setBorrowingInfos(borrowingInfos);
        }
      });
    }
  }, [borrowings]);

  const borrowingsByLocation: { [key: number]: BorrowingInfo[] } = {};
  useMemo(() => {
    borrowingInfos.forEach((borrowing) => {
      borrowing.user = users.find((user) => user.id === borrowing.borrowerId);
    });
    borrowingInfos.map((borrowing) => {
      if (!borrowingsByLocation[borrowing.itemInstance.locationId || -2])
        borrowingsByLocation[borrowing.itemInstance.locationId || -2] = [];
      borrowingsByLocation[borrowing.itemInstance.locationId || -2].push(borrowing);
    });
  }, [borrowingInfos, users]);

  return (
    <div>
      <h1>{t('views.borrowings')}</h1>
      {Object.keys(borrowingsByLocation).map((locationId) => {
        const location = locations.find((l) => l.locationId === parseInt(locationId));
        return (
          <div key={locationId}>
            <h2 style={{ marginTop: '60px', marginBottom: '-10px' }}>{location?.name || 'Ei kohdetta'}</h2>
            <BorrowingResults
              reload={reload}
              infos={borrowingsByLocation[parseInt(locationId)]}
              redirectUrl={URLS.EU.INSTANCE_NOQUERY}
              adminPage={true}
            />
          </div>
        );
      })}
    </div>
  );
}

export default AdminBorrowingsView;
