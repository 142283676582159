import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert, ReportProblemOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TermsOfUseItem from '../../../components/item/TermsOfUseItem';
import { ItemInstance } from '../../../models';
import useStyles from '../../../styles';
import { ReportProblem } from './ReportProblem';

interface DetailedItemModalsProps {
  instance: ItemInstance;
}

type VisibleModal = 'termsOfUse' | 'problemReport' | 'none';

export const DetailedItemModals = (props: DetailedItemModalsProps): JSX.Element => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal>('none');

  const closeModal = (): void => {
    setVisibleModal('none');
  };

  function ItemDropdown(): JSX.Element {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget);
    };

    const closeMenu = (): void => setAnchorEl(null);

    return (
      <div className={classes.hFlex}>
        <IconButton onClick={handleClick}>
          <MoreVert fontSize="large" />
        </IconButton>
        <IconButton
          onClick={() => {
            closeMenu();
            setVisibleModal('problemReport');
          }}
        >
          <ReportProblemOutlined fontSize="large" />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={closeMenu}>
          <MenuItem
            onClick={() => {
              closeMenu();
              setVisibleModal('termsOfUse');
            }}
          >
            {t('item.usageTerms')}
          </MenuItem>
          {false && (
            <MenuItem
              onClick={() => {
                closeMenu();
                setVisibleModal('problemReport');
              }}
            >
              {t('feedback.reportProblem')}
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
  return (
    <>
      <ItemDropdown />
      {visibleModal === 'termsOfUse' && <TermsOfUseItem closeModal={closeModal} />}
      {visibleModal === 'problemReport' && <ReportProblem instance={props.instance} closeModal={closeModal} />}
    </>
  );
};
