import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LocationComponent } from '..';
import { Location } from '../../models';
import { selectQuery } from '../../store/searchSlice';
import useStyles from '../../styles';

interface LocationResultsProps {
  locations: Location[];
  redirectUrl: string;
  actionButton?: JSX.Element;
}

function LocationResults(props: LocationResultsProps): JSX.Element {
  const { t } = useTranslation();
  const query = useSelector(selectQuery);
  const classes = useStyles();
  const filteredLocations = props.locations.filter((location: Location) => {
    // TODO: Replace with better filtering
    return query ? location.name.toLowerCase().includes(query.toLowerCase()) : true;
  });

  // TODO: To helper function
  const displayResultText = (): string => {
    if (!query) {
      return t('search.browse.locations');
    }
    switch (filteredLocations.length) {
      case 0:
        return t('search.results.none');
      case 1:
        return t('search.results.one');
      default:
        return t('search.results.many', { x: filteredLocations.length });
    }
  };

  const sortLocationsByName = (i1: Location, i2: Location): number =>
    i1.name.toLowerCase() > i2.name.toLowerCase() ? 1 : -1;

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <h2>{displayResultText()}</h2>
        {props.actionButton !== undefined && props.actionButton}
      </div>
      <Grid container className={classes.fGrow} spacing={2}>
        {filteredLocations.sort(sortLocationsByName).map((location: Location, i: number) => (
          <Grid key={i} xl={3} lg={4} md={6} sm={12} xs={12} item style={{ width: '240px' }}>
            <LocationComponent key={location.locationId} location={location} redirectUrl={props.redirectUrl} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default LocationResults;
