import React, { useEffect, useState } from 'react';
import { NewsArticleResults } from '../../components';
import { URLS } from '../../constants';
import { NewsArticle } from '../../models';
import { newsService } from '../../services';

function NewsPage(): JSX.Element {
  const [newsarticles, setNewsarticles]: [NewsArticle[], any] = useState([]);

  useEffect(() => {
    newsService.fetchAll().then((newsarticles: NewsArticle[] | void) => setNewsarticles(newsarticles ?? []));
    //console.debug('Called effect for news');
  }, []);

  // TODO: heading as prop so Results can be used in both admin and eu view?
  // TODO: add and fix searchbar, if needed
  return (
    <div>
      {/*<SearchBar redirectUrl={URLS.EU.NEWS} searchingFor="news" initialQuery={props.match.params.query} />*/}
      <NewsArticleResults newsarticles={newsarticles} redirectUrl={URLS.EU.NEWS} />
    </div>
  );
}

export default NewsPage;
