import {
  Box,
  Collapse,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Order from '../../models/Order';
import vismaPayService from '../../services/vismaPayService';
import { selectLocations } from '../../store/locationsSlice';
import useStyles from '../../styles';
interface OrderRowProps {
  order: Order;
}
function AdminOrdersView(props: any): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [orders, setOrders] = useState([] as Order[]);
  const locations = useSelector(selectLocations);
  useEffect(() => {
    vismaPayService.fetchOrders().then((r) => {
      if (r) {
        setOrders(r.reverse());
      }
    });
  }, []);

  const returnCodeMap: { [key: string]: string } = {
    '0': 'Payment completed successfully',
    '1': 'Payment failed',
    '4': 'Transaction status could not be updated after customer returned from the web page of a bank.',
    '10': 'Maintenance break',
  };
  const settledCodeMap: { [key: string]: string } = {
    '0': 'Authorized',
    '1': 'Settled',
  };
  const OrderRow = ({ order }: OrderRowProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const location = locations.find((loc) => loc.locationId === order.products?.[0]?.itemInstance?.locationId);
    return (
      <>
        <TableRow style={{ backgroundColor: order.paid ? 'rgba(76,175,80,0.1)' : 'rgba(239,83,80,0.1)' }}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{order.id}</TableCell>
          <TableCell>{order.firstname}</TableCell>
          <TableCell>{order.lastname}</TableCell>
          <TableCell>{order.email}</TableCell>
          <TableCell>{order.paid && moment(order.paid).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
          <TableCell style={{ textAlign: 'right' }}>{numeral(order.amount).format('0,0.00')}</TableCell>
          <TableCell>
            <Link
              href={'#'}
              onClick={(e: any) => {
                e.preventDefault();
                history.push('/instance/' + order.products?.[0]?.itemInstanceId);
              }}
            >
              {order.products?.[0]?.title}: {order.products?.[0]?.itemInstance?.size}
            </Link>
          </TableCell>
          <TableCell>
            {order.products?.[0]?.borrowing?.endDate &&
              moment(order.products?.[0]?.borrowing?.endDate).format('DD.MM.YYYY')}
          </TableCell>
          <TableCell>{order.products?.[0]?.borrowing?.borrowingId}</TableCell>
          <TableCell>{location?.name}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={11} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small">
                  <TableHead>
                    <TableRow style={{ background: 'rgba(0, 0, 0, 0.01)' }}>
                      <TableCell></TableCell>
                      <TableCell style={{ fontWeight: 500 }}>{t('order.addressStreet')}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}>{t('order.addressPostalcode')}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}>{t('order.addressCity')}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}>{t('order.createdAt')}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}>{t('order.returnCode')}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}>{t('order.settled')}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}>{t('order.incidentId')}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}></TableCell>
                      <TableCell style={{ fontWeight: 500 }}></TableCell>
                      <TableCell style={{ fontWeight: 500 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{order.addressStreet}</TableCell>
                      <TableCell>{order.addressPostalcode}</TableCell>
                      <TableCell>{order.addressCity}</TableCell>
                      <TableCell>{order.createdAt && moment(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                      <TableCell title={order.returnCode}>
                        {returnCodeMap[order.returnCode] ? returnCodeMap[order.returnCode] : order.returnCode}
                      </TableCell>
                      <TableCell title={order.settled}>
                        {settledCodeMap[order.settled] ? settledCodeMap[order.settled] : order.settled}
                      </TableCell>
                      <TableCell>{order.incidentId}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <div>
      <div className={classes.flexRow}>
        <h1>{t('views.orders')}</h1>
      </div>

      <div></div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('order.ordernumber')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('order.firstname')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('order.lastname')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('order.email')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('order.paid')}</TableCell>
                <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>{t('order.amount')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('orderProduct.title')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {t('borrowing.header')} {t('borrowing.endDate')}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('borrowing.header')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('data.fields.location')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                return <OrderRow order={order} key={order.id} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AdminOrdersView;
