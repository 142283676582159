import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MDTextDisplay } from '../../components';
import { pageContentService } from '../../services';
import { selectLanguage } from '../../store/settingsSlice';

function AdminHelpView(): JSX.Element {
  const language = useSelector(selectLanguage);
  const [text, setText] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  useEffect(() => {
    let lang = 0;
    if (language === 'fi') {
      lang = 1;
    } else if (language === 'en') {
      lang = 2;
    }
    pageContentService.getPage(4, lang).then((res) => {
      const result: any = res;
      setText(result[result.length - 1]?.body);
      setHeading(result[result.length - 1]?.heading);
    });
  }, [language]);

  return (
    <div>
      <h1>{heading}</h1>
      <MDTextDisplay path={text} />
    </div>
  );
}

export default AdminHelpView;
