import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Like } from '../models';
import { checkStatus, createErrorNotification } from './helpers';

const fetchAll = async function (userId: string): Promise<Like[] | void> {
  const model: string = i18n.t('data.models.like');
  const query = `${URLS.API}/likes?userId=${userId}`;
  const init: RequestInit = { cache: 'no-store' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    return res.json() as Promise<Like[]>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOne = async function (userId: string, itemModelId: number): Promise<Like | undefined> {
  const model: string = i18n.t('data.models.like');
  if (!userId) return undefined;
  const query = `${URLS.API}/likes/${itemModelId}?userId=${userId}`;
  const init: RequestInit = { cache: 'no-store' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    return res.json() as Promise<Like>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
    return undefined;
  }
};

const updateOne = async function (userId: string, itemModelId: number): Promise<Like | void> {
  const model: string = i18n.t('data.models.like');
  const query = `${URLS.API}/likes`;
  const init: RequestInit = {
    body: JSON.stringify({ likedById: userId, likedItemModelId: +itemModelId }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const like: Promise<Like> = res.json() as Promise<Like>;
    // createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return like;
  } catch {
    createErrorNotification(i18n.t('notifications.error.update', { model }));
  }
};

const deleteOne = async function (userId: string, itemModelId: number): Promise<Like | void> {
  const model: string = i18n.t('data.models.like');
  const query = `${URLS.API}/likes/${itemModelId}?userId=${userId}`;
  const init: RequestInit = { method: 'DELETE' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const like: Promise<Like> = res.json() as Promise<Like>;
    // createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return like;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};

export default { fetchAll, fetchOne, updateOne, deleteOne };
