import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Location, UserDTO } from '../../models';
import { locationService, userService } from '../../services';
import { createSuccessNotification } from '../../services/helpers';
import { addUser, selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

interface UserProfileState {
  email: string;
  firstName: string;
  lastName: string;
  defaultLocationId: number | null;
  newPassword: string;
  confirmPassword: string;
  showPassword: boolean;
  isReadOnly: boolean;
  address: string;
  postalcode: string;
  city: string;
  phoneNumber: string;
}

function UserProfilePage(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  // TODO: Location code is copy-paste from AdminOldItemView, could this be unified?
  const [locations, setLocations] = useState<Location[]>([]);
  // Allow location selector to be controlled
  const [currentLocation, setCurrentLocation] = useState<string>('');
  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [values, setValues] = useState<UserProfileState>({
    email: '',
    firstName: '',
    lastName: '',
    defaultLocationId: null,
    newPassword: '',
    confirmPassword: '',
    showPassword: false,
    address: '',
    isReadOnly: true,
    postalcode: '',
    city: '',
    phoneNumber: '',
  });
  const [initialValues, setInitialValues] = useState<UserProfileState>(JSON.parse(JSON.stringify(values)));

  useEffect(() => {
    if (user.id) {
      setValues({
        ...values,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        defaultLocationId: user.defaultLocationId ?? null,
        address: user.address,
        postalcode: user.postalcode,
        city: user.city,
        phoneNumber: user.phoneNumber,
      });

      setInitialValues({
        ...values,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        address: user.address,
        postalcode: user.postalcode,
        city: user.city,
        phoneNumber: user.phoneNumber,
      });
    }
    // This rule forces values to be a dependency and it causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  useEffect(() => {
    locationService.fetchAll().then((locationsRes) => {
      if (locationsRes) {
        setLocations(locationsRes);
      }
    });
  }, []);

  useEffect(() => {
    const mapLocationName = (locationId: number | null): string => {
      const location: Location | undefined = locations.find((location) => location.locationId === locationId);
      return location?.name ?? '';
    };
    if (locations.length !== 0) {
      setCurrentLocation(mapLocationName(values.defaultLocationId));
    }
  }, [locations, values.defaultLocationId]);

  //TODO: Implement password change, currently doesn't change password
  const checkPasswordChange = (): void => {
    if (values.newPassword.length < 8) {
      setPasswordErrorText(t('validation.mustHaveLength', { length: 8 }));
    } else if (values.newPassword !== values.confirmPassword) {
      setPasswordErrorText(t('validation.passwordMustMatch'));
    } else {
      setPasswordErrorText('');
      createSuccessNotification(t('notification.success.passwordChange'));
    }
  };

  const handleChange =
    (prop: keyof UserProfileState) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = (): void => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChangeIsReadOnly = (): void => {
    setValues({ ...values, isReadOnly: !values.isReadOnly });
  };

  const handleResetState = (): void => {
    setValues({ ...initialValues, isReadOnly: true });
  };

  const handleSave = async (): Promise<void> => {
    const updatedUser: UserDTO = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      defaultLocationId: values.defaultLocationId ?? null,
      isAdmin: false,
      address: values.address,
      postalcode: values.postalcode,
      city: values.city,
      phoneNumber: values.phoneNumber,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const updated = await userService.updateOne(user.id!, updatedUser, user.id!);

      if (updated) {
        const updatedValues = {
          ...values,
          email: updated.email,
          //userName: updated.userName,
          firstName: updated.firstName,
          lastName: updated.lastName,
        };
        dispatch(addUser(updated));
        setValues({ ...updatedValues, isReadOnly: !values.isReadOnly });
        setInitialValues({ ...updatedValues, isReadOnly: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  return (
    <div>
      <Paper className={classes.profileCard} elevation={1} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('profile.basicInfo')}</Typography>
            <hr className={classes.profileDivider}></hr>
          </Grid>
          <Grid item md={12} lg={6} className={classes.vFlex}>
            <TextField
              id="userFirstName"
              label={t('data.fields.firstName')}
              value={values.firstName ?? ''}
              onChange={handleChange('firstName')}
              InputProps={{
                readOnly: values.isReadOnly,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} lg={6} className={classes.vFlex}>
            <TextField
              id="userLastName"
              label={t('data.fields.lastName')}
              type="text"
              value={values.lastName ?? ''}
              onChange={handleChange('lastName')}
              variant="outlined"
              InputProps={{
                readOnly: values.isReadOnly,
              }}
            />
          </Grid>
          <Grid item md={12} lg={12} className={classes.vFlex}>
            <TextField
              id="userAddress"
              label={t('data.fields.address')}
              type="address"
              value={values.address}
              onChange={handleChange('address')}
              InputProps={{
                readOnly: values.isReadOnly,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} lg={6} className={classes.vFlex}>
            <TextField
              id="userPostalcode"
              label={t('data.fields.postalcode')}
              type="postalcode"
              value={values.postalcode}
              onChange={handleChange('postalcode')}
              InputProps={{
                readOnly: values.isReadOnly,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} lg={6} className={classes.vFlex}>
            <TextField
              id="userCity"
              label={t('data.fields.city')}
              type="city"
              value={values.city}
              onChange={handleChange('city')}
              InputProps={{
                readOnly: values.isReadOnly,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} lg={6} className={classes.vFlex}>
            <TextField
              id="userPhoneNumber"
              label={t('data.fields.phoneNumber')}
              type="phoneNumber"
              value={values.phoneNumber}
              onChange={handleChange('phoneNumber')}
              InputProps={{
                readOnly: values.isReadOnly,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} lg={6} className={classes.vFlex}>
            <TextField
              id="userEmail"
              label={t('data.fields.email')}
              type="email"
              value={values.email}
              onChange={handleChange('email')}
              InputProps={{
                readOnly: values.isReadOnly,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} lg={6} className={classes.vFlex}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{t('data.fields.location')}</InputLabel>
              <Select
                disabled={values.isReadOnly}
                label={currentLocation}
                value={currentLocation}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const name: string = event.target.value as string;
                  const location: Location | undefined = locations.find((location) => location.name === name);
                  const locationId = location?.locationId ?? null;
                  setCurrentLocation(name);
                  setValues({ ...values, defaultLocationId: locationId });
                }}
              >
                <MenuItem value="">
                  <em>{t('data.none')}</em>
                </MenuItem>
                {locations.map((location, i) => (
                  <MenuItem key={i} value={location.name}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.rMargin}
              color="primary"
              variant="outlined"
              disabled={values.isReadOnly}
              onClick={() => {
                handleSave();
              }}
            >
              {t('actions.save')}
            </Button>
            {values.isReadOnly ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  handleChangeIsReadOnly();
                }}
              >
                {t('actions.edit')}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  handleResetState();
                }}
              >
                {t('actions.cancel')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.profileCard} elevation={1} variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('profile.changePassword')}</Typography>
            <hr className={classes.profileDivider}></hr>
          </Grid>
          <Grid item md={12} lg={6} className={classes.vFlex}>
            <FormControl error={passwordErrorText !== ''}>
              <InputLabel htmlFor="userNewPassword">New password</InputLabel>
              <Input
                id="userNewPassword"
                type={values.showPassword ? 'text' : 'password'}
                value={values.newPassword}
                onChange={handleChange('newPassword')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="password-error-text" hidden={passwordErrorText === ''}>
                {passwordErrorText}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={12} lg={6} className={classes.vFlex}>
            <FormControl error={passwordErrorText !== ''}>
              <InputLabel htmlFor="userConfirmPassword">Confirm password</InputLabel>
              <Input
                id="userConfirmPassword"
                type={values.showPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" variant="outlined" onClick={checkPasswordChange}>
              {t('actions.savePassword')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default UserProfilePage;
