import { makeStyles, Tooltip, withStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  infoBox: {
    marginTop: -20,
    marginLeft: -10,
  },
}));

const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: 10,
  },
}))(Tooltip);

function InfoTextModifyHelpLegal(): JSX.Element {
  const classes = useStyles();

  return (
    <InfoTooltip
      className={classes.infoBox}
      title={
        <React.Fragment>
          <h1># Heading 1</h1>
          <h2>## Heading 2</h2>
          <h3>### Heading 3</h3>
          <em>{'* Italic *'}</em>
          <br />
          <b>{'** Bold **'}</b>
          <br />
          <em>
            <b>{'*** Italic and bold ***'}</b>
          </em>
          <br />
          <br />
          {'- List item'}
          <br />
          <br />
          ![Test Image](https://i.imgur.com/eUZi2wi.png)
          <br />
          [Duck Duck Go](https://duckduckgo.com)
        </React.Fragment>
      }
      placement="top-start"
    >
      <InfoOutlinedIcon />
    </InfoTooltip>
  );
}

export default InfoTextModifyHelpLegal;
