import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { Euro, LocationOn } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from '../../models';
import useStyles from '../../styles';

interface LocationComponentProps {
  location: Location;
  redirectUrl: string;
}

function LocationComponent(props: LocationComponentProps): JSX.Element {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardActionArea
        onClick={() => {
          history.push(`${props.redirectUrl}/${props.location.locationId}`);
        }}
      >
        <CardContent>
          <div className={classes.flexRow}>
            <div className={classes.hFlex}>
              <LocationOn fontSize="large" className={classes.rMargin} />
              <h3 className={classes.rMargin}>{props.location.name}</h3>
            </div>
            {props.location.usePayments && <Euro fontSize={'large'} />}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default LocationComponent;
