import {
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Autorenew,
  //BorderColor,
  ChatBubble,
  Dashboard,
  Dvr,
  Favorite,
  Help,
  Home,
  HourglassFull,
  MeetingRoom,
  Menu,
  Message,
  NoMeetingRoom,
  Payment,
  Person,
  Policy,
  ReportProblem,
  Room,
  Search,
  SportsSoccer,
} from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { LanguageSelector } from '..';
import { URLS } from '../../constants';
import { isAuthenticated, signinRedirect, signoutRedirect } from '../../services/authenticationService';
import shareAndSaveLogo from '../../share_and_save.svg';
import {
  CurrentView,
  selectCurrentView,
  selectMobileDrawerOpen,
  setCurrentView,
  toggleMobileDrawerOpen,
} from '../../store/settingsSlice';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

interface DrawerButtonData {
  name: string;
  icon: JSX.Element;
  // Saved to store, this is used to highlight the current view
  viewId?: CurrentView;
  // This will act as a progress indicator (literally telling which features
  // are unavailable but planned)
  disabled?: boolean;

  hidden?: boolean;

  callback?: () => void | Promise<void>;
}

type DrawerButtonList = (DrawerButtonData | undefined)[];

export function isAdminMode(currentUrl: string): boolean {
  return currentUrl.substr(0, 7) === '/admin/';
}

function FrameworkAppBar(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const { t } = useTranslation();
  return (
    <AppBar position="fixed" className={classes.frameworkAppBar}>
      <Toolbar>
        <IconButton
          className={classes.frameworkMenuButton}
          onClick={(): void => {
            dispatch(toggleMobileDrawerOpen());
          }}
        >
          <Menu />
        </IconButton>
        <img src={shareAndSaveLogo} alt="Share & Save" className={classes.logo} />
        {isAdminMode(currentLocation.pathname) && (
          <Typography variant="h6" noWrap>
            {`Admin`}
          </Typography>
        )}
        <span className={classes.spacer} />
        {!isAuthenticated() && (
          <IconButton onClick={signinRedirect} className={[classes.white].join(' ')}>
            <Person />
          </IconButton>
        )}
        <LanguageSelector />
      </Toolbar>
    </AppBar>
  );
}

function FrameworkDrawer(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentLocation = useLocation();
  const history = useHistory();
  const mobileDrawerOpen = useSelector(selectMobileDrawerOpen);
  const user = useSelector(selectUser);
  const currentView = useSelector(selectCurrentView) ?? CurrentView.T_HOME;
  const dispatch = useDispatch();

  const toggleMobileDrawer = (): void => {
    dispatch(toggleMobileDrawerOpen());
  };

  const moveTo = (url: string): void => {
    if (mobileDrawerOpen) {
      toggleMobileDrawer();
    }
    history.push(url);
  };

  const setViewAndCallback = (drawerButton: DrawerButtonData): void => {
    dispatch(setCurrentView(drawerButton.viewId));
    if (drawerButton.callback) drawerButton.callback();
  };

  const roleSwitcher = (): DrawerButtonData =>
    isAdminMode(currentLocation.pathname)
      ? {
          name: t('views.toTenant'),
          icon: <Autorenew />,
          viewId: CurrentView.T_HOME,
          callback: () => {
            moveTo(URLS.ROOT);
          },
        }
      : {
          name: t('views.toAdmin'),
          icon: <Autorenew />,
          viewId: CurrentView.A_DASHBOARD,
          callback: () => {
            moveTo(URLS.ADMIN.DASHBOARD);
          },
        };

  const universalDrawerData: DrawerButtonList = [
    { name: 'break', icon: <div /> },
    user.isAdmin ? roleSwitcher() : undefined,
    isAuthenticated()
      ? {
          name: t('credentials.logout'),
          icon: <NoMeetingRoom />,
          callback: signoutRedirect,
        }
      : {
          name: t('credentials.login'),
          icon: <MeetingRoom />,
          callback: signinRedirect,
        },
  ];

  const isUserAuthenticated = isAuthenticated();
  const adminDrawerData: DrawerButtonList = [
    {
      name: t('views.dashboard'),
      icon: <Dashboard />,
      viewId: CurrentView.A_DASHBOARD,
      callback: () => {
        moveTo(URLS.ADMIN.DASHBOARD);
      },
    },
    {
      name: t('views.items'),
      icon: <SportsSoccer />,
      viewId: CurrentView.A_ITEMS,
      callback: () => {
        moveTo(URLS.ADMIN.ITEMS);
      },
    },
    {
      name: t('views.borrowings'),
      icon: <HourglassFull />,
      viewId: CurrentView.A_BORROWINGS,
      callback: () => {
        moveTo(URLS.ADMIN.BORROWINGS);
      },
    },
    {
      name: t('views.orders'),
      icon: <Payment />,
      viewId: CurrentView.A_ORDERS,
      callback: () => {
        moveTo(URLS.ADMIN.ORDERS);
      },
    },
    {
      name: t('views.problems'),
      icon: <ReportProblem />,
      viewId: CurrentView.A_PROBLEMS,
      callback: () => {
        moveTo(URLS.ADMIN.PROBLEMS);
      },
    },
    {
      name: t('views.locations'),
      icon: <Room />,
      viewId: CurrentView.A_LOCATIONS,
      callback: () => {
        moveTo(URLS.ADMIN.LOCATIONS);
      },
    },
    {
      name: t('views.users'),
      icon: <Person />,
      viewId: CurrentView.A_USERS,
      callback: () => {
        moveTo(URLS.ADMIN.USERS);
      },
    },
    {
      name: t('views.news'),
      icon: <Message />,
      viewId: CurrentView.A_NEWS,
      callback: () => {
        moveTo(URLS.ADMIN.NEWS);
      },
    },
    {
      name: t('views.logs'),
      icon: <Dvr />,
      viewId: CurrentView.A_LOGS,
      callback: () => {
        moveTo(URLS.ADMIN.LOGS);
      },
    },
    {
      name: t('views.feedback'),
      icon: <ChatBubble />,
      viewId: CurrentView.A_FEEDBACK,
      callback: () => {
        moveTo(URLS.ADMIN.FEEDBACK);
      },
    },
    {
      name: t('views.help'),
      icon: <Help />,
      viewId: CurrentView.A_HELP,
      callback: () => {
        moveTo(URLS.ADMIN.HELP);
      },
    },
    {
      name: t('views.modify'),
      icon: <CreateIcon />,
      viewId: CurrentView.A_MODIFY,
      callback: () => {
        moveTo(URLS.ADMIN.MODIFY);
      },
    },
    ...universalDrawerData,
  ];

  const tenantDrawerData: DrawerButtonList = [
    {
      name: t('views.home'),
      icon: <Home />,
      viewId: CurrentView.T_HOME,
      callback: () => {
        moveTo(URLS.ROOT);
      },
    },
    {
      name: t('views.search'),
      icon: <Search />,
      viewId: CurrentView.T_SEARCH,
      callback: () => {
        moveTo(URLS.EU.SEARCH);
      },
    },
    /*{
      name: t('views.lend'),
      icon: <CropFree />,
      viewId: CurrentView.T_SCAN,
      hidden: !isUserAuthenticated,
      callback: () => {
        moveTo(URLS.EU.LEND_SELECT);
      },
    },*/
    /*
    {
      name: t('lend.lendWithCode'),
      icon: <BorderColor />,
      viewId: CurrentView.T_LEND,
      callback: () => {
        moveTo(URLS.EU.LEND_ITEM);
      },
    },
     */
    {
      name: t('views.likes'),
      icon: <Favorite />,
      viewId: CurrentView.T_LIKES,
      hidden: !isUserAuthenticated,
      callback: () => {
        moveTo(URLS.EU.LIKES);
      },
    },
    {
      name: t('views.borrowings'),
      icon: <HourglassFull />,
      viewId: CurrentView.T_BORROWINGS,
      hidden: !isUserAuthenticated,
      callback: () => {
        moveTo(URLS.EU.BORROWS);
      },
    },
    {
      name: t('views.news'),
      icon: <Message />,
      viewId: CurrentView.T_NEWS,
      callback: () => {
        moveTo(URLS.EU.NEWS);
      },
    },
    {
      name: t('views.profile'),
      icon: <Person />,
      viewId: CurrentView.T_PROFILE,
      hidden: !isUserAuthenticated,
      callback: () => {
        moveTo(URLS.EU.PROFILE);
      },
    },
    {
      name: t('views.help'),
      icon: <Help />,
      viewId: CurrentView.T_HELP,
      callback: () => {
        moveTo(URLS.EU.HELP);
      },
    },
    {
      name: t('views.legal'),
      icon: <Policy />,
      viewId: CurrentView.T_LEGAL,
      callback: () => {
        moveTo(URLS.EU.LEGAL);
      },
    },
    ...universalDrawerData,
  ];

  const currentDrawerData = isAdminMode(currentLocation.pathname) ? adminDrawerData : tenantDrawerData;
  const currentDrawerComponents: JSX.Element = (
    <div className={classes.vFlex}>
      <Toolbar>
        <i className={classes.white}>{user.email}</i>
      </Toolbar>
      <List className={classes.vFlex}>
        {currentDrawerData.map((data, i) => {
          // TODO: This is a bit hackish way to hide role switcher for non-admins
          if (data === undefined) {
            return <div key={i} />;
          }
          if (data && data.hidden) return <div key={i} />;
          const isSelected = currentView !== undefined && currentView === data.viewId;
          // TODO: Another hack, this time to to push components down
          return data.name === 'break' ? (
            <span key={i} className={classes.spacer} />
          ) : (
            <div id={`${data.name}-listitem`} key={i}>
              <ListItem
                button
                onClick={() => setViewAndCallback(data)}
                disabled={data.disabled}
                className={isSelected ? classes.drawerListItemSelected : classes.drawerListItem}
                selected={isSelected}
              >
                <ListItemIcon className={isSelected ? classes.primary : classes.white}>{data.icon}</ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItem>
            </div>
          );
        })}
      </List>
    </div>
  );

  return (
    <nav className={classes.frameworkDrawer}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileDrawerOpen}
          onClose={toggleMobileDrawer}
          classes={{ paper: classes.frameworkDrawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {currentDrawerComponents}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer variant="permanent" open classes={{ paper: classes.frameworkDrawerPaper }}>
          {currentDrawerComponents}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export { FrameworkAppBar, FrameworkDrawer };
