import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, CreateButton, NewsForm } from '../../components';
import { errorInNewsArticle } from '../../components/admin/validation';
import { URLS } from '../../constants';
import { NewsArticleDTO } from '../../models';
import { newsService } from '../../services';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';

function AdminNewNewsArticleView(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [newsArticleDto, setNewsArticleDto]: [NewsArticleDTO, any] = useState({ author: '', heading: '', body: '' });
  const userId: string = useSelector(selectUser).id ?? '';

  const createNewsArticle: () => void = () => {
    newsService.createOne(userId, newsArticleDto).then(() => {
      history.push(URLS.ADMIN.NEWS);
    });
  };

  const checkError: () => boolean = () => {
    return errorInNewsArticle(newsArticleDto);
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.createNew')}</h1>
      <div className={classes.flexRow}>
        <div className={classes.rMargin}>
          <CreateButton create={createNewsArticle} checkError={checkError} />
        </div>
        <BackButton />
      </div>
    </div>
  );

  return (
    <div>
      {header}
      <NewsForm dto={newsArticleDto} setDto={setNewsArticleDto} initializeWithDto={false} />
    </div>
  );
}

export default AdminNewNewsArticleView;
