import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BackButton, ItemForm, RemoveButton, SaveButton } from '../../components';
import { errorInImage, errorInItem } from '../../components/admin/validation';
import { URLS } from '../../constants';
import i18n from '../../i18n/i18n';
import { Borrowing, ImageData, ItemInstance, ItemInstanceDTO, ItemModel, ItemModelDTO, Location } from '../../models';
import { imageService, itemService, locationService } from '../../services';
import { createErrorNotification } from '../../services/helpers';
import { addSkipForImage } from '../../store/settingsSlice';
import { selectUser } from '../../store/userSlice';
import useStyles from '../../styles';
import { createImageData } from '../../util';

function AdminOldItemView(props: any): JSX.Element {
  const itemModelId: number = props.match.params.itemId;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [itemModelDto, setItemModelDto] = useState<ItemModelDTO>({
    name: '',
    description: '',
    maxBorrowingTime: parseInt(''),
  });
  const [itemInstanceDto, setItemInstanceDto] = useState<ItemInstanceDTO>({
    itemModelId: itemModelId,
    size: '',
    locationId: 0,
    pretaxPrice: 0,
    price: 0,
    tax: 0,
  });

  const [locations, setLocations] = useState<Location[]>([]);
  // Allow location selector to be controlled
  const [currentLocation, setCurrentLocation]: [string, any] = useState('');
  const [imageData, _setImageData] = useState<ImageData | undefined>(undefined);
  const [imageData2, _setImageData2] = useState<ImageData | undefined>(undefined);
  const [imageData3, _setImageData3] = useState<ImageData | undefined>(undefined);
  const userId: string = useSelector(selectUser).id ?? '';
  const skipReload = false; //useSelector((state) => selectSkipImageReload(state as RootState, itemModelId));
  const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false);
  const [instances, setInstances] = useState<ItemInstance[]>([]);
  const [borrowings, setBorrowings] = useState<Borrowing[]>([]);

  useEffect(() => {
    locationService.fetchAll().then((locations) => {
      if (locations) {
        setLocations(locations);
      }
    });
  }, []);

  useEffect(() => {
    // Fetch item only when locations are loaded. This way the name of the location can be displayed.
    if (locations.length !== 0 && !initialLoadDone) {
      const mapLocationName = (locationId: number): string => {
        const location: Location | undefined = locations.find((location) => location.locationId === locationId);
        return location?.name ?? '';
      };

      itemService.fetchOne(itemModelId).then((itemModel: ItemModel | void) => {
        if (itemModel) {
          setItemModelDto(itemModel as ItemModelDTO);
          setCurrentLocation(mapLocationName(itemInstanceDto.locationId));
          imageService.fetchOne(itemModelId, true, skipReload).then((imageBlob: Blob | void) => {
            createImageData(_setImageData, imageBlob);
            if (!skipReload) dispatch(addSkipForImage(itemModelId));
          });
          imageService.fetchOneWithImageNumber(itemModelId, 2, true, skipReload).then((imageBlob: Blob | void) => {
            createImageData(_setImageData2, imageBlob);
            if (!skipReload) dispatch(addSkipForImage(`${itemModelId}_2`));
          });
          imageService.fetchOneWithImageNumber(itemModelId, 3, true, skipReload).then((imageBlob: Blob | void) => {
            createImageData(_setImageData3, imageBlob);
            if (!skipReload) dispatch(addSkipForImage(`${itemModelId}_3`));
          });
        }
      });

      itemService.fetchInstancesForOneModel(itemModelId).then((instances: ItemInstance[] | void) => {
        if (instances) {
          instances.sort(sortInstancesById);
          setInstances(instances);
        }
      });

      setInitialLoadDone(true);
    }
  }, [dispatch, initialLoadDone, itemModelId, locations, skipReload]);

  const saveItem: () => void = async () => {
    const itemModel: ItemModel | void = await itemService.updateOne(userId, itemModelDto, itemModelId);
    let imageCreated;
    if (itemModel && imageData) {
      imageCreated = await imageService.createOne(itemModelId, imageData.blob);
    }
    if (itemModel && imageData2) {
      imageCreated = await imageService.createOneWithImageNumber(itemModelId, 2, imageData2.blob);
    }
    if (itemModel && imageData3) {
      imageCreated = await imageService.createOneWithImageNumber(itemModelId, 3, imageData3.blob);
    }
    if (imageCreated) {
      history.push(URLS.ADMIN.ITEMS);
    }
  };

  const checkError: () => boolean = () => {
    return errorInItem(itemModelDto) || errorInImage(imageData?.blob);
  };

  const removeItem: () => void = () => {
    if (instances.length > 0) {
      createErrorNotification(i18n.t('notifications.error.cannotDelete_ItemInstancesMustBeRemoved'));
      return;
    } else {
      itemService.deleteOne(userId, itemModelId).then(() => {
        history.push(URLS.ADMIN.ITEMS);
      });
    }
  };

  const sortInstancesById = (i1: ItemInstance, i2: ItemInstance): number =>
    i1.itemInstanceId > i2.itemInstanceId ? 1 : -1;

  const sortInstancesByLocation = (i1: ItemInstance, i2: ItemInstance): number =>
    i1.locationId > i2.locationId ? 1 : -1;

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('admin.modify')}</h1>
      <div className={`${classes.flexRow} ${classes.flexReverseRow}`}>
        <SaveButton save={saveItem} checkError={checkError} />

        <div className={classes.rMargin}>
          <RemoveButton remove={removeItem} />
        </div>
        <div className={classes.rMargin}>
          <BackButton />
        </div>
      </div>
    </div>
  );

  const setImageData = (data?: ImageData) => {
    if (data === undefined) {
      imageService.deleteOne(itemModelId);
    }
    _setImageData(data);
  };
  const setImageData2 = (data?: ImageData) => {
    if (data === undefined) {
      imageService.deleteOneWithImageNumber(itemModelId, 2);
    }
    _setImageData2(data);
  };
  const setImageData3 = (data?: ImageData) => {
    if (data === undefined) {
      imageService.deleteOneWithImageNumber(itemModelId, 3);
    }
    _setImageData3(data);
  };

  return (
    <div>
      {header}
      <ItemForm
        dto={itemModelDto}
        setDto={setItemModelDto}
        setImage={setImageData}
        setImage2={setImageData2}
        setImage3={setImageData3}
        imageData={imageData}
        imageData2={imageData2}
        imageData3={imageData3}
        itemModelId={itemModelId}
        initializeWithDto={true}
        locations={locations}
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        instanceDto={itemInstanceDto}
        setInstanceDto={setItemInstanceDto}
        instances={instances}
      />
    </div>
  );
}

export default AdminOldItemView;
