import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Language, selectLanguage, setLanguage } from '../../store/settingsSlice';
import useStyles from '../../styles';

function LanguageSelector(): JSX.Element {
  const currentLanguage = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const classes = useStyles();

  const changeLanguage = (): void => {
    dispatch(setLanguage(currentLanguage === Language.FI ? Language.EN : Language.FI));
    i18n.changeLanguage(currentLanguage === Language.FI ? Language.EN : Language.FI);
  };

  return (
    <Button
      color="secondary"
      variant="contained"
      size={'small'}
      onClick={changeLanguage}
      style={{ minWidth: '32px' }}
      className={[classes.white, classes.uppercase].join(' ')}
    >
      {currentLanguage}
    </Button>
  );
}

export default LanguageSelector;
