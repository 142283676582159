import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmationDialogProps {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
  title: string;
  children: any;
  useOkButton?: boolean;
  useCancelButton?: boolean;

  okButtonText?: string;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { onClose, onOk, open, title, children, ...other } = props;
  if (other.useOkButton === undefined) other.useOkButton = true;
  if (other.useCancelButton === undefined) other.useCancelButton = true;
  const radioGroupRef = useRef<HTMLElement>(null);
  const { t } = useTranslation();
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onOk();
  };

  return (
    <Dialog
      style={{ width: '90%' }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {other.useCancelButton && (
          <Button autoFocus onClick={handleCancel}>
            {t('confirmationDialog.cancel')}
          </Button>
        )}
        {other.useOkButton && (
          <Button onClick={handleOk}>{other.okButtonText ? other.okButtonText : t('confirmationDialog.ok')}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
