import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Create, CropFree, FiberManualRecord, FiberManualRecordOutlined, InsertPhotoTwoTone } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TIME, URLS, VALIDATION } from '../../constants';
import { ImageData, ItemInstance, ItemInstanceDTO, ItemModel, ItemModelDTO, Location } from '../../models';
import { rewriteSkipForImage, selectAllImageSkips } from '../../store/settingsSlice';
import useStyles from '../../styles';
import InstanceComponent from '../item/Instance';
import { QrCodeDialog } from '../item/QrCodeDialog';
import { CreateButton } from './ActionButtons';
import { errorInNumber, errorInText } from './validation';
interface ItemFormProps {
  dto: ItemModelDTO;
  setDto: any;
  setImage: any;
  setImage2?: any;
  setImage3?: any;
  imageData: ImageData | undefined;
  imageData2?: ImageData | undefined;
  imageData3?: ImageData | undefined;
  itemModelId?: number;
  initializeWithDto: boolean;
  locations: Location[];
  currentLocation: string;
  setCurrentLocation: any;
  instanceDto: ItemInstanceDTO;
  setInstanceDto: any;
  instances: ItemInstance[];
}

interface ImageFormProps {
  setImage: any;
  imageData: ImageData | undefined;
  itemModelId?: number;
}
interface ImageCarouselProps {
  setImage: any;
  setImage2?: any;
  setImage3?: any;
  imageData: ImageData | undefined;
  imageData2?: ImageData | undefined;
  imageData3?: ImageData | undefined;

  itemId?: number;
}
// Not for validation, only for displaying errondous values
interface ItemValidationErrors {
  name: boolean;
  description: boolean;
  location: boolean;
  image: boolean;
  maxBorrowingTime: boolean;
}

const initialValidationErrors: ItemValidationErrors = {
  name: false,
  description: false,
  location: false,
  image: false,
  maxBorrowingTime: false,
};

function ImageCarousel(props: ImageCarouselProps): JSX.Element {
  const classes = useStyles();
  const [imageIndex, setImageIndex] = useState(1);
  return (
    <div>
      {imageIndex === 1 && <ImageForm setImage={props.setImage} imageData={props.imageData} />}
      {imageIndex === 2 && <ImageForm setImage={props.setImage2} imageData={props.imageData2} />}
      {imageIndex === 3 && <ImageForm setImage={props.setImage3} imageData={props.imageData3} />}
      <div className={`${classes.vMargin} ${classes.hFlex} ${classes.justifyCenter}`}>
        <IconButton onClick={() => setImageIndex(1)}>
          {imageIndex === 1 ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
        </IconButton>
        {props.itemId && (
          <IconButton onClick={() => setImageIndex(2)}>
            {imageIndex === 2 ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
          </IconButton>
        )}
        {props.itemId && (
          <IconButton onClick={() => setImageIndex(3)}>
            {imageIndex === 3 ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
          </IconButton>
        )}
      </div>
    </div>
  );
}

function ImageForm(props: ImageFormProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const allSkips: number[] = useSelector(selectAllImageSkips);
  const dispatch = useDispatch();
  const handleUpload = (event: any): void => {
    event.preventDefault();
    const blob: Blob = event.target.files[0];
    const image: ImageData = {
      blob,
      url: URL.createObjectURL(blob),
    };
    props.setImage(image);
    if (props.itemModelId) {
      const newList: number[] = [];
      allSkips.forEach((skip: number) => {
        if (skip.toString() !== props.itemModelId?.toString()) newList.push(skip);
      });
      dispatch(rewriteSkipForImage(newList));
    }
  };
  return (
    <div>
      {props.imageData?.url && (
        <div className={`${classes.vMargin} ${classes.imageCard}`}>
          <img src={props.imageData.url} className={`${classes.fGrow} ${classes.imageFull}`} alt="item"></img>
        </div>
      )}
      {!props.imageData?.url && (
        <div className={`${classes.vMargin} ${classes.imageCard}`}>
          <div
            className={`${classes.fGrow} ${classes.imageFull} ${classes.hFlex} ${classes.justifyCenter} ${classes.alignCenter}`}
            style={{ backgroundColor: '#eaeaea' }}
          >
            <InsertPhotoTwoTone fontSize={'large'} />
          </div>
        </div>
      )}
      <div className={`${classes.vMargin} ${classes.hFlex} ${classes.justifyCenter}`}>
        <input
          accept="image/*"
          className={classes.imageUpload}
          id="image-upload"
          type="file"
          onChange={(event: React.ChangeEvent) => handleUpload(event.nativeEvent)}
        />
        <Button
          variant="contained"
          className={[classes.rMargin, classes.redChip].join(' ')}
          onClick={() => {
            props.setImage(undefined);
          }}
        >
          {t('actions.clear')}
        </Button>
        <label htmlFor="image-upload" className={classes.rMargin}>
          <Button variant="contained" color="primary" component="span">
            {props.imageData === undefined ? t('actions.upload') : t('actions.update')}
          </Button>
        </label>
      </div>
    </div>
  );
}
function ItemForm(props: ItemFormProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors]: [ItemValidationErrors, any] = useState(initialValidationErrors);
  const allSkips: number[] = useSelector(selectAllImageSkips);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [instanceId, setInstanceId] = useState(0);

  const showQrCode = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const borrowStatus = (available: boolean): JSX.Element =>
    available ? (
      <div className={classes.green}>{t('item.status.available')}</div>
    ) : (
      <div className={classes.red}>{t('item.status.unavailable')}</div>
    );

  return (
    <div>
      <ImageCarousel
        setImage={props.setImage}
        imageData={props.imageData}
        setImage2={props.setImage2}
        imageData2={props.imageData2}
        setImage3={props.setImage3}
        imageData3={props.imageData3}
        itemId={props.itemModelId}
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.name')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.name : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, name: event.target.value });
        }}
        error={errors.name}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.ITEM.NAME })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, name: errorInText(event.target.value, VALIDATION.ITEM.NAME) })
        }
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.maxBorrowingTime')}
        fullWidth
        // Handle parsing manually, this input causes bugging with outlined variant
        // type="number"
        variant="outlined"
        value={
          props.initializeWithDto
            ? isNaN(props.dto.maxBorrowingTime)
              ? ''
              : props.dto.maxBorrowingTime / TIME.DAY
            : undefined
        }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({
            ...props.dto,
            maxBorrowingTime: parseInt(event.target.value) * TIME.DAY,
          });
        }}
        error={errors.maxBorrowingTime}
        helperText={t('validation.mustBeNatNumber')}
        onBlur={(event: any): void =>
          setErrors({ ...errors, maxBorrowingTime: event.target.value === '' || isNaN(event.target.value) })
        }
      />

      <TextField
        className={classes.vMargin}
        multiline
        minRows={6}
        label={t('data.fields.description')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.description : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, description: event.target.value });
        }}
        error={errors.description}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.ITEM.DESCRIPTION })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, description: errorInText(event.target.value, VALIDATION.ITEM.DESCRIPTION) })
        }
      />
      {props.initializeWithDto && (
        <div className={classes.vMargin}>
          <CreateButton
            checkError={() => false}
            create={() => {
              history.push(`${URLS.ADMIN.INSTANCE_NOQUERY}/${props.itemModelId}`);
            }}
          ></CreateButton>
        </div>
      )}
      {props.initializeWithDto &&
        props.instances.map((instance: ItemInstance, i: number) => (
          <div key={`${i}_spacer`}>
            <div className={classes.vMargin}></div>
            <InstanceComponent
              item={props.dto as ItemModel}
              instance={instance}
              key={i}
              editMode={
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="default"
                    component="span"
                    className={classes.rMargin}
                    onClick={() => {
                      history.push(`${URLS.ADMIN.INSTANCE_NOQUERY}/${instance.itemInstanceId}/${props.itemModelId}`);
                    }}
                  >
                    <Create /> {t('instance.modify')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={() => {
                      setInstanceId(instance.itemInstanceId);
                      showQrCode();
                    }}
                  >
                    <CropFree /> {t('actions.showQrCode')}
                  </Button>
                </Grid>
              }
            />
          </div>
        ))}
      {!props.initializeWithDto ? (
        <>
          <TextField
            key={1}
            className={classes.vMargin}
            label={t('data.fields.size')}
            fullWidth
            variant="outlined"
            value={props.initializeWithDto ? props.instanceDto.size : undefined}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setInstanceDto({ ...props.instanceDto, size: event.target.value });
            }}
          />
          <FormControl className={classes.vMargin} variant="outlined" fullWidth error={errors.location} key={2}>
            <InputLabel>{t('data.fields.location')}</InputLabel>
            <Select
              label={t('data.fields.location')}
              value={
                props.initializeWithDto
                  ? props.locations.find((location) => location.locationId === props.instanceDto.locationId)
                  : undefined
              }
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const name: string = event.target.value as string;
                const location: Location | undefined = props.locations.find((location) => location.name === name);
                const locationId = location?.locationId ?? 0;
                props.setCurrentLocation(name);
                props.setInstanceDto({ ...props.instanceDto, locationId: locationId });
                setErrors({ ...errors, location: errorInNumber(locationId) });
              }}
            >
              <MenuItem value="">
                <em>{t('data.none')}</em>
              </MenuItem>
              {props.locations.map((location, i) => (
                <MenuItem key={i} value={location.name}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t('validation.mustHaveValue')}</FormHelperText>
          </FormControl>
        </>
      ) : undefined}
      <QrCodeDialog isOpen={open} closeDialog={handleClose} itemInstanceId={instanceId}></QrCodeDialog>
    </div>
  );
}

export default ItemForm;
