import { VALIDATION } from '../../constants';
import { ItemInstanceDTO, ItemModelDTO, LocationDTO, NewsArticleDTO, UserDTO } from '../../models';

function errorInText(text: string, length: number): boolean {
  return text?.length < length;
}

function errorInNumber(number: number): boolean {
  return number <= 0;
}
function errorInPrice(number: string): boolean {
  const regex = /^\d+(?:\.?\d{0,2}?)$/;
  /*console.log(
    parseInt(number) < 0,
    String(number)
      .split('')
      .filter((n) => n === '.').length > 1,
    !regex.test(String(number)),
    isNaN(+number)
  );*/
  return (
    parseInt(number) < 0 ||
    String(number)
      .split('')
      .filter((n) => n === '.').length > 1 || // Has more than one dots
    !regex.test(String(number)) ||
    isNaN(+number)
  );
}

function errorInItem(itemModelDto: ItemModelDTO): boolean {
  const errors: boolean[] = [
    errorInText(itemModelDto.name, VALIDATION.ITEM.NAME),
    errorInText(itemModelDto.description, VALIDATION.ITEM.DESCRIPTION),
    errorInNumber(itemModelDto.maxBorrowingTime),
  ];

  return errors.includes(true);
}

function errorInInstance(itemInstanceDto: ItemInstanceDTO): boolean {
  const errors: boolean[] = [
    errorInText(itemInstanceDto.size, VALIDATION.INSTANCE.SIZE),
    errorInNumber(itemInstanceDto.locationId),
  ];

  return errors.includes(true);
}

function errorInLocation(locationDto: LocationDTO): boolean {
  const errors: boolean[] = [
    errorInText(locationDto.name, VALIDATION.LOCATION.NAME),
    errorInText(locationDto.address, VALIDATION.LOCATION.ADDRESS),
  ];

  return errors.includes(true);
}

function errorInNewsArticle(newsArticleDto: NewsArticleDTO): boolean {
  const errors: boolean[] = [
    errorInText(newsArticleDto.author, VALIDATION.NEWS.AUTHOR),
    errorInText(newsArticleDto.heading, VALIDATION.NEWS.HEADING),
    errorInText(newsArticleDto.body, VALIDATION.NEWS.BODY),
  ];

  return errors.includes(true);
}

function errorInImage(imageBlob: Blob | undefined): boolean {
  return imageBlob === undefined;
}

function errorInUser(userDto: UserDTO): boolean {
  const errors: boolean[] = [
    errorInText(userDto.firstName, VALIDATION.USER.NAME),
    errorInText(userDto.lastName, VALIDATION.USER.NAME),
    errorInText(userDto.email, VALIDATION.USER.NAME),
  ];

  return errors.includes(true);
}

function errorUnableToRemoveItemModel(itemModelDto: ItemModelDTO) {
  errorInText(itemModelDto.name, VALIDATION.ITEM.NAME);
}

export {
  errorInText,
  errorInNumber,
  errorInPrice,
  errorInItem,
  errorInLocation,
  errorInNewsArticle,
  errorInInstance,
  errorInImage,
  errorInUser,
  errorUnableToRemoveItemModel,
};
