import React from 'react';
import ReactMarkdown from 'react-markdown';

interface MDTextDisplayProps {
  path: string;
}

function MDTextDisplay(props: MDTextDisplayProps): JSX.Element {
  // const [content, setContent] = useState<string>('');

  // useEffect(() => {
  //   fetch(props.path)
  //     .then((res) => res.text())
  //     .then((text) => setContent(text));
  // }, [props.path]);

  const renderers = {
    //This custom renderer changes how images are rendered
    //we use it to constrain the max width of an image to its container
    image: ({ alt, src, title }: { alt?: string; src?: string; title?: string }) => (
      <img alt={alt} src={src} title={title} style={{ maxWidth: '90%' }} />
    ),
  };
  return <ReactMarkdown renderers={renderers}>{props.path}</ReactMarkdown>;
}

export default MDTextDisplay;
