import { Card } from '@material-ui/core';
import { AccessTime, PersonOutline } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsArticle } from '../../models';
import { newsService } from '../../services';
import useStyles from '../../styles';

function NewsArticleView(props: any): JSX.Element {
  const newsArticleId: number = props.match.params.newsArticleId;
  const classes = useStyles();
  const { t } = useTranslation();
  const [newsArticle, setNewsArticle]: [NewsArticle, any] = useState({
    newsArticleId: 0,
    author: '',
    heading: '',
    body: '',
    published: new Date(),
  });

  useEffect(() => {
    newsService.fetchOne(newsArticleId).then((article: NewsArticle | void) => {
      if (article) {
        setNewsArticle(article as NewsArticle);
      }
    });
    //console.debug('Called effect for newsarticle');
  }, [newsArticleId]);

  const published = moment(newsArticle.published).format('DD.MM.yyyy HH:mm');

  return (
    <div>
      <h1>{t('news.newsroom')}</h1>
      <Card className={classes.component} variant="outlined">
        <h3>{newsArticle.heading}</h3>
        <div className={classes.hFlex}>
          <AccessTime className={classes.smallText} />
          <p className={classes.smallText}>{published}</p>
        </div>
        <div className={classes.hFlex}>
          <PersonOutline className={classes.smallText} />
          <p className={classes.smallText}>{newsArticle.author}</p>
        </div>
        {newsArticle.body.split('\n\n').map((paragraph, i) => (
          <p key={i}>{paragraph}</p>
        ))}
      </Card>
    </div>
  );
}

export default NewsArticleView;
