import { Typography } from '@material-ui/core';
import numeral from 'numeral';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemInstance } from '../../models';
import useStyles from '../../styles';

interface PriceDisplayProps {
  instance: ItemInstance;
  instance2?: ItemInstance;
  style?: CSSProperties;
  showTitle?: boolean;
}
function PriceDisplay({ instance, style, instance2, showTitle = true }: PriceDisplayProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div style={style}>
      {showTitle && (
        <Typography variant="subtitle2" component="span">
          {t('data.fields.price')}:
        </Typography>
      )}
      <Typography variant="h6" component="h6" className={classes.priceDisplay}>
        <span className={classes.priceInt}>{numeral(Math.floor(instance.price || 0)).format('0')}</span>
        <span className={classes.priceDec}>{numeral(instance.price || 0).format('.00')}</span> {instance2 ? null : '€'}
        {instance2 && (
          <span>
            {' '}
            - <span className={classes.priceInt}>{numeral(Math.floor(instance2.price || 0)).format('0')}</span>
            <span className={classes.priceDec}>{numeral(instance2.price || 0).format('.00')}</span> €
          </span>
        )}
      </Typography>
    </div>
  );
}

export default PriceDisplay;
