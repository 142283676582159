import { FormControlLabel, FormGroup, Switch, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATION } from '../../constants';
import { LocationDTO } from '../../models';
import useStyles from '../../styles';
import { errorInText } from './validation';

interface LocationFormProps {
  dto: LocationDTO;
  setDto: any;
  initializeWithDto: boolean;
}

interface LocationValidationErrors {
  name: boolean;
  address: boolean;
}

const initialValidationErrors: LocationValidationErrors = {
  name: false,
  address: false,
};

function LocationForm(props: LocationFormProps): JSX.Element {
  const classes = useStyles();
  const [errors, setErrors]: [LocationValidationErrors, any] = useState(initialValidationErrors);
  const { t } = useTranslation();

  return (
    <div>
      <TextField
        className={classes.vMargin}
        label={t('data.fields.name')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.name : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, name: event.target.value });
        }}
        error={errors.name}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.LOCATION.NAME })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, name: errorInText(event.target.value, VALIDATION.LOCATION.NAME) })
        }
      />
      <TextField
        className={classes.vMargin}
        label={t('data.fields.address')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.address : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, address: event.target.value });
        }}
        error={errors.address}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.LOCATION.ADDRESS })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, address: errorInText(event.target.value, VALIDATION.LOCATION.ADDRESS) })
        }
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={props.dto.usePayments}
              onChange={(event, checked) => {
                props.setDto({ ...props.dto, usePayments: checked });
              }}
            />
          }
          label={t('data.fields.usePayments')}
        ></FormControlLabel>
      </FormGroup>
    </div>
  );
}

export default LocationForm;
