import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { News } from '..';
import { NewsArticle } from '../../models';
import { selectQuery } from '../../store/searchSlice';
import useStyles from '../../styles';

interface NewsArticleResultsProps {
  newsarticles: NewsArticle[];
  redirectUrl: string;
  actionButton?: JSX.Element;
  articleLimit?: number;
}

function NewsArticleResults(props: NewsArticleResultsProps): JSX.Element {
  const { t } = useTranslation();
  const query = useSelector(selectQuery);
  const classes = useStyles();

  const filteredNewsArticles = props.newsarticles.filter((newsarticle: NewsArticle) => {
    const author = newsarticle.author.toLowerCase();
    const heading = newsarticle.heading.toLowerCase();
    const body = newsarticle.body.toLowerCase();

    return query
      ? author.includes(query.toLowerCase()) ||
          heading.includes(query.toLowerCase()) ||
          body.includes(query.toLowerCase())
      : true;
  });

  // TODO: To helper function
  const displayResultText = (): string => {
    if (!query) {
      return t('search.browse.news');
    }
    switch (filteredNewsArticles.length) {
      case 0:
        return t('search.results.none');
      case 1:
        return t('search.results.one');
      default:
        return t('search.results.many', { x: filteredNewsArticles.length });
    }
  };

  // TODO: sort by date, author
  const sortNewsArticlesByDate = (i1: NewsArticle, i2: NewsArticle): number => (i1.published < i2.published ? 1 : -1);

  /*
  const sortNewsArticlesByAuthor = (i1: NewsArticle, i2: NewsArticle): number =>
    i1.author.toLowerCase() > i2.author.toLowerCase() ? 1 : -1;
  */

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <h2>{displayResultText()}</h2>
        {props.actionButton !== undefined && props.actionButton}
      </div>
      <Grid container className={classes.fGrow} spacing={2}>
        {filteredNewsArticles
          .sort(sortNewsArticlesByDate)
          .slice(0, props.articleLimit) // includes all if undefined
          .map((newsarticle: NewsArticle, i: number) => (
            <Grid key={i} xl={3} lg={4} md={6} sm={12} xs={12} item style={{ width: '240px' }}>
              {<News key={newsarticle.newsArticleId} article={newsarticle} redirectUrl={props.redirectUrl} />}
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default NewsArticleResults;
