import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Result } from '@zxing/library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
//import QrReader from 'react-qr-reader';
import { ItemInstance } from '../../models';
import { itemService } from '../../services';
import { createErrorNotification } from '../../services/helpers';
import useStyles from '../../styles';

function QrScanner(props: { onSuccess: (scannedItemInstance: ItemInstance) => void }): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [stopStream, setStopStream]: [boolean, any] = useState(false);
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  const vw80 = (vw / 100) * 80;
  const dismissQrReader = () => {
    setStopStream(true);
    //setTimeout(() => , 0)
  };
  useEffect(() => {
    return function cleanup() {
      dismissQrReader();
    };
  }, []);
  const handleScan = async (err: unknown, data: Result | undefined): Promise<void> => {
    if (err) {
      console.log(err);
    }
    if (data) {
      try {
        const text: string = data.getText();
        const itemInstanceId: number = parseInt(text);
        const scannedItemInstance = await itemService.fetchOneInstance(itemInstanceId);

        if (scannedItemInstance) {
          props.onSuccess(scannedItemInstance);
        } else {
          createErrorNotification(t('notifications.error.scanNotRecognized'));
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Box className={classes.scanner}>
      {/*<QrReader delay={300} onScan={handleScan} onError={handleError} />*/}
      <BarcodeScannerComponent
        onUpdate={handleScan}
        width={String(matches ? (vw80 < 400 ? vw80 : 400) : 400)}
        height={String(matches ? (vw80 < 400 ? vw80 : 400) : 400)}
        onError={(error: any) => {
          if (error && 'name' in error && error.name === 'NotAllowedError') {
            console.error('Camera not allowed');
          }
        }}
        stopStream={stopStream}
      />
    </Box>
  );
}

export default QrScanner;
