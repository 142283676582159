import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Location } from '../models';

interface LocationsState {
  locations: Location[];
}
export const initialState: LocationsState = {
  locations: [] as Location[],
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocations(state, action) {
      state.locations = action.payload;
    },
  },
});

export const { setLocations } = locationsSlice.actions;

export const selectLocations = (state: RootState): Location[] => state.locations?.locations;

export default locationsSlice.reducer;
