import { Button, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SearchBar } from '../../components';
import UserResults from '../../components/user/UserResults';
import { URLS } from '../../constants';
import { User } from '../../models';
import { userService } from '../../services';
import { selectLocations } from '../../store/locationsSlice';
import useStyles from '../../styles';

function AdminSearchUsersView(props: any): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [users, setUsers]: [User[], any] = useState([]);
  const locations = useSelector(selectLocations);
  useEffect(() => {
    userService.fetchAll().then((users: User[] | void) => {
      setUsers(users ?? []);
    });
  }, []);

  const navigateToCreation: () => void = () => {
    history.push(URLS.ADMIN.USER_NEW);
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('search.header')}</h1>
      {/* BUTTON DISABLED BECAUSE BACKEND DOESNT SUPPORT CREATION YET */}
      <Button variant="contained" color="secondary" onClick={navigateToCreation} disabled={true}>
        {t('actions.new')}
      </Button>
    </div>
  );

  const usersByLocation: { [key: string]: User[] } = {};
  users.forEach((user) => {
    if (!usersByLocation[user.defaultLocationId || '__']) usersByLocation[user.defaultLocationId || '__'] = [];
    usersByLocation[user.defaultLocationId || '__'].push(user);
  });
  return (
    <div>
      {header}
      <SearchBar redirectUrl={URLS.ADMIN.USERS} searchingFor="users" initialQuery={props.match.params.query} />
      {Object.keys(usersByLocation).map((locationId) => {
        const location = locations.find((loc) => loc.locationId === parseInt(locationId));
        return (
          <div key={locationId}>
            <h2 key={locationId + '_header'}>{location?.name || 'Ei kohdetta'}</h2>
            <UserResults
              key={locationId + '_results'}
              users={usersByLocation[locationId]}
              redirectUrl={URLS.ADMIN.USER}
            />
            <Divider className={classes.vMargin} />
          </div>
        );
      })}
    </div>
  );
}

export default AdminSearchUsersView;
