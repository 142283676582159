import { IconButton } from '@material-ui/core';
import { FiberManualRecord, FiberManualRecordOutlined, InsertPhotoTwoTone } from '@material-ui/icons';
import React, { useState } from 'react';
import { ImageData } from '../../models';
import useStyles from '../../styles';

interface ImageDisplayProps {
  imageData?: ImageData;
}
function ImageDisplay(props: ImageDisplayProps): JSX.Element {
  const classes = useStyles();
  return (
    <div>
      {props.imageData?.url && (
        <div className={`${classes.vMargin} ${classes.imageCard}`}>
          <img src={props.imageData.url} className={`${classes.fGrow} ${classes.imageFull}`} alt="item"></img>
        </div>
      )}
      {!props.imageData?.url && (
        <div className={`${classes.vMargin} ${classes.imageCard}`}>
          <div
            className={`${classes.fGrow} ${classes.imageFull} ${classes.hFlex} ${classes.justifyCenter} ${classes.alignCenter}`}
            style={{ backgroundColor: '#eaeaea' }}
          >
            <InsertPhotoTwoTone fontSize={'large'} />
          </div>
        </div>
      )}
    </div>
  );
}
interface ImageCarouselProps {
  imageData: ImageData | undefined;
  imageData2?: ImageData | undefined;
  imageData3?: ImageData | undefined;
}
function ImageCarousel(props: ImageCarouselProps): JSX.Element {
  const classes = useStyles();
  const [imageIndex, setImageIndex] = useState(1);
  return (
    <div>
      {imageIndex === 1 && <ImageDisplay imageData={props.imageData} />}
      {imageIndex === 2 && <ImageDisplay imageData={props.imageData2} />}
      {imageIndex === 3 && <ImageDisplay imageData={props.imageData3} />}
      <div className={`${classes.vMargin} ${classes.hFlex} ${classes.justifyCenter}`}>
        {props.imageData && (props.imageData2 || props.imageData3) && (
          <IconButton onClick={() => setImageIndex(1)}>
            {imageIndex === 1 ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
          </IconButton>
        )}
        {props.imageData2 && (
          <IconButton onClick={() => setImageIndex(2)}>
            {imageIndex === 2 ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
          </IconButton>
        )}
        {props.imageData3 && (
          <IconButton onClick={() => setImageIndex(3)}>
            {imageIndex === 3 ? <FiberManualRecord /> : <FiberManualRecordOutlined />}
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default ImageCarousel;
