import { Button, CircularProgress } from '@material-ui/core';
import { Add, ArrowBack, Close, Delete, LockOpen, LockSharp, Save } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useStyles from '../../styles';

function BackButton(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const goBack = (): void => {
    history.goBack();
  };
  return (
    <Button variant="contained" color="default" onClick={goBack} startIcon={<ArrowBack fontSize={'small'} />}>
      {t('actions.back')}
    </Button>
  );
}

interface CreateButtonProps {
  create: () => void;
  checkError: () => boolean;
}

function CreateButton(props: CreateButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={props.checkError()}
      onClick={props.create}
      startIcon={<Add fontSize={'small'} />}
    >
      {t('actions.create')}
    </Button>
  );
}

interface RemoveButtonProps {
  remove: () => void;
}

interface LockButtonProps {
  lock: () => void;
}
interface UnlockButtonProps {
  unlock: () => void;
}
interface CloseButtonProps {
  close: () => void;
}

function RemoveButton(props: RemoveButtonProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.redChip}
      onClick={props.remove}
      startIcon={<Delete fontSize={'small'}></Delete>}
    >
      {t('actions.delete')}
    </Button>
  );
}
function CloseButton(props: CloseButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button variant="contained" color="default" onClick={props.close} startIcon={<Close fontSize={'small'} />}>
      {t('actions.close')}
    </Button>
  );
}
function LockButton(props: LockButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button variant="contained" color="secondary" onClick={props.lock} startIcon={<LockSharp fontSize={'small'} />}>
      {t('actions.lock')}
    </Button>
  );
}
function UnlockButton(props: UnlockButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button variant="contained" color="secondary" onClick={props.unlock} startIcon={<LockOpen fontSize={'small'} />}>
      {t('actions.unlock')}
    </Button>
  );
}

interface SaveButtonProps {
  save: () => void;
  checkError: () => boolean;

  loading?: boolean;
}

function SaveButton(props: SaveButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={props.checkError()}
      onClick={props.save}
      startIcon={props.loading ? <CircularProgress /> : <Save fontSize={'small'} />}
    >
      {t('actions.save')}
    </Button>
  );
}

export { BackButton, CreateButton, RemoveButton, SaveButton, LockButton, UnlockButton, CloseButton };
