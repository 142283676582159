import { Button, Checkbox, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StaticContext } from 'react-router';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { InstanceComponent } from '../../components';
import { TermsOfUseDialog } from '../../components/item/TermsOfUseDialog';
import { URLS } from '../../constants';
import { getLocationById } from '../../helpers';
import { BorrowingDTO, ItemInstance, ItemModel } from '../../models';
import { borrowingService } from '../../services';
import { RootState } from '../../store';
import { selectLocations } from '../../store/locationsSlice';
import { selectQuery } from '../../store/searchSlice';
import useStyles from '../../styles';
import PaymentMethodsView from './PaymentMethodsView';

interface BorrowPageProps {
  item: ItemModel;
  instance: ItemInstance;
}

export function BorrowingPage(props: RouteComponentProps<any, StaticContext, BorrowPageProps>): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const userId = useSelector((state: RootState) => state.user.id);
  const [open, setOpen] = React.useState(false);
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const [firstQuery, setFirstQuery] = useState<boolean>(true);
  const query = useSelector(selectQuery);
  let item: ItemModel = {} as ItemModel;
  let instance: ItemInstance = {} as ItemInstance;
  const locations = useSelector(selectLocations);

  if (props.location.state) {
    instance = props.location.state.instance;
    item = props.location.state.item;
  }
  const location = useMemo(() => {
    if (instance) {
      return getLocationById(locations, instance.locationId);
    }
    return null;
  }, [instance, locations]);

  useEffect(() => {
    // A bit hackish way of checking store before fetching everything (as there's no query)
    if (firstQuery) {
      setFirstQuery(false);
      return;
    }
  }, [firstQuery, query]);

  const showTermsOfUse = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleTermsCheckChange = (): void => {
    setTermsAgreed(!termsAgreed);
  };

  const handleBorrowing = async (): Promise<void> => {
    const borrowingDto: BorrowingDTO = {
      itemInstanceId: instance.itemInstanceId,
      // Route is protected, user has to be logged in
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userId: userId!,
    };
    try {
      await borrowingService.createBorrowing(borrowingDto);
      history.push(URLS.EU.INSTANCE_NOQUERY + `/${instance.itemInstanceId}`);
    } catch (e) {
      console.log(e);
    }
  };

  const borrowingInformation: JSX.Element = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">{t('borrowing.header')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <InstanceComponent instance={instance} item={item} borrowButton={false} />
      </Grid>
      <Grid item xs={12}>
        <hr className={classes.borrowingDivider} />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>{t('borrowing.agreementText')}</Typography>
        <Button variant="contained" color="secondary" onClick={showTermsOfUse}>
          {t('borrowing.tou')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          label={t('borrowing.agreementCheckbox')}
          control={<Checkbox checked={termsAgreed} onChange={handleTermsCheckChange} />}
        />
      </Grid>
      {location && location.usePayments && instance.price > 0 && (
        <Grid item xs={12}>
          <PaymentMethodsView disabled={!termsAgreed} instance={instance} />
        </Grid>
      )}
      {((location && !location.usePayments) || instance.price === 0) && (
        <Grid item xs={12}>
          <Button
            onClick={handleBorrowing}
            variant="contained"
            color="primary"
            className={classes.blockBorrow}
            disabled={!termsAgreed}
          >
            {t('item.actions.borrow')}
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const notValid: JSX.Element = (
    <div>
      Item to be borrowed not found. There could have been error loading the product or this page might have been
      accessed without scanning item. In either case please return to scanning
    </div>
  );

  if (!instance) {
    return <div>{notValid}</div>;
  }
  return (
    <div>
      <Paper className={classes.borrowingCard} elevation={1} variant="outlined">
        {instance.itemInstanceId ? borrowingInformation : notValid}
        <TermsOfUseDialog isOpen={open} closeDialog={handleClose}></TermsOfUseDialog>
      </Paper>
    </div>
  );
}
