import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { URLS } from '../constants';
import { signinRedirectCallback } from '../services/authenticationService';

const LoginRedirect = (): JSX.Element => {
  useEffect(() => {
    async function signinAsync(): Promise<void> {
      await signinRedirectCallback();
      // history push doesn't reload page and then user is not identified after login
      window.location.href = URLS.ROOT;
    }
    signinAsync();
  }, []);

  return (
    <div>
      Processing login..
      <CircularProgress />
    </div>
  );
};

export default LoginRedirect;
