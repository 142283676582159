import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface QrCodeDialogProps {
  closeDialog: () => void;
  itemInstanceId: number;
  isOpen: boolean;
}

export function QrCodeDialog(props: QrCodeDialogProps): JSX.Element {
  const { t } = useTranslation();
  const downloadQRCode = (): void => {
    const canvas = document.getElementById('qr-code') as HTMLCanvasElement;
    const pngUrl = canvas!.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrCodeProduct.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Dialog onClose={props.closeDialog} aria-labelledby="dialog-title" open={props.isOpen}>
      <DialogTitle id="dialog-title">{t('scan.qrCode')}</DialogTitle>
      <DialogContent dividers>
        <QRCode id="qr-code" size={180} value={props.itemInstanceId.toString()} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={downloadQRCode}>
          {t('actions.downloadQr')}
        </Button>
        <Button autoFocus onClick={props.closeDialog} color="secondary">
          {t('actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
