import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Statistic } from '../models';
import { checkStatus, createErrorNotification } from './helpers';

const fetchAllAsOne = async function (): Promise<Statistic | void> {
  const model: string = i18n.t('data.models.statistic');
  const query = `${URLS.API}/statistics`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Statistic>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

export default { fetchAllAsOne };
