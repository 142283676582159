import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BorrowingInfo } from '../../models';
import { selectLocations } from '../../store/locationsSlice';
import useStyles from '../../styles';
import BorrowedItem from '../item/BorrowedItem';

interface BorrowingResultsProps {
  infos: BorrowingInfo[];
  redirectUrl: string;
  adminPage?: boolean;
  reload?: () => void;
}

function BorrowingResults(props: BorrowingResultsProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const locations = useSelector(selectLocations);
  const ongoingBorrowings: BorrowingInfo[] = props.infos.filter(
    (info: BorrowingInfo) => info.borrowing.endDate === null
  );
  const endedBorrowings: BorrowingInfo[] = props.infos.filter((info: BorrowingInfo) => info.borrowing.endDate !== null);

  const displayResultText = (): string => (props.adminPage ? '' : t('search.browse.borrowings'));

  const sortItemsByDate = (i1: BorrowingInfo, i2: BorrowingInfo): number =>
    i1.borrowing.startDate < i2.borrowing.startDate ? 1 : -1;

  const createItemGrid = (borrowingInfos: BorrowingInfo[], altTitle?: string): JSX.Element => {
    return (
      <div>
        {borrowingInfos.length !== 0 && (
          <div className={classes.flexRow}>
            <h2>{altTitle ?? displayResultText()}</h2>
          </div>
        )}
        <Grid container className={classes.fGrow} spacing={2}>
          {borrowingInfos.sort(sortItemsByDate).map((info: BorrowingInfo, i: number) => {
            return (
              <Grid item className={classes.gridItem} key={i} xl={6} lg={6} md={12} sm={12} xs={12}>
                <BorrowedItem
                  key={info.borrowing.borrowingId}
                  info={info}
                  borrowStatus={info.itemInstance.available}
                  redirectUrl={props.redirectUrl}
                  adminPage={props.adminPage}
                  reload={props.reload}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  };

  return (
    <div>
      {createItemGrid(ongoingBorrowings)}
      {createItemGrid(endedBorrowings, t('borrowing.endedBorrows'))}
    </div>
  );
}

export default BorrowingResults;
