import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { User, UserDTO } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const fetchAll = async function (): Promise<User[] | void> {
  const model: string = i18n.t('data.models.user');
  const query = `${URLS.API}/users`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<User[]>;
  } catch {
    createErrorNotification(i18n.t('notifications.errors.fetch', { model }));
  }
};

const fetchOne = async function (userId: string): Promise<User | void> {
  const model: string = i18n.t('data.models.user');
  const query = `${URLS.API}/users/${userId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<User>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const createOne = async function (userId: string, userDto: UserDTO): Promise<User | void> {
  const model: string = i18n.t('data.models.user');
  const query = `${URLS.API}/users?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(userDto),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const user: Promise<User> = res.json() as Promise<User>;
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return user;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

const updateOne = async function (userId: string, userDto: UserDTO, updatedUserId: string): Promise<User | void> {
  const model: string = i18n.t('data.models.user');
  const query = `${URLS.API}/users/${updatedUserId}?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(userDto),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const user: Promise<User> = res.json() as Promise<User>;
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return user;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update'));
  }
};

const deleteOne = async function (userId: string, deletedUserId: string): Promise<User[] | void> {
  const model: string = i18n.t('data.models.user');
  const query = `${URLS.API}/users/${deletedUserId}?userId=${userId}`;
  const init: RequestInit = { method: 'DELETE' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const user: Promise<User[]> = res.json() as Promise<User[]>;
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return user;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};

const lockOne = async function (userId: string, lockedUserId: string): Promise<User[] | void> {
  const model: string = i18n.t('data.models.user');
  const query = `${URLS.API}/users/${lockedUserId}/lock?userId=${userId}`;
  const init: RequestInit = { method: 'PUT' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const user: Promise<User[]> = res.json() as Promise<User[]>;
    createSuccessNotification(i18n.t('notifications.success.lock', { model }));
    return user;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.lock', { model }));
  }
};
const unlockOne = async function (userId: string, lockedUserId: string): Promise<User[] | void> {
  const model: string = i18n.t('data.models.user');
  const query = `${URLS.API}/users/${lockedUserId}/unlock?userId=${userId}`;
  const init: RequestInit = { method: 'PUT' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const user: Promise<User[]> = res.json() as Promise<User[]>;
    createSuccessNotification(i18n.t('notifications.success.unlock', { model }));
    return user;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.unlock', { model }));
  }
};
const updateRole = async function (ownUserId: string, userId: string, isAdmin: boolean): Promise<User[] | void> {
  const bodyText = isAdmin.toString();
  const query = `${URLS.API}/users/updateRole/${userId}?userId=${ownUserId}`;
  const init: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodyText,
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const user: Promise<User[]> = res.json() as Promise<User[]>;
    // No notifications here, because updateOne shows
    // createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return user;
  } catch {
    // return createErrorNotification(i18n.t('notifications.error.update', { model }));
  }
};

export default { fetchAll, fetchOne, createOne, updateOne, deleteOne, updateRole, lockOne, unlockOne };
