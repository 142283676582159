import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface SearchFilters {
  ownLocation: boolean;
  freeItems: boolean;
  category: string;
}

interface SearchState {
  query?: string;
  filters: SearchFilters;
}

export const initialState: SearchState = {
  query: undefined,
  filters: {
    ownLocation: false,
    freeItems: false,
    category: '',
  },
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateQuery(state, action) {
      state.query = action.payload;
    },
    updateLocationFilter(state, action) {
      state.filters.ownLocation = action.payload;
    },
    updateItemStatusFilter(state, action) {
      state.filters.freeItems = action.payload;
    },
    updateCategoryFilter(state, action) {
      state.filters.category = action.payload;
    },
  },
});

export const { updateQuery, updateLocationFilter, updateItemStatusFilter, updateCategoryFilter } = searchSlice.actions;

export const selectQuery = (state: RootState): string | undefined => state.search?.query;
export const selectFilters = (state: RootState): SearchFilters => state.search?.filters;

export default searchSlice.reducer;
