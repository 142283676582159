import { Button, Grid, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ItemResults, MDTextDisplay, NewsArticleResults } from '../../components';
import Feedback from '../../components/cfa/Feedback';
import { URLS } from '../../constants';
import { ItemInstance, ItemModel, NewsArticle } from '../../models';
import { itemService, newsService, pageContentService } from '../../services';
import { selectLanguage } from '../../store/settingsSlice';
import useStyles from '../../styles';
import theme from '../../theme';

function HomeView(props: any): JSX.Element {
  const { t } = useTranslation();

  const language = useSelector(selectLanguage);
  const [text, setText] = useState<string>('');
  const [heading, setHeading] = useState<string>('');
  const [text2, setText2] = useState<string>('');
  const [heading2, setHeading2] = useState<string>('');

  const [items, setItems] = useState<ItemModel[]>([]);
  const [instances, setInstances] = useState<ItemInstance[]>([]);
  const [firstQuery, setFirstQuery] = useState<boolean>(true);
  const [newsarticles, setNewsarticles]: [NewsArticle[], any] = useState([]);
  const history = useHistory();
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [loadingInstances, setLoadingInstances] = useState<boolean>(false);
  const classes = useStyles();
  useEffect(() => {
    newsService.fetchAll().then((newsarticles: NewsArticle[] | void) => setNewsarticles(newsarticles ?? []));
    //console.debug('Called effect for news');
  }, []);
  useEffect(() => {
    let lang = 0;
    if (language === 'fi') {
      lang = 1;
    } else if (language === 'en') {
      lang = 2;
    }
    // 3 for Help
    pageContentService.getPage(6, lang).then((res) => {
      const result: any = res;
      if (result && result.length > 0) {
        setText(result[result.length - 1].body);
        setHeading(result[result.length - 1].heading);
      } else {
        setText('');
        setHeading('');
      }
    });
  }, [language]);
  useEffect(() => {
    let lang = 0;
    if (language === 'fi') {
      lang = 1;
    } else if (language === 'en') {
      lang = 2;
    }
    // 3 for Help
    pageContentService.getPage(7, lang).then((res) => {
      const result: any = res;
      if (result && result.length > 0) {
        setText2(result[result.length - 1].body);
        setHeading2(result[result.length - 1].heading);
      } else {
        setText2('');
        setHeading2('');
      }
    });
  }, [language]);
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    // A bit hackish way of checking store before fetching everything (as there's no query)
    if (firstQuery) {
      setFirstQuery(false);
      return;
    }

    setLoadingItems(true);
    const amount = isBelowXs ? 3 : isBelowSm ? 4 : 8;
    itemService.fetchAll(`&Limit=${amount}&GetInstances=true&Randomized=true`).then((items: ItemModel[] | void) => {
      if (items) {
        setItems(items);
        setInstances(items.flatMap((item) => item.instances ?? []).filter(Boolean));
      }
      setLoadingItems(false);
    });

    /*setLoadingInstances(true);
    itemService.fetchAllInstances(query).then((instances: ItemInstance[] | void) => {
      if (instances) {
        setInstances(instances);
        setLoadingInstances(false);
      }
    });*/
  }, [firstQuery]);

  return (
    <>
      <h1 key={2}>{t('home.header')}</h1>
      <Feedback />
      <div key={3}>
        <ItemResults
          sort={false}
          items={items}
          instances={instances}
          redirectUrl={URLS.EU.ITEM_NOQUERY}
          loading={loadingItems || loadingInstances}
        />
        <Button
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          onClick={() => history.push(`/search`)}
          style={{ marginTop: '2rem' }}
        >
          {t('home.viewAllItems')}
        </Button>
      </div>
      <div key={5} style={{ marginTop: '2em', marginBottom: '2em' }}>
        <h2>{heading}</h2>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '2rem', paddingRight: '1rem' }}>
            <div className={[classes.vFlex, classes.h100].join(' ')}>
              <div>
                <MDTextDisplay path={text} />
              </div>
              <span className={classes.spacer} />
              <Button
                variant={'contained'}
                color={'secondary'}
                size={'small'}
                style={{ alignSelf: 'flex-start' }}
                onClick={() => history.push(URLS.EU.HELP)}
              >
                {t('views.help')}
              </Button>
            </div>
          </Grid>

          {false && (
            <Grid item xs={12} sm={12} md={6}>
              <img
                style={{ maxWidth: '100%', maxHeight: '400px' }}
                src={'https://www.murrost.fi/wp-content/uploads/2021/10/tavaroiden_yhteiskaytto.JPG-683x1024.jpg'}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <div key={4}>
        <NewsArticleResults newsarticles={newsarticles} redirectUrl={URLS.EU.NEWS} articleLimit={3} />
      </div>
      <div key={6} style={{ marginTop: '3em', marginBottom: '2em' }}>
        <h2>{heading2}</h2>
        <Grid container>
          <Grid item xs={12} sm={12} md={8} style={{ marginBottom: '2rem', paddingRight: '1rem' }}>
            <div className={[classes.vFlex, classes.h100].join(' ')}>
              <div>
                <MDTextDisplay path={text2} />
              </div>
              <span className={classes.spacer} />
              <Button
                variant={'contained'}
                color={'secondary'}
                size={'small'}
                style={{ alignSelf: 'flex-start' }}
                href={'mailto:info@murrost.fi'}
              >
                {t('general.contact')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default HomeView;
