import moment from 'moment';
import { Location } from './models';

const isIOS: boolean = (() => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
})();
const getLocationById = (locations: Location[], locationId: number) => {
  return locations?.find((location) => location.locationId === locationId);
};
const secondsToDays = (seconds: number): number => {
  const duration = moment.duration(seconds * 1000);
  return duration.asDays();
};

export { isIOS, getLocationById, secondsToDays };
