// TODO: This should be from docker env value, but use this as workaround until

let baseUrl: string;
if (process.env['REACT_APP_ENVIRONMENT'] === 'staging') {
  baseUrl = 'https://staging.shareandsave.fi';
} else if (process.env['REACT_APP_ENVIRONMENT'] === 'production') {
  baseUrl = 'https://shareandsave.fi';
} else {
  baseUrl = 'https://localhost:5001';
}

const dummySvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg').outerHTML;

const createImageData = (setter: any, imageBlob: Blob | void): void => {
  if (imageBlob) {
    setter({
      blob: imageBlob,
      url: URL.createObjectURL(imageBlob),
    });
  }
};

export { baseUrl, dummySvg, createImageData };
