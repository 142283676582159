import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AnimatedBar } from '../../components';
import { URLS } from '../../constants';
import { Statistic } from '../../models';
import { statisticService } from '../../services';
import useStyles from '../../styles';

function AdminDashboard(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [statistics, setStatistics] = useState<Statistic>();
  const [statError, setStatError] = useState<boolean>(false);
  let largest = 0;

  useEffect(() => {
    statisticService.fetchAllAsOne().then((response: Statistic | void) => {
      if (response) {
        setStatistics(response);
      } else {
        setStatError(true);
      }
    });
  }, []);

  function BarChart(statistics: Statistic | any, barMaxValue: number): JSX.Element {
    return (
      <div>
        <AnimatedBar
          maxValue={barMaxValue}
          label={t('admin.statistics.totalLikes')}
          value={statistics ? statistics.totalLikes : 0}
        />
        <AnimatedBar
          maxValue={barMaxValue}
          label={t('admin.statistics.currentBorrowings')}
          value={statistics ? statistics.currentBorrowings : 0}
        />
        <AnimatedBar
          maxValue={barMaxValue}
          label={t('admin.statistics.totalBorrowings')}
          value={statistics ? statistics.totalBorrowings : 0}
        />
        <AnimatedBar
          maxValue={barMaxValue}
          label={t('admin.statistics.totalItems')}
          value={statistics ? statistics.totalItems : 0}
        />
        <AnimatedBar
          maxValue={barMaxValue}
          label={t('admin.statistics.totalLocations')}
          value={statistics ? statistics.totalLocations : 0}
        />
        <AnimatedBar
          maxValue={barMaxValue}
          label={t('admin.statistics.totalUsers')}
          value={statistics ? statistics.totalUsers : 0}
        />
      </div>
    );
  }
  if (statistics) {
    const arr = Object.values(statistics as Statistic);
    largest = Math.max(...arr);
  }

  const errorText: JSX.Element = (
    <div>
      <br />
      <h4 style={{ color: 'red' }}>{t('general.fetchError')}</h4>
    </div>
  );

  return (
    <div>
      <h1>{t('views.dashboard')}</h1>
      {BarChart(statistics, largest)}
      {statError && errorText}
      <Button
        style={{ color: 'white', backgroundColor: '#0288d1', padding: 15, borderRadius: 6, cursor: 'pointer' }}
        onClick={() => {
          history.push(`${URLS.ADMIN.STATISTICS}`);
          history.go(0);
        }}
      >
        {t('admin.statistics.openApi')}
      </Button>
    </div>
  );
}

export default AdminDashboard;
