import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATION } from '../../constants';
import { NewsArticleDTO } from '../../models';
import useStyles from '../../styles';
import { errorInText } from './validation';

interface NewsFormProps {
  dto: NewsArticleDTO;
  setDto: any;
  initializeWithDto: boolean;
}

interface NewsValidationErrors {
  author: boolean;
  heading: boolean;
  body: boolean;
}

const initialValidationErrors: NewsValidationErrors = {
  author: false,
  heading: false,
  body: false,
};

function NewsForm(props: NewsFormProps): JSX.Element {
  const classes = useStyles();
  const [errors, setErrors]: [NewsValidationErrors, any] = useState(initialValidationErrors);
  const { t } = useTranslation();

  return (
    <div>
      <TextField
        className={classes.vMargin}
        label={t('data.fields.author')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.author : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, author: event.target.value });
        }}
        error={errors.author}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.NEWS.AUTHOR })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, author: errorInText(event.target.value, VALIDATION.NEWS.AUTHOR) })
        }
      />

      <TextField
        className={classes.vMargin}
        label={t('data.fields.heading')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.heading : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, heading: event.target.value });
        }}
        error={errors.heading}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.NEWS.HEADING })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, heading: errorInText(event.target.value, VALIDATION.NEWS.HEADING) })
        }
      />

      <TextField
        className={classes.vMargin}
        multiline
        rows={6}
        label={t('data.fields.body')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.dto.body : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setDto({ ...props.dto, body: event.target.value });
        }}
        error={errors.body}
        helperText={t('validation.mustHaveLength', { length: VALIDATION.NEWS.BODY })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, body: errorInText(event.target.value, VALIDATION.NEWS.BODY) })
        }
      />
    </div>
  );
}

export default NewsForm;
