import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { signinRedirect } from '../services/authenticationService';

const Login = (): JSX.Element => {
  signinRedirect();

  return (
    <div>
      Processing login..
      <CircularProgress />
    </div>
  );
};

export default Login;
