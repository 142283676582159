import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import React from 'react';
import useStyles from '../styles';

function NotFoundPage(): JSX.Element {
  const classes = useStyles();
  return (
    <div>
      <p className={classes.viewName}>Not found</p>
      <p className={classes.viewName}>
        The page you tried to access was not found
        <SentimentVeryDissatisfied color="error" />
      </p>
    </div>
  );
}

export default NotFoundPage;
