import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InfoTextModifyHelpLegal from '../../components/admin/InfoTextModifyHelpLegal';
import { pageContentService } from '../../services';
import { selectUser } from '../../store/userSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
  },
  formControl: {
    minWidth: 200,
  },

  formControlLang: {
    minWidth: 100,
  },

  button: {
    marginTop: 12,
  },

  textArea: {
    minWidth: 600,
    left: -13,
  },
}));

function AdminModifyHelpLegal(): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [lang, setLang] = useState<number>(1);
  const [heading, setHeading] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [pageContentId, setPageContentId] = useState<number>(0);
  const [pageTypeFromBack, setPageTypeFromBack] = useState<number>(0);
  const [pageLangFromBack, setPageLangFromBack] = useState<number>(0);
  const [pageTextFromBack, setPageTextFromBack] = useState<string>('');
  const [pageHeadingFromBack, setPageHeadingFromBack] = useState<string>('');
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [disableCancel, setDisableCancel] = useState<boolean>(true);
  const [disableText, setDisableText] = useState<boolean>(true);

  const ownUserId: string = useSelector(selectUser).id ?? '';

  const { t } = useTranslation();
  const classes = useStyles();

  const getContent: () => void = () => {
    pageContentService.getPage(page, lang).then((res) => {
      const result: any = res;
      if (result.length !== 0) {
        setHeading(result[result.length - 1].heading);
        setText(result[result.length - 1].body);
        setPageContentId(result[result.length - 1].pageContentId);
        setPageTypeFromBack(result[result.length - 1].type);
        setPageLangFromBack(result[result.length - 1].language);
        setPageTextFromBack(result[result.length - 1].body);
        setPageHeadingFromBack(result[result.length - 1].heading);
        setDisableText(false);
      } else {
        setHeading('');
        setText('');
        setDisableText(false);
      }
    });
  };

  const savePageContent: () => void = () => {
    pageContentService.savePageContent(ownUserId, pageContentId, page, lang, heading, text).then((res) => {
      const result: any = res;
      console.log(result.body);
      setPageTextFromBack(result.body);
    });
  };

  const cancelText: () => void = () => {
    setText(pageTextFromBack);
    setHeading(pageHeadingFromBack);
  };

  useEffect(() => {
    if (page === pageTypeFromBack && lang === pageLangFromBack) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [page, lang, pageTypeFromBack, pageLangFromBack]);

  useEffect(() => {
    if (text !== pageTextFromBack || heading !== pageHeadingFromBack) {
      setDisableCancel(false);
    } else {
      setDisableCancel(true);
    }
  }, [heading, pageHeadingFromBack, pageTextFromBack, text]);

  return (
    <div>
      <Grid container spacing={3} className={classes.container}>
        <h1>{t('views.modifyPages')}</h1>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel id="page">{t('general.page')}</InputLabel>
              <Select
                labelId="page"
                id="pageSelect"
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setPage(event.target.value as number);
                }}
                defaultValue={1}
              >
                <MenuItem value={1}>{t('views.termsOfService')}</MenuItem>
                <MenuItem value={2}>{t('views.privacyPolicy')}</MenuItem>
                <MenuItem value={3}>{t('views.help')}</MenuItem>
                <MenuItem value={4}>{t('views.helpAdmin')}</MenuItem>
                <MenuItem value={5}>{t('item.usageTerms')}</MenuItem>
                <MenuItem value={6}>{t('views.home')}</MenuItem>
                <MenuItem value={7}>{t('views.home2')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControlLang}>
              <InputLabel id="lang">{t('general.lang')}</InputLabel>
              <Select
                labelId="lang"
                id="langSelect"
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setLang(event.target.value as number);
                }}
                defaultValue={1}
              >
                <MenuItem value={1}>FI</MenuItem>
                <MenuItem value={2}>EN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" className={classes.button} onClick={getContent}>
              {t('actions.get')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <form noValidate autoComplete="off">
            <TextField
              className={classes.textArea}
              id="outlined-static"
              label={t('data.fields.heading')}
              variant="outlined"
              value={heading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setHeading(event.target.value);
              }}
              disabled={disableText}
            ></TextField>
          </form>
        </Grid>
        <Grid item xs={12}>
          <form noValidate autoComplete="off">
            <TextField
              className={classes.textArea}
              id="outlined-multiline-static"
              label={t('general.content')}
              multiline
              rows={24}
              variant="outlined"
              value={text}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setText(event.target.value);
              }}
              disabled={disableText}
            />
          </form>
        </Grid>
        <Grid item xs={3}>
          <InfoTextModifyHelpLegal />
        </Grid>
        <Grid container direction="row" justify="flex-end" spacing={3} xs={9}>
          <Grid item>
            <Button variant="contained" color="primary" disabled={disableCancel} onClick={cancelText}>
              Peruuta
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={savePageContent} disabled={disableSave}>
              Tallenna
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminModifyHelpLegal;
