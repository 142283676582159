import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MDTextDisplay } from '../../components';
import { pageContentService } from '../../services';
import { selectLanguage } from '../../store/settingsSlice';

function LegalView(): JSX.Element {
  const language = useSelector(selectLanguage);
  const [textTos, setTextTos] = useState<string>('');
  const [headingTos, setHeadingTos] = useState<string>('');
  const [textPp, setTextPp] = useState<string>('');
  const [headingPp, setHeadingPp] = useState<string>('');

  useEffect(() => {
    let lang = 0;
    if (language === 'fi') {
      lang = 1;
    } else if (language === 'en') {
      lang = 2;
    }
    // 1 for Tos
    pageContentService.getPage(1, lang).then((res) => {
      const result: any = res;
      if (result && result.length > 0) {
        setTextTos(result[result.length - 1].body);
        setHeadingTos(result[result.length - 1].heading);
      } else {
        setTextTos('');
        setHeadingTos('');
      }
    });

    // 2 for pp
    pageContentService.getPage(2, lang).then((res) => {
      const result: any = res;
      if (result && result.length > 0) {
        setTextPp(result[result.length - 1].body);
        setHeadingPp(result[result.length - 1].heading);
      } else {
        setTextPp('');
        setHeadingPp('');
      }
    });
  }, [language]);

  return (
    <div>
      <h1>{headingTos}</h1>
      <MDTextDisplay path={textTos} />
      <h1>{headingPp}</h1>
      <MDTextDisplay path={textPp} />
    </div>
  );
}

export default LegalView;
