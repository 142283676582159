import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ItemInstance, ItemModel } from '../../models';
import { selectQuery } from '../../store/searchSlice';
import useStyles from '../../styles';

function NotPaidView(props: any): JSX.Element {
  const [items, setItems] = useState<ItemModel[]>([]);
  const [instances, setInstances] = useState<ItemInstance[]>([]);
  const [firstQuery, setFirstQuery] = useState<boolean>(true);
  const query = useSelector(selectQuery);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t('payment.orderWasNotPaid')}</h3>
    </div>
  );
}

export default NotPaidView;
