import { Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import { URLS } from './constants';
import shareAndSaveLogo from './share_and_save.svg';
import useStyles from './styles';

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const moveTo = (url: string): void => {
    history.push(url);
  };

  return (
    <Route exact path={URLS.ROOT}>
      <div className={classes.footerSection}>
        <div className={classes.heroContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <img src={shareAndSaveLogo} alt="Share & Save" className={classes.logo} />
              <Typography variant={'subtitle2'} className={[classes.white, classes.bold, classes.vMargin].join(' ')}>
                Share & Save
              </Typography>
              <Link className={classes.cursorPointer} onClick={() => moveTo(URLS.ROOT)}>
                <Typography variant={'subtitle2'} className={classes.white}>
                  {t('views.home')}
                </Typography>
              </Link>

              <Link className={classes.cursorPointer} onClick={() => moveTo(URLS.EU.SEARCH)}>
                <Typography variant={'subtitle2'} className={classes.white}>
                  {t('views.items')}
                </Typography>
              </Link>
              <Link className={classes.cursorPointer} onClick={() => moveTo(URLS.EU.NEWS)}>
                <Typography variant={'subtitle2'} className={classes.white}>
                  {t('views.news')}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.footerTopMargin}></div>
              <Typography variant={'subtitle2'} className={[classes.white, classes.bold, classes.vMargin].join(' ')}>
                Info
              </Typography>
              <Link className={classes.cursorPointer} onClick={() => moveTo(URLS.EU.HELP)}>
                <Typography variant={'subtitle2'} className={classes.white}>
                  {t('views.help')}
                </Typography>
              </Link>

              <Link className={classes.cursorPointer} onClick={() => moveTo(URLS.EU.LEGAL)}>
                <Typography variant={'subtitle2'} className={classes.white}>
                  {t('views.legal')}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.footerTopMargin}></div>
              <Typography variant={'subtitle2'} className={[classes.white, classes.bold, classes.vMargin].join(' ')}>
                {t('general.contact')}
              </Typography>
              <Link href={'mailto:info@murrost.fi'}>
                <Typography variant={'subtitle2'} className={classes.white}>
                  info@murrost.fi
                </Typography>
              </Link>

              <Link href={'https://murrost.fi'}>
                <Typography variant={'subtitle2'} className={classes.white}>
                  murrost.fi
                </Typography>
              </Link>

              <Typography variant={'subtitle2'} className={classes.white}>
                Murrost Oy
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Route>
  );
}
