import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilters, updateItemStatusFilter, updateLocationFilter } from '../../store/searchSlice';
import { selectUser } from '../../store/userSlice';

function SearchFilters(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLocation = useSelector(selectUser)?.defaultLocationId;
  const filters = useSelector(selectFilters);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const toggleItemFilter = (event: ChangeEvent<{ checked: boolean }>): void => {
    dispatch(updateItemStatusFilter(event.target.checked));
  };
  const toggleLocationFilter = (event: ChangeEvent<{ checked: boolean }>): void => {
    dispatch(updateLocationFilter(event.target.checked));
  };

  const handleFilterOpen = (): void => setFiltersOpen(true);
  const handleFilterClose = (): void => setFiltersOpen(false);

  return (
    <div>
      <IconButton onClick={handleFilterOpen}>
        <Tune />
      </IconButton>
      <Dialog open={filtersOpen} onClose={handleFilterClose}>
        <DialogTitle>{t('search.filterResults')}</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={filters.freeItems} onChange={toggleItemFilter} />}
                label={t('search.filters.available')}
              />
              {userLocation === undefined && (
                <FormControlLabel
                  disabled={userLocation === undefined}
                  control={<Checkbox checked={filters.ownLocation} onChange={toggleLocationFilter} />}
                  label={t('search.filters.ownLocation')}
                />
              )}
            </FormGroup>
          </FormControl>

          <DialogActions>
            <Button onClick={handleFilterClose}>{t('actions.close')}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SearchFilters;
