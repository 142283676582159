import LockerDoor from './LockerDoor';

export default interface Locker {
  id?: number;
  locationId: number;
  lockerId: string;
  deprecated: boolean;

  description: string;

  type: LockerType;

  doors: LockerDoor[];
}

export enum LockerType {
  PUNTA = 'PUNTA',
}
