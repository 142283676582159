import { Card, CardActionArea } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NewsArticle } from '../../models';
import useStyles from '../../styles';

interface NewsProps {
  article: NewsArticle;
  redirectUrl: string;
  continueAfter?: number;
}

// TODO: list of articles as props or news.header in upper level?
// (unnecessary in news view but maybe not in main view)
function News(props: NewsProps): JSX.Element {
  const article = props.article;
  const published = moment(article.published).format('DD.MM.yyyy HH:mm');
  const redirectUrl = props.redirectUrl;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const continueAfter = props.continueAfter ?? 120;
  // TODO: Better splitting
  // TODO: only headings if many
  const tooLong = article.body.length > continueAfter;
  const displayedText = tooLong ? article.body.substring(0, continueAfter) + '...' : article.body;

  return (
    <Card variant="outlined">
      <CardActionArea
        className={[classes.card].join(' ')}
        style={{
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
        onClick={() => {
          history.push(`${redirectUrl}/${article.newsArticleId}`);
        }}
      >
        <h3>{article.heading}</h3>
        <h5>{t('news.published') + ' ' + published}</h5>
        <div>{displayedText}</div>
      </CardActionArea>
    </Card>
  );
}

export default News;
