import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Locker, LockerDoor } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const fetchAll = async function (): Promise<Locker[] | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Locker[]>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchAllByLocation = async function (locationId: number): Promise<Locker[] | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers/?locationId=${locationId}`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Locker[]>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOne = async function (lockerId: number): Promise<Locker | undefined> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers/${lockerId}`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Locker>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
    return undefined;
  }
};

const createOne = async function (lockerDto: Locker): Promise<Locker | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers`;
  const init: RequestInit = {
    body: JSON.stringify(lockerDto),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const locker: Promise<Locker> = res.json() as Promise<Locker>;
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return locker;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

const updateOne = async function (lockerDto: Locker, lockerId: number): Promise<Locker | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers/${lockerId}`;
  const init: RequestInit = {
    body: JSON.stringify(lockerDto),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const locker: Promise<Locker> = res.json() as Promise<Locker>;
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return locker;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update'));
  }
};

/*const deleteOne = async function (lockerId: number): Promise<Locker[] | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers/${lockerId}`;
  const init: RequestInit = { method: 'DELETE' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const locker: Promise<Locker[]> = res.json() as Promise<Locker[]>;
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return locker;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};*/
const deprecateOne = async function (lockerId: number): Promise<Locker[] | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers/${lockerId}/deprecate`;
  const init: RequestInit = { method: 'PATCH' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const locker: Promise<Locker[]> = res.json() as Promise<Locker[]>;
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return locker;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};
const openDoor = async function (lockerId: number, lockerDoorId: number): Promise<Locker[] | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers/${lockerId}/${lockerDoorId}/open`;
  const init: RequestInit = { method: 'POST' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const locker: Promise<Locker[]> = res.json() as Promise<Locker[]>;
    createSuccessNotification(i18n.t('notifications.success.open', { model }));
    return locker;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.open', { model }));
  }
};

const fetchForBorrowing = async function (borrowingId: number): Promise<LockerDoor | void> {
  const model: string = i18n.t('data.models.locker');
  const query = `${URLS.API}/lockers/borrowing/${borrowingId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<LockerDoor>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
export default {
  fetchAll,
  fetchOne,
  createOne,
  updateOne,
  /*deleteOne,*/ deprecateOne,
  fetchAllByLocation,
  openDoor,
  fetchForBorrowing,
};
