import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Like, User } from '../models';

interface UserState {
  // TODO: Use user model here
  id: string | undefined;
  firstName: string;
  lastName: string;
  defaultLocationId?: number;
  email: string;
  likes: Like[];
  isAdmin: boolean;
  address: string;
  postalcode: string;
  city: string;
  phoneNumber: string;
}

export const initialState: UserState = {
  id: undefined,
  firstName: '',
  lastName: '',
  email: '',
  likes: [],
  isAdmin: false,
  address: '',
  postalcode: '',
  city: '',
  phoneNumber: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser(state, action: PayloadAction<User>) {
      const { id, firstName, lastName, email, isAdmin, address, postalcode, city, phoneNumber } = action.payload;
      state.id = id;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.isAdmin = isAdmin;
      state.address = address;
      state.postalcode = postalcode;
      state.city = city;
      state.phoneNumber = phoneNumber;
      if (action.payload.defaultLocationId) state.defaultLocationId = action.payload.defaultLocationId;
    },
    removeUser: (): UserState => initialState,
    updateLikes(state, action: PayloadAction<Like[]>) {
      state.likes = action.payload;
    },
  },
});

export const { addUser, removeUser, updateLikes } = userSlice.actions;

export const selectUser = (state: RootState): UserState => state.user;

export default userSlice.reducer;
