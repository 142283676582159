import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Log } from '../models';
import { checkStatus, createErrorNotification } from './helpers';

const fetchAll = async function (): Promise<Log[] | void> {
  const model: string = i18n.t('data.models.log');
  const query = `${URLS.API}/logs`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Log[]>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

export default { fetchAll };
