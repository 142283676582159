import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { URLS } from '../../constants';
import { getLocationById, secondsToDays } from '../../helpers';
import { ImageData, ItemInstance, ItemModel, Location } from '../../models';
import { imageService } from '../../services';
import { RootState } from '../../store';
import { selectLocations } from '../../store/locationsSlice';
import { addSkipForImage, selectSkipImageReload } from '../../store/settingsSlice';
import useStyles from '../../styles';
import { createImageData, dummySvg } from '../../util';
import PriceDisplay from './PriceDisplay';

interface InstanceComponentProps {
  item: ItemModel;
  instance: ItemInstance;
  redirectUrl?: string;
  borrowButton?: boolean;

  showBorrowStatus?: boolean;
  onClick?: (item: ItemModel, instance: ItemInstance) => void;
  editMode?: boolean | JSX.Element;
}

function InstanceComponent({
  item,
  instance,
  redirectUrl,
  borrowButton = true,
  showBorrowStatus = true,
  onClick,
  editMode = false,
}: InstanceComponentProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const itemId = item?.itemModelId;
  const skipReload = useSelector((state) => selectSkipImageReload(state as RootState, itemId));
  const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
  const locations = useSelector(selectLocations);

  const location = useMemo(() => {
    if (instance) {
      return getLocationById(locations, instance.locationId);
    }
    return null;
  }, [instance, locations]);

  useEffect(() => {
    // Prevent trying to fetch the same data multiple times
    if (!imageData) {
      // Fetch image's thumbnail and save it
      imageService.fetchOne(itemId, false, skipReload).then((imageBlob: Blob | void) => {
        createImageData(setImageData, imageBlob);
        // If the image was loaded now, make it use the cache in the future
        if (!skipReload) dispatch(addSkipForImage(itemId));
      });
    }
  }, [dispatch, imageData, itemId, skipReload]);

  const borrowStatus = (available: boolean, borrowedUntil: string | undefined): JSX.Element => {
    const date = moment(borrowedUntil).format('DD.MM.yyyy') ?? '';
    return (
      <>
        <Typography variant={'subtitle2'}>{t('item.status._')}:</Typography>
        {available ? (
          <Typography variant={'body2'} className={classes.greenChip}>
            {t('item.status.available')}
          </Typography>
        ) : (
          <Typography variant={'body2'} className={classes.redChip}>{`${t(
            'item.status.unavailable'
          )} (${date})`}</Typography>
        )}
      </>
    );
  };
  const locationName = (locId: number): JSX.Element => {
    const loc = locations.find((location: Location) => location.locationId === locId);
    return (
      <div>
        <Typography variant={'subtitle2'}>{t('instance.location')}:</Typography>
        <Typography variant={'body2'}>
          <strong>{loc?.name ?? ''}</strong>
        </Typography>
      </div>
    );
  };
  const ItemCard = () => {
    return (
      <Card
        variant={'outlined'}
        className={[classes.itemContent, classes.h100, onClick ? classes.pointer : null].filter(Boolean).join(' ')}
        onClick={() => (onClick ? onClick(item, instance) : null)}
      >
        <CardMedia
          className={classes.itemContentImageThumb}
          image={imageData?.url ?? dummySvg}
          title={item?.name}
          style={{ backgroundSize: 'contain', backgroundColor: '#fff' }}
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} className={classes.itemName}>
                {item?.name}
              </Typography>
            </Grid>

            {instance && instance.size && (
              <Grid item xs={12} md={6}>
                <Typography variant={'subtitle2'}>{t('instance.size')}:</Typography>
                <Typography variant={'body2'}>
                  <strong>{instance.size}</strong>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              {locationName(instance.locationId)}
            </Grid>
            {showBorrowStatus && (
              <Grid item xs={12} md={6}>
                {borrowStatus(instance.available, instance.nextBorrowingDueDate)}
              </Grid>
            )}
            {showBorrowStatus && (
              <Grid item xs={12} md={6}>
                <Typography variant={'subtitle2'}>{t('item.borrowTime')}:</Typography>
                <Typography variant={'body2'}>
                  <strong>
                    {secondsToDays(item?.maxBorrowingTime)} {t('item.borrowTimeUnit')}
                  </strong>
                </Typography>
              </Grid>
            )}

            {showBorrowStatus && (
              <Grid item xs={6}>
                <PriceDisplay instance={instance} />
              </Grid>
            )}

            {editMode && editMode}
            {!editMode && borrowButton && instance.available && (
              <Grid item xs={12}>
                {false && <Typography variant={'subtitle2'}>{t('item.actions.borrow')}:</Typography>}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.blockBorrow}
                  size={'small'}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (location?.usePayments) {
                      history.push({
                        pathname: URLS.EU.BORROWING,
                        state: { instance, item },
                      });
                    } else {
                      history.push({
                        pathname: URLS.EU.SCANNING,
                      });
                    }
                  }}
                >
                  {t('item.actions.borrow')}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };
  return (
    <div>
      {redirectUrl && (
        <Card variant="outlined" className={classes.h100}>
          <CardActionArea
            className={classes.h100}
            onClick={() => {
              if (redirectUrl) {
                history.push(`${redirectUrl}/${instance.itemInstanceId}`);
              }
            }}
          >
            <ItemCard />
          </CardActionArea>
        </Card>
      )}
      {!redirectUrl && <ItemCard />}
    </div>
  );
}

export default InstanceComponent;
