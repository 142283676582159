import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProblemDTOModel from '../../models/ProblemModel';
import { itemService } from '../../services';
import { selectLocations } from '../../store/locationsSlice';
import useStyles from '../../styles';
function AdminProblemsView(props: any): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locations = useSelector(selectLocations);
  const [problems, setProblems] = useState<ProblemDTOModel[]>([]);
  useEffect(() => {
    const fetchProblemsList = async (): Promise<void> => {
      const probs = await itemService.fetchProblemsList();
      if (probs) {
        setProblems(probs);
      }
    };

    fetchProblemsList();
  }, []);

  const markAsSolved = async (problemId: number): Promise<void> => {
    const res = await itemService.markProblemAsRead(problemId);
    if (res) {
      const probs = await itemService.fetchProblemsList();
      if (probs) {
        setProblems(probs);
      }
    }
  };
  const ProblemRow = ({ problem }: { problem: ProblemDTOModel }): JSX.Element => {
    const history = useHistory();
    //const location = locations.find((loc) => loc.locationId === problem.products?.[0]?.itemInstance?.locationId);
    return (
      <>
        <TableRow style={{ backgroundColor: problem.opened ? 'rgba(76,175,80,0.1)' : 'rgba(239,83,80,0.1)' }}>
          <TableCell>
            <a
              href={'#'}
              onClick={(e: any) => {
                e.preventDefault();
                history.push(`/admin/instance/${problem.problemItemInstanceId}/${problem.problemItemModelId}`);
              }}
            >
              {problem.problemItemInstanceId}
            </a>
          </TableCell>
          <TableCell>
            <a
              href={'#'}
              onClick={(e: any) => {
                e.preventDefault();
                history.push(`/admin/item/${problem.problemItemModelId}`);
              }}
            >
              {problem.itemInstance?.itemModel?.name}
            </a>
          </TableCell>
          <TableCell>{problem.itemInstance?.size}</TableCell>
          <TableCell>{locations.find((l) => l.locationId === problem?.itemInstance?.locationId)?.name}</TableCell>

          <TableCell>
            <strong>{problem.content}</strong>
          </TableCell>
          <TableCell>{problem.sender}</TableCell>
          <TableCell>{moment.utc(problem.createdAt).local().format('DD.MM.YYYY HH:mm:ss')}</TableCell>
          <TableCell>
            {!problem.opened && (
              <IconButton onClick={() => markAsSolved(problem.problemId)} title={t('problem.markAsRead')}>
                <Check />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <div>
      <div className={classes.flexRow}>
        <h1>{t('views.problems')}</h1>
      </div>

      <div></div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>{t('problem.instanceId')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('problem.itemName')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('problem.itemInstanceSize')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('problem.location')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('problem.content')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('problem.sender')}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{t('problem.createdAt')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {problems.map((problem) => {
                return <ProblemRow problem={problem} key={problem.problemId} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AdminProblemsView;
