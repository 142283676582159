import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NewsArticleResults, SearchBar } from '../../components';
import { URLS } from '../../constants';
import { NewsArticle } from '../../models';
import { newsService } from '../../services';
import useStyles from '../../styles';

function AdminSearchNewsView(props: any): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [newsarticles, setNewsarticles]: [NewsArticle[], any] = useState([]);

  useEffect(() => {
    newsService.fetchAll().then((newsarticles: NewsArticle[] | void) => setNewsarticles(newsarticles ?? []));
    //console.debug('Called effect for news');
  }, []);

  const navigateToCreation: () => void = () => {
    history.push(URLS.ADMIN.NEWSARTICLE_NEW);
  };

  const header: JSX.Element = (
    <div className={classes.flexRow}>
      <h1>{t('search.header')}</h1>
      <Button variant="contained" color="secondary" onClick={navigateToCreation}>
        {t('actions.new')}
      </Button>
    </div>
  );

  return (
    <div>
      {header}
      <SearchBar redirectUrl={URLS.ADMIN.NEWS} searchingFor="news" initialQuery={props.match.params.query} />
      <NewsArticleResults newsarticles={newsarticles} redirectUrl={URLS.ADMIN.NEWSARTICLE} />
    </div>
  );
}

export default AdminSearchNewsView;
