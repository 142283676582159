import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  /*typography: {
    fontFamily: `"Open Sans", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,


  },*/
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  palette: {
    // TODO: https://material-ui.com/customization/palette/
    primary: {
      main: '#1F545B',
    },
    secondary: {
      main: '#FF6600',
      contrastText: '#fff',
    },
    success: {
      main: '#1F545B',
    },
    error: {
      main: '#FF6600',
    },
    info: {
      main: '#B4E7FF',
    },
  },
});

export default theme;
