import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { Location, LocationDTO } from '../models';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const fetchAll = async function (): Promise<Location[] | void> {
  const model: string = i18n.t('data.models.location');
  const query = `${URLS.API}/locations`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Location[]>;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const fetchOne = async function (locationId: number): Promise<Location | undefined> {
  const model: string = i18n.t('data.models.location');
  const query = `${URLS.API}/locations/${locationId}`;
  try {
    const res = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<Location>;
  } catch {
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
    return undefined;
  }
};

const createOne = async function (userId: string, locationDto: LocationDTO): Promise<Location | void> {
  const model: string = i18n.t('data.models.location');
  const query = `${URLS.API}/locations?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(locationDto),
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const location: Promise<Location> = res.json() as Promise<Location>;
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return location;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

const updateOne = async function (
  userId: string,
  locationDto: LocationDTO,
  locationId: number
): Promise<Location | void> {
  const model: string = i18n.t('data.models.location');
  const query = `${URLS.API}/locations/${locationId}?userId=${userId}`;
  const init: RequestInit = {
    body: JSON.stringify(locationDto),
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const location: Promise<Location> = res.json() as Promise<Location>;
    createSuccessNotification(i18n.t('notifications.success.update', { model }));
    return location;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.update'));
  }
};

const deleteOne = async function (userId: string, locationId: number): Promise<Location[] | void> {
  const model: string = i18n.t('data.models.location');
  const query = `${URLS.API}/locations/${locationId}?userId=${userId}`;
  const init: RequestInit = { method: 'DELETE' };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    const location: Promise<Location[]> = res.json() as Promise<Location[]>;
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return location;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};

export default { fetchAll, fetchOne, createOne, updateOne, deleteOne };
