import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import { checkStatus, createErrorNotification, createSuccessNotification } from './helpers';

const fetchOne = async function (imageId: number, fullImage = false, skipReload = false): Promise<Blob | void> {
  const model: string = i18n.t('data.models.image');
  const query = `${URLS.API}/images/${imageId}?fullImage=${fullImage}`;
  if (!imageId) return undefined;
  const init: RequestInit = {
    cache: skipReload ? 'force-cache' : 'reload',
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    return await res.blob();
  } catch {
    //createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
const fetchOneWithImageNumber = async function (
  imageId: number,
  imageNumber: number,
  fullImage = false,
  skipReload = false
): Promise<Blob | void> {
  const model: string = i18n.t('data.models.image');
  const query = `${URLS.API}/images/${imageId}/${imageNumber}?fullImage=${fullImage}`;
  if (!imageId) return undefined;
  const init: RequestInit = {
    cache: skipReload ? 'force-cache' : 'reload',
  };
  try {
    const res: Response = await fetch(query, init);
    checkStatus(res);
    return await res.blob();
  } catch {
    //createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};

const createOne = async function (imageId: number, imageData: Blob): Promise<boolean | void> {
  const model: string = i18n.t('data.models.image');
  const query = `${URLS.API}/images`;
  const formData = new FormData();
  formData.append('imageId', imageId.toString());
  formData.append('imageData', imageData);
  const init: RequestInit = {
    body: formData,
    method: 'POST',
  };
  try {
    const res: Response = await fetch(query, init);
    console.error(res);
    checkStatus(res);
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return res.ok;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};
const createOneWithImageNumber = async function (
  imageId: number,
  imageNumber: number,
  imageData: Blob
): Promise<boolean | void> {
  const model: string = i18n.t('data.models.image');
  const query = `${URLS.API}/images/${imageNumber}`;
  const formData = new FormData();
  formData.append('imageId', imageId.toString());
  formData.append('imageData', imageData);
  const init: RequestInit = {
    body: formData,
    method: 'POST',
  };
  try {
    const res: Response = await fetch(query, init);
    console.error(res);
    checkStatus(res);
    createSuccessNotification(i18n.t('notifications.success.create', { model }));
    return res.ok;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};

const deleteOne = async function (imageId: number): Promise<boolean | void> {
  const model: string = i18n.t('data.models.image');
  const query = `${URLS.API}/images/${imageId}`;
  const init: RequestInit = {
    method: 'DELETE',
  };
  try {
    const res: Response = await fetch(query, init);
    console.error(res);
    checkStatus(res);
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return res.ok;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};
const deleteOneWithImageNumber = async function (imageId: number, imageNumber: number): Promise<boolean | void> {
  const model: string = i18n.t('data.models.image');
  const query = `${URLS.API}/images/${imageId}/${imageNumber}`;
  const init: RequestInit = {
    method: 'DELETE',
  };
  try {
    const res: Response = await fetch(query, init);
    console.error(res);
    checkStatus(res);
    createSuccessNotification(i18n.t('notifications.success.delete', { model }));
    return res.ok;
  } catch {
    return createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};
export default {
  fetchOne,
  createOne,
  createOneWithImageNumber,
  fetchOneWithImageNumber,
  deleteOneWithImageNumber,
  deleteOne,
};
