import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ItemResults } from '../../components';
import { URLS } from '../../constants';
import { ItemInstance, ItemModel, Like } from '../../models';
import { itemService, likeService } from '../../services';
import { selectUser } from '../../store/userSlice';

function LikesView(): JSX.Element {
  const { t } = useTranslation();
  const userId = useSelector(selectUser).id ?? '';
  const [likedItemIds, setLikedItemIds] = useState<number[]>([]);
  const [likedItemModels, setLikedItemModels] = useState<ItemModel[]>([]);
  const [likedItemInstances, setLikedItemInstances] = useState<ItemInstance[]>([]);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [loadingInstances, setLoadingInstances] = useState<boolean>(false);
  // Fetch all user's likes in the beginning
  useEffect(() => {
    likeService.fetchAll(userId).then((likesResponse: Like[] | void) => {
      if (likesResponse) {
        setLikedItemIds(likesResponse.map((like: Like) => like.likedItemModelId));
      }
    });
  }, [userId]);

  // Fetch all liked item objects when likes are fetched
  useEffect(() => {
    if (likedItemIds.length !== 0) {
      // TODO: Implement fetching only a given list of items from backend!
      setLoadingItems(true);
      itemService.fetchAll().then((itemsResponse: ItemModel[] | void) => {
        if (itemsResponse) {
          const likedItems: ItemModel[] = itemsResponse.filter((item: ItemModel) =>
            likedItemIds.includes(item.itemModelId)
          );
          setLikedItemModels(likedItems);
          setLoadingItems(false);
        }
      });
      setLoadingInstances(true);
      itemService.fetchAllInstances().then((itemsResponse: ItemInstance[] | void) => {
        if (itemsResponse) {
          setLikedItemInstances(itemsResponse);
          setLoadingInstances(false);
        }
      });
    }
  }, [likedItemIds]);

  return (
    <div>
      <h1>{t('views.likes')}</h1>
      {
        <ItemResults
          loading={loadingItems || loadingInstances}
          items={likedItemModels}
          instances={likedItemInstances}
          redirectUrl={URLS.EU.ITEM_NOQUERY}
          resultText={t('search.browse.likes')}
        />
      }
    </div>
  );
}

export default LikesView;
