import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';
import { RootState } from '.';

export interface NotificationItem {
  status?: number;
  message: string;
}

export interface Notification {
  id: number;
  status?: number;
  message: string;
  dismissed: boolean;
  type: VariantType;
}

interface NotificationState {
  notifications: Notification[];
}

export const initialState: NotificationState = {
  notifications: [],
};

// notistack is mad if index starts at 0
let nextNotificationId = 1;

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addSuccess: {
      reducer(state, action: PayloadAction<Notification>) {
        state.notifications.push(action.payload);
      },
      prepare({ status, message }: NotificationItem) {
        const variantType: VariantType = 'success';
        return {
          payload: {
            id: nextNotificationId++,
            status,
            message,
            dismissed: false,
            type: variantType,
          },
        };
      },
    },
    addWarning: {
      reducer(state, action: PayloadAction<Notification>) {
        state.notifications.push(action.payload);
      },
      prepare({ status, message }: NotificationItem) {
        const variantType: VariantType = 'warning';
        return {
          payload: {
            id: nextNotificationId++,
            status,
            message,
            dismissed: false,
            type: variantType,
          },
        };
      },
    },
    addInfo: {
      reducer(state, action: PayloadAction<Notification>) {
        state.notifications.push(action.payload);
      },
      prepare({ status, message }: NotificationItem) {
        const variantType: VariantType = 'info';
        return {
          payload: {
            id: nextNotificationId++,
            status,
            message,
            dismissed: false,
            type: variantType,
          },
        };
      },
    },
    addError: {
      reducer(state, action: PayloadAction<Notification>) {
        state.notifications.push(action.payload);
      },
      prepare({ status, message }: NotificationItem) {
        const variantType: VariantType = 'error';
        return {
          payload: {
            id: nextNotificationId++,
            status,
            message,
            dismissed: false,
            type: variantType,
          },
        };
      },
    },
    removeNotification(state, action) {
      state.notifications = state.notifications.filter((n) => n.id !== action.payload);
    },
  },
});

export const { addError, addInfo, addSuccess, addWarning, removeNotification } = notificationSlice.actions;

export const selectNotifications = (state: RootState): Notification[] => state.notifications.notifications;

export default notificationSlice.reducer;
