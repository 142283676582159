import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { URLS } from '../../constants';
import { isAuthenticated } from '../../services/authenticationService';

interface ProtectedRouteProps extends RouteProps {
  path: string;
}

function ProtectedRoute({ children, component: Component, ...rest }: ProtectedRouteProps): JSX.Element {
  const isUserAuthenticated = isAuthenticated();

  return isUserAuthenticated ? <Route {...rest} component={Component} /> : <Redirect to={URLS.AUTH.LOGIN} />;
}

export default ProtectedRoute;
