import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATION } from '../../constants';
import { ItemInstance, ItemInstanceDTO, Location } from '../../models';
import { itemService } from '../../services/';
import useStyles from '../../styles';
import { QrCodeDialog } from '../item/QrCodeDialog';
import { errorInNumber, errorInPrice, errorInText } from './validation';

interface InstanceFormProps {
  instanceId: number;
  itemModelId: number;
  initializeWithDto: boolean;
  locations: Location[];
  locationName: string;
  currentLocation: string;
  setCurrentLocation: any;
  instanceDto: ItemInstanceDTO;
  setInstanceDto: any;
  instance: ItemInstance;
}

// Not for validation, only for displaying errondous values
interface ItemValidationErrors {
  size: boolean;
  location: boolean;
  price: boolean;
}

const initialValidationErrors: ItemValidationErrors = {
  size: false,
  location: false,
  price: false,
};

function InstanceForm(props: InstanceFormProps): JSX.Element {
  const classes = useStyles();
  const [errors, setErrors]: [ItemValidationErrors, any] = useState(initialValidationErrors);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClose = (): void => {
    setOpen(false);
  };

  const ProblemList = ({ instanceId }: { instanceId: number | undefined }): JSX.Element => {
    const [problems, setProblems] = useState<any[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
      if (!instanceId) {
        return;
      }
      const fetchProblems = async (): Promise<void> => {
        const probs = await itemService.fetchProblems(instanceId);
        if (probs) {
          setProblems(probs);
        }
      };

      fetchProblems();
    }, [instanceId]);

    return (
      <>
        <h3>{t('views.itemProblems')}</h3>
        <List>
          {problems.map((problem) => {
            const primary = `${moment(problem.createdAt).format('DD.MM.yyyy HH:mm')} - ${problem.sender}`;

            return (
              <ListItem>
                <ListItemText primary={primary} secondary={problem.content} />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  const replacePriceChars = (value: string) => {
    const regex = /[^0-9\.]*/gi;
    return value.replace(',', '.').replace(regex, '');
  };
  return (
    <div>
      <TextField
        className={classes.vMargin}
        label={t('data.fields.size')}
        fullWidth
        variant="outlined"
        value={props.initializeWithDto ? props.instanceDto.size : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.setInstanceDto({ ...props.instanceDto, size: event.target.value });
        }}
        error={errors.size}
        helperText={t('validation.size', { length: VALIDATION.INSTANCE.SIZE })}
        onBlur={(event: any): void =>
          setErrors({ ...errors, size: errorInText(event.target.value, VALIDATION.INSTANCE.SIZE) })
        }
      />
      <FormControl className={classes.vMargin} variant="outlined" fullWidth error={errors.location}>
        <InputLabel>{t('data.fields.location')}</InputLabel>
        <Select
          label={t('data.fields.location')}
          value={props.initializeWithDto ? props.currentLocation : undefined}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            const name: string = event.target.value as string;
            const location: Location | undefined = props.locations.find((location) => location.name === name);
            const locationId = location?.locationId ?? 0;
            props.setCurrentLocation(name);
            props.setInstanceDto({ ...props.instanceDto, locationId: locationId });
            setErrors({ ...errors, location: errorInNumber(locationId) });
          }}
        >
          <MenuItem value="">
            <em>{t('data.none')}</em>
          </MenuItem>
          {props.locations.map((location, i) => (
            <MenuItem key={i} value={location.name}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{t('validation.mustHaveValue')}</FormHelperText>
      </FormControl>

      <TextField
        fullWidth
        className={classes.vMargin}
        label={t('data.fields.price')}
        variant="outlined"
        value={props.initializeWithDto ? props.instanceDto.price : undefined}
        onChange={(event) => {
          props.setInstanceDto({
            ...props.instanceDto,
            price: replacePriceChars(event.target.value),
          });
        }}
        error={errors.price}
        helperText={t('item.priceHelper')}
        inputProps={{ pattern: '[0-9]*\\.?[0-9]{0,2}' }}
        onBlur={(event: any): void => setErrors({ ...errors, price: errorInPrice(event.target.value) })}
      />

      <div>
        <FormControl className={classes.vMargin} variant={'outlined'} fullWidth error={false}>
          <InputLabel>{t('data.fields.tax')}</InputLabel>
          <Select
            labelId={'tax-select-label'}
            value={props.initializeWithDto ? props.instanceDto.tax : undefined}
            onChange={(event) => {
              props.setInstanceDto({ ...props.instanceDto, tax: event.target.value });
            }}
          >
            <MenuItem value={24}>24 %</MenuItem>
            <MenuItem value={14}>14 %</MenuItem>
            <MenuItem value={10}>10 %</MenuItem>
            <MenuItem value={0}>0 %</MenuItem>
          </Select>
        </FormControl>
      </div>
      <br />

      <TextField
        fullWidth
        className={classes.vMargin}
        label={t('data.fields.pretaxPrice')}
        inputProps={{ readOnly: true }}
        variant="outlined"
        value={props.initializeWithDto ? props.instanceDto.pretaxPrice : undefined}
        onChange={(event) => {
          props.setInstanceDto({ ...props.instanceDto, pretaxPrice: event.target.value });
        }}
      />
      {props.initializeWithDto ? <ProblemList instanceId={props.instanceId} /> : undefined}
      <QrCodeDialog isOpen={open} closeDialog={handleClose} itemInstanceId={props.instanceId} />
    </div>
  );
}

export default InstanceForm;
