import { URLS } from '../constants';
import i18n from '../i18n/i18n';
import DoorCode from '../models/DoorCode';
import { checkStatus, createErrorNotification } from './helpers';

const fetchAll = async function (filter: Record<string, any>): Promise<DoorCode[] | void> {
  const model: string = i18n.t('data.models.doorCode');
  const query = `${URLS.API}/doorcodes?${new URLSearchParams(filter).toString()}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<DoorCode[]>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
const fetchForBorrowing = async function (borrowingId: number): Promise<DoorCode | void> {
  const model: string = i18n.t('data.models.doorCode');
  const query = `${URLS.API}/doorcodes/borrowing/${borrowingId}`;
  try {
    const res: Response = await fetch(query);
    checkStatus(res);
    return res.json() as Promise<DoorCode>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.fetch', { model }));
  }
};
const deprecate = async function (doorCodeId: number): Promise<Record<string, any> | void> {
  const model: string = i18n.t('data.models.doorCode');
  const query = `${URLS.API}/doorcodes/${doorCodeId}/deprecate`;
  try {
    const res: Response = await fetch(query, {
      method: 'PATCH',
    });
    checkStatus(res);
    return res.json() as Promise<Record<string, any>>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.delete', { model }));
  }
};

const save = async function (doorCode: DoorCode): Promise<Record<string, any> | void> {
  const model: string = i18n.t('data.models.doorCode');
  const query = `${URLS.API}/doorcodes`;
  try {
    if (!doorCode.code) {
      throw 'Code is empty';
    }
    const res: Response = await fetch(query, {
      body: JSON.stringify(doorCode),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    checkStatus(res);
    return res.json() as Promise<Record<string, any>>;
  } catch (err) {
    console.error(err);
    createErrorNotification(i18n.t('notifications.error.create', { model }));
  }
};
export default { fetchAll, fetchForBorrowing, deprecate, save };
