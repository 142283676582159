import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import translationsEn from './../i18n/locales/en/translation.json';
import translationsFi from './../i18n/locales/fi/translation.json';

const resources = {
  en: {
    translation: translationsEn,
  },
  fi: {
    translation: translationsFi,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fi',
  debug: false,
  react: {
    useSuspense: false,
  },
  interpolation: {
    format: (value: any, format: any, lng: any) => {
      if (value instanceof Date) {
        return moment(value).format(format);
      }
      return value;
    },
  },
});

export default i18n;
